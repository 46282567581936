import _ from 'lodash';
import { productService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import { TAX_TYPE_SAAS, ACCOUNT_TYPE_CLIENT } from '@/helpers';
import {
    GET_ALL,
    GET_ACTIVE,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE,
    GET_RATE_BY_ID,
    ADD_RATE,
    UPDATE_RATE,
    DELETE_RATE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_ACTIVE_REQUEST,
    GET_ACTIVE_SUCCESS,
    GET_ACTIVE_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    RATES_GET_BY_ID_REQUEST,
    RATES_GET_BY_ID_FAILURE,
    RATES_GET_BY_ID_SUCCESS,
    RATES_CLEAR_CURRENT_ITEM,
    RATES_UPDATE_CURRENT_ITEM,
  } from "./mutations.type";

function getBlankProduct() {
    return {
        productId: GUID_EMPTY,
        platformProductId: null,
        accountTypeId: ACCOUNT_TYPE_CLIENT,
        productCode: "",
        isBilledByProvider: false,
        productType: "",
        subscriptionLevel: null,
        taxProductType: TAX_TYPE_SAAS,
        name: "",
        description: "",
        longDescription: "",
        defaultExpiryMonths: null,
        defaultExpiryDays: null,
        isSubscription: false,
        requiresConsult: false,
        isHidden: false,
        displayOrder: null,
        maxReadOnlyUsers: null,
        maxEditUsers: null,
        maxPrograms: null,
        maxProjects: null,
        maxPortfolios: null,
        maxApplications: null,
        maxITServices: null,
        maxClientWorkspaces: null,
        effectiveDate: null,
        endDate: null,
        productRates: [],
        accountProductCount: null
    };
}

function getBlankRate() {
    return {
        productRateId: GUID_EMPTY,
        productId: null,
        unit: "",
        rate: null,
        isPerEditUser: false,
        defaultCouponId: null,
        applyDefaultCouponOnInitialSetupOnly: false,
        effectiveDate: null,
        endDate: null
    };
}



export const products = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        allProducts: [],
        activeProducts: [],
        currentProduct: getBlankProduct(),
        currentRate: getBlankRate()
    },
    getters: {
       
    },
    actions: {
        [GET_ALL]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                return productService.getAll()
                    .then(
                        allProducts => {
                            commit(GET_ALL_SUCCESS, allProducts);
                            resolve(allProducts);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_ACTIVE]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_ACTIVE_REQUEST);

                return productService.getActive()
                    .then(
                        activeProducts => {
                            commit(GET_ACTIVE_SUCCESS, activeProducts);
                            resolve(activeProducts);
                        },
                        error => {
                            commit(GET_ACTIVE_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, productId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return productService.getById(productId)
                    .then(
                        product => {
                            commit(GET_BY_ID_SUCCESS, product);
                            resolve(product);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, product) {
            return productService.add(product);
        },
        [UPDATE]({ dispatch, commit }, product) {
            return productService.update(product);
        },
        [DELETE]({ dispatch, commit }, product) {
            return productService.deleteProduct(product);
        },

        [GET_RATE_BY_ID]({ commit }, { productId, rateId }) {
            return new Promise((resolve, reject) => {
                commit(RATES_GET_BY_ID_REQUEST);

                return productService.getRateById(productId, rateId)
                    .then(
                        rate => {
                            commit(RATES_GET_BY_ID_SUCCESS, rate);
                            resolve(rate);
                        },
                        error => {
                            commit(RATES_GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD_RATE]({ dispatch, commit }, rate) {
            return productService.addRate(rate);
        },
        [UPDATE_RATE]({ dispatch, commit }, rate) {
            return productService.updateRate(rate);
        },
        [DELETE_RATE]({ dispatch, commit }, rate) {
            return productService.deleteRate(rate);
        },


    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, allProducts) {
            state.status = { loaded: true };
            state.allProducts = allProducts;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.allProducts = [];
        },

        [GET_ACTIVE_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ACTIVE_SUCCESS](state, activeProducts) {
            state.status = { loaded: true };
            state.activeProducts = activeProducts;
        },
        [GET_ACTIVE_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.activeProducts = [];
        },

        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, product) {
            state.status = { loaded: true };
            state.currentProduct = product;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentProduct = getBlankProduct();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentProduct = getBlankProduct();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentProduct, {
                [field]: value
            });
        },

        [RATES_GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [RATES_GET_BY_ID_SUCCESS](state, rate) {
            state.status = { loaded: true };
            state.currentRate = rate;
        },
        [RATES_GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRate = getBlankRate();
        },
        [RATES_CLEAR_CURRENT_ITEM](state) {
            state.currentRate = getBlankRate();
        },
        [RATES_UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRate, {
                [field]: value
            });
        },
    }
}
