import _ from 'lodash';
import { overviewService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET,
    GET_COMMON_VALUES
  } from "./actions.type";
import {
    GET_REQUEST,
    GET_SUCCESS,
    GET_FAILURE,
    GET_COMMON_VALUES_REQUEST,
    GET_COMMON_VALUES_SUCCESS,
    GET_COMMON_VALUES_FAILURE
  } from "./mutations.type";

const initialOverviewState = {
    accountId: GUID_EMPTY,
    applicationsCount: 0,
    licensesCount: 0,
    processesSupportedCount: 0,
    applicationsToBeRetiredCount: 0,
    licenseCost: 0,
    personnelCost: 0,
    hardwareCost: 0,
    refreshCost: 0,
    developmentCost: 0,
    hardwareAndHostingCost: 0,
    totalCost: 0,
    thirdPartyApplicationsCount: 0,
    thirdPartyLicensesCount: 0,
    thirdPartyProcessesSupportedCount: 0,
    thirdPartyApplicationsToBeRetiredCount: 0,
    thirdPartyLicenseCost: 0,
    thirdPartyPersonnelCost: 0,
    thirdPartyHardwareCost: 0,
    thirdPartyRefreshCost: 0,
    thirdPartyDevelopmentCost: 0,
    thirdPartyHardwareAndHostingCost: 0,
    thirdPartyTotalCost: 0,
    grandTotalApplicationsCount: 0,
    grandTotalLicensesCount: 0,
    grandTotalProcessesSupportedCount: 0,
    grandTotalApplicationsToBeRetiredCount: 0,
    grandTotalLicenseCost: 0,
    grandTotalPersonnelCost: 0,
    grandTotalHardwareCost: 0,
    grandTotalRefreshCost: 0,
    grandTotalDevelopmentCost: 0,
    grandTotalHardwareAndHostingCost: 0,
    grandTotalCost: 0,            
};


const blankCommonValues = {
    areaTypes: [],
    contactTypes: [],
    companyEmployees: [],
    companyRevenue: [],
    companyTypes: [],
    industries: [],
    questionLevels: [],
    projectNoteStatuses: [],
    projectNoteTypes: [],
    countries: []
};


export const overview = {
    namespaced: true,
    state: {
        overview: initialOverviewState,
        commonValues: blankCommonValues,
    },
    getters: {

    },
    actions: {
        [GET]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.overview.accountId > 0) {
                    resolve(state.overview);
                } else {
                    commit(GET_REQUEST);

                    return overviewService.getOverview()
                        .then(
                            data => {
                                commit(GET_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_COMMON_VALUES]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.commonValues.contactTypes.length > 0) {
                    resolve(state.commonValues);
                } else {
                    commit(GET_COMMON_VALUES_REQUEST);

                    return overviewService.getCommonValues()
                        .then(
                            data => {
                                commit(GET_COMMON_VALUES_SUCCESS, data);
                                resolve(data);
                            },
                            error => {
                                commit(GET_COMMON_VALUES_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
    },
    mutations: {
        [GET_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.overview = data;
        },
        [GET_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.overview = initialOverviewState;
        },

        [GET_COMMON_VALUES_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_COMMON_VALUES_SUCCESS](state, data) {
            state.status = { loaded: true };
            state.commonValues = data;
        },
        [GET_COMMON_VALUES_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.commonValues = blankCommonValues;
        },
    }
}
