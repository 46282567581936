// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const billingService = {
    getTransactions,
    getManualTransactions,
    getAutomaticTransactions,
    generateAccountTransactions,

    getProducts,
    getProductById,
    addProduct,
    updateProduct,
    deleteProduct,
    getProductCouponById,
    addProductCoupon,
    updateProductCoupon,
    deleteProductCoupon,

    getAdjustments,
    getAdjustmentById,
    addAdjustment,
    updateAdjustment,
    deleteAdjustment,
    
    getPayments,
    getPaymentById,
    addPayment,
    updatePayment,
    deletePayment,

    chargePaymentMethod,
};

function getTransactions() {
    return axios.get(`/billing/transactions`).then(handleResponse);
}
function getManualTransactions() {
    return axios.get(`/billing/transactions/manual`).then(handleResponse);
}
function getAutomaticTransactions() {
    return axios.get(`/billing/transactions/automatic`).then(handleResponse);
}
function generateAccountTransactions() {
    return axios.post('/billing/transactions/generate').then(handleResponse);
}


function getProducts() {
    return axios.get(`/billing/products`).then(handleResponse);
}

function getProductById(id) {
    return axios.get(`/billing/products/${id}`).then(handleResponse);
}

function addProduct(product) {
    return axios.post('/billing/products', product).then(handleResponse);
}

function updateProduct(product) {
    return axios.put(`/billing/products/${product.accountProductId}`, product).then(handleResponse);
}

function deleteProduct(product) {
    return axios.delete(`/billing/products/${product.accountProductId}`).then(handleResponse);
}

function getProductCouponById(accountProductId, id) {
    return axios.get(`/billing/products/${accountProductId}/coupons/${id}`).then(handleResponse);
}

function addProductCoupon(coupon) {
    return axios.post(`/billing/products/${coupon.accountProductId}/coupons`, coupon).then(handleResponse);
}

function updateProductCoupon(coupon) {
    return axios.put(`/billing/products/${coupon.accountProductId}/coupons/${coupon.accountProductCouponId}`, coupon).then(handleResponse);
}

function deleteProductCoupon(coupon) {
    return axios.delete(`/billing/products/${coupon.accountProductId}/coupons/${coupon.accountProductCouponId}`).then(handleResponse);
}




function getAdjustments() {
    return axios.get(`/billing/adjustments`).then(handleResponse);
}

function getAdjustmentById(id) {
    return axios.get(`/billing/adjustments/${id}`).then(handleResponse);
}

function addAdjustment(adjustment) {
    return axios.post('/billing/adjustments', adjustment).then(handleResponse);
}

function updateAdjustment(adjustment) {
    return axios.put(`/billing/adjustments/${adjustment.accountAdjustmentId}`, adjustment).then(handleResponse);
}

function deleteAdjustment(adjustment) {
    return axios.delete(`/billing/adjustments/${adjustment.accountAdjustmentId}`).then(handleResponse);
}


function getPayments() {
    return axios.get(`/billing/payments`).then(handleResponse);
}

function getPaymentById(id) {
    return axios.get(`/billing/payments/${id}`).then(handleResponse);
}

function addPayment(payment) {
    return axios.post('/billing/payments', payment).then(handleResponse);
}

function updatePayment(payment) {
    return axios.put(`/billing/payments/${payment.accountPaymentId}`, payment).then(handleResponse);
}

function deletePayment(payment) {
    return axios.delete(`/billing/payments/${payment.accountPaymentId}`).then(handleResponse);
}

function chargePaymentMethod() {
    return axios.post('/billing/charge_automatic_balance').then(handleResponse);
}
