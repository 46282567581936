// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refIdeaTypeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteEntity
};

function getByAccount() {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refIdeaTypes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refIdeaTypes/${id}`).then(handleResponse);
}

function add(refIdeaType) {
    return axios.post('/refIdeaTypes', refIdeaType).then(handleResponse);
}

function update(refIdeaType) {
    return axios.put(`/refIdeaTypes/${refIdeaType.refIdeaTypeId}`, refIdeaType).then(handleResponse);
}

function deleteEntity(refIdeaType) {
    return axios.delete(`/refIdeaTypes/${refIdeaType.refIdeaTypeId}`).then(handleResponse);
}
