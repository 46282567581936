// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refProjectTypeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefProjectType
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refProjectTypes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refProjectTypes/${id}`).then(handleResponse);
}

function add(refProjectType) {
    return axios.post('/refProjectTypes', refProjectType).then(handleResponse);
}

function update(refProjectType) {
    return axios.put(`/refProjectTypes/${refProjectType.refProjectTypeId}`, refProjectType).then(handleResponse);
}

function deleteRefProjectType(refProjectType) {
    return axios.delete(`/refProjectTypes/${refProjectType.refProjectTypeId}`).then(handleResponse);
}
