// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const overviewService = {
    getOverview,
    getVersion,
    getCommonValues
};

function getOverview() {
    return axios.get('/overview').then(handleResponse);
}

function getVersion() {
    return axios.get('/overview/version').then(handleResponse);
}

function getCommonValues() {
    return axios.get('/overview/commonvalues').then(handleResponse);
}
