// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const capabilitySetService = {
    getByAccount,
    getById,
    add,
    update,
    deleteCapabilitySet
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/capabilitySets/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/capabilitySets/${id}`).then(handleResponse);
}

function add(capabilitySet) {
    return axios.post('/capabilitySets', capabilitySet).then(handleResponse);
}

function update(capabilitySet) {
    return axios.put(`/capabilitySets/${capabilitySet.capabilitySetId}`, capabilitySet).then(handleResponse);
}

function deleteCapabilitySet(capabilitySet) {
    return axios.delete(`/capabilitySets/${capabilitySet.capabilitySetId}`).then(handleResponse);
}