import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timeZone  from 'dayjs/plugin/timezone';

dayjs.extend(timeZone).extend(advancedFormat);

export const formatDate = value => {
    if (value) {
        return dayjs(String(value)).format('MM/DD/YYYY')
    }
};

export const formatLongDate = value => {
    if (value) {
        return dayjs(String(value)).format('MMM D, YYYY')
    }
};

export const formatDateTime = value => {
    if (value) {
        return dayjs(String(value)).format('MM/DD/YYYY h:mm a')
    }
};

export const formatDateTimeTz = value => {
    if (value) {
        return dayjs(String(value)).format('MM/DD/YYYY h:mm a z')
    }
};


export const yesNo = value => {
    return value ? 'Yes' : 'No';
};

export const formatDollars = value => {
    if (value == null || Number.isNaN(value)) {
        return null;
    }
    return '$' + Number(value).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,');
};

export const formatDollars2Decimals = value => {
    if (value == null || Number.isNaN(value)) {
        return null;
    }
    return '$' + formatNumber2Decimals(Number(value));
    // return '$' + value.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumber = value => {
    if (value == null || Number.isNaN(value)) {
        return null;
    }
    return Number(value).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,');
};

export const formatNumber2Decimals = value => {
    if (value == null || Number.isNaN(value)) {
        return null;
    }
    return new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value).toFixed(2));
    // return value.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};


export const formatNumber1Decimal = value => {
    if (value == null || Number.isNaN(value)) {
        return null;
    }
    return new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value).toFixed(1));
    // return value.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPercent = value => {
    if (value == null || Number.isNaN(value)) {
        return null;
    }
    return Number(value).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,') + '%';
};

export const formatPhone = value => {
    if (value == null) {
        return null;
    }
    var cleaned = ('' + value).replace(/\D/g, '');
    if (cleaned.length == 10) {
        return "(" + cleaned.substring(0,3) + ") " + cleaned.substring(3,6) + "-" + cleaned.substring(6,10);
    } else {
        return value;
    }
};

export const formatUrl = value => {
    if (value == null || value.trim().length == 0) {
        return '';
    }
    if (value.indexOf("http://") == 0 || value.indexOf("https://") == 0) {
        return value;
    }
    return `http://${value.trim()}`;
};

// from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return null; //'0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
