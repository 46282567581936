import _ from 'lodash';
import { supplierService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    UPDATE_ADDRESS,
    // ADD_APPLICATION_SUPPLIER,
    // REMOVE_APPLICATION_SUPPLIER,
    // ADD_IT_SERVICE_SUPPLIER,
    // REMOVE_IT_SERVICE_SUPPLIER,
} from "./mutations.type";

// function getBlankApplicationSupplier() {
//     return {
//         applicationSupplierId: GUID_EMPTY,
//         applicationId: GUID_EMPTY,
//         supplierId: null,
//         name: ""
//     };
// }

function getBlankSupplier() {
    return {
        supplierId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        addressId: null,
        accountSupplierNumber: null,
        name: "",
        emailAddress: "",
        websiteAddress: "",
        contactPhone: "",
        notes: "",
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        address: getBlankAddress(),
        // applicationSuppliers: []
    };
}

function getBlankAddress() {
    return {
        addressId: GUID_EMPTY,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        city: null,
        province: null,
        countryName: null,
        countryId: null,
        postalCode: null
    };
}

export const suppliers = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentSupplier: getBlankSupplier()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                supplierService.getByAccount(accountId)
                    .then(
                        suppliers => {
                            commit(GET_ALL_SUCCESS, suppliers);
                            resolve(suppliers);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, supplierId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return supplierService.getById(supplierId)
                    .then(
                        supplier => {
                            commit(GET_BY_ID_SUCCESS, supplier);
                            resolve(supplier);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, supplier) {
            return supplierService.add(supplier);
        },
        [UPDATE]({ dispatch, commit }, supplier) {
            return supplierService.update(supplier);
        },
        [DELETE]({ dispatch, commit }, supplier) {
            return supplierService.deleteSupplier(supplier);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, suppliers) {
            state.status = { loaded: true };
            state.items = suppliers;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, supplier) {
            state.status = { loaded: true };
            state.currentSupplier = supplier;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentSupplier = getBlankSupplier();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentSupplier = getBlankSupplier();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentSupplier, {
                [field]: value
            });
        },
        [UPDATE_ADDRESS](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentSupplier.address, {
                [field]: value
            });
        },
        // [ADD_APPLICATION_SUPPLIER](state, applicationId) {
        //     var newAppSupplier = getBlankApplicationSupplier();
        //     newAppSupplier.applicationId = applicationId;
        //     state.currentSupplier.applicationSuppliers.push(newAppSupplier);
        // },
        // [REMOVE_APPLICATION_SUPPLIER](state, applicationId) {
        //     _.remove(state.currentSupplier.applicationSuppliers, { 'applicationId': applicationId });
        // },


    }
}
