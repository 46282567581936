import _ from 'lodash';
import { analyticsService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_NAMESPACES,
    GET_NAMESPACE_BY_ID
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    CLEAR_ITEMS,
    SET_SELECTED_DASHBOARD,
    CLEAR_NAMESPACES,
    CLEAR_CURRENT_NAMESPACE,
    GET_NAMESPACES_REQUEST,
    GET_NAMESPACES_SUCCESS,
    GET_NAMESPACES_FAILURE,
    GET_NAMESPACE_BY_ID_REQUEST,
    GET_NAMESPACE_BY_ID_SUCCESS,
    GET_NAMESPACE_BY_ID_FAILURE,
} from "./mutations.type";

function getBlankNamespace() {
    return {
        analyticsNamespaceId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        quickSightNamespaceName: null,
        accountNumber: null,
        accountName: null,
        analyticsUserCount: null,
        sessionCountThisMonth: null,
        estimatedChargesThisMonth: null,
        analyticsUsers: [],
        analyticsGroups: [],
        analyticsDashboards: []
    };
}

export const analytics = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        selectedDashboardId: null,
        items: [],
        namespaces: [],
        currentNamespace: getBlankNamespace(),
    },
    getters: {
        getDashboardById: (state) => (id) => {
            return state.items.find(item => item.analyticsDashboardId === id);
        },
    },
    actions: {
        [GET_ALL]({ state, commit }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    return analyticsService.getDashboards()
                        .then(
                            dashboards => {
                                commit(GET_ALL_SUCCESS, dashboards);
                                resolve(dashboards);
                            },
                            error => {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_NAMESPACES]({ state, commit }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_NAMESPACES_REQUEST);

                    return analyticsService.getNamespaces()
                        .then(
                            namespaces => {
                                commit(GET_NAMESPACES_SUCCESS, namespaces);
                                resolve(namespaces);
                            },
                            error => {
                                commit(GET_NAMESPACES_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_NAMESPACE_BY_ID]({ commit }, namespaceId) {
            return new Promise((resolve, reject) => {
                commit(GET_NAMESPACE_BY_ID_REQUEST);

                return analyticsService.getNamespaceById(namespaceId)
                    .then(
                        namespace => {
                            commit(GET_NAMESPACE_BY_ID_SUCCESS, namespace);
                            resolve(namespace);
                        },
                        error => {
                            commit(GET_NAMESPACE_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
    },
    mutations: {
        [SET_SELECTED_DASHBOARD](state, value) {
            state.selectedDashboardId = value;
        },
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, dashboards) {
            state.status = { loaded: true };
            state.items = dashboards;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },


        [GET_NAMESPACES_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_NAMESPACES_SUCCESS](state, namespaces) {
            state.status = { loaded: true };
            state.namespaces = namespaces;
        },
        [GET_NAMESPACES_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.namespaces = [];
        },
        [GET_NAMESPACE_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_NAMESPACE_BY_ID_SUCCESS](state, namespace) {
            state.status = { loaded: true };
            state.currentNamespace = namespace;
        },
        [GET_NAMESPACE_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentNamespace = getBlankNamespace();
        },

        [CLEAR_NAMESPACES](state) {
            state.namespaces = [];
        },
        [CLEAR_CURRENT_NAMESPACE](state) {
            state.currentNamespace = getBlankNamespace();
        },
    }
}
