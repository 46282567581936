import axios from 'axios';
import { handleResponse } from './http-utils';

export const aiService = {
    generate,
    generateValueDrivers,
    generateApplicationCapabilities
};

function generate(promptCode, text1, text2, text3) {
    return axios.post('/ai', { promptCode, text1, text2, text3 } ).then(handleResponse);
}

function generateValueDrivers(promptCode, text1, text2, text3) {
    return axios.post('/ai/value_drivers', { promptCode, text1, text2, text3 } ).then(handleResponse);
}

function generateApplicationCapabilities(text1) {
    return axios.post('/ai/application_capabilities', { text1 } ).then(handleResponse);
}
