<template>
    <span>
        <span :id="'question-details-' + entity.accountQuestionId" style="padding: 4px 4px;" @mouseenter.capture="showTooltip" @mouseleave.capture="hideTooltip" :title="entity.questionText">
            <i class="fa fa-info-circle" style="opacity: 0.7;"></i>
        </span>
        <Tooltip ref="tooltip" :anchor-element="'target'" :position="position" :open-delay="0" />
    </span>
</template>

<style>

</style>

<script>
import { Tooltip } from '@progress/kendo-vue-tooltip';

export default {
    name: 'QuestionTooltip',
    props: {
      entity: {
        type: Object,
        description: 'Entity to show details for'
      },
      position: {
        type: String,
        default: 'bottom',
        description: 'Location to show tooltip'
      }
    },
    components: {
        Tooltip
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
    },
    methods: {
        showTooltip(event) {
            this.$refs.tooltip && this.$refs.tooltip.handleMouseOver(event);
        },
        hideTooltip(event) {
            this.$refs.tooltip && this.$refs.tooltip.handleMouseOut(event);
        },

    },
}
</script>