<template>
  <div class="card card-stats mb-1">
    <div class="card-body">
      <div class="row">
        <div class="col-3 col-md-2">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
        <div class="col-9 col-md-10">
          <slot name="content">
            <div class="numbers">
              <p class="card-category">{{smallTitle}}</p>
              <p class="card-title">{{title}}</p>
              <p class="card-category mt-2" v-if="!!secondarySmallTitle">{{secondarySmallTitle}}</p>
              <p class="card-title" v-if="!!secondaryTitle">{{secondaryTitle}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <!-- <hr/> -->
      <slot name="footer"></slot>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'stats-card',
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      secondaryTitle: {
        type: String,
        description: 'Secondary card title'
      },
      secondarySmallTitle: {
        type: String,
        description: 'Secondary card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      }
    }
  }

</script>
<style scoped>
  .card-content{
    min-height: 85px
  }
  .card {
      word-wrap: normal;
      height: 95%;
  }
</style>
