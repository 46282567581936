<template>
    <div class="register-page">
        <app-navbar></app-navbar>
        <div class="wrapper wrapper-full-page">
            <div class="full-page register-page">
                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 ms-auto me-auto">
                                <card type="signup">
                                    <template slot="header">
                                        <h4 class="card-title text-center">Page Not Found</h4>
                                    </template>
                                    <div class="text-center mb-3">
                                        Oops! It seems that this page does not exist.
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</template>

<script>
import { Card } from "src/components/UIComponents";
import AppNavbar from "src/components/layout/NotLoggedInAppNavbar";
import AppFooter from "src/components/layout/NotLoggedInAppFooter";

export default {
    components: {
        Card,
        AppNavbar,
        AppFooter
    },
    data() {
        return {};
    },
    computed: {
    },
    created() {
    },
    methods: {

    }
};
</script>
