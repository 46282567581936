<template>
    <span>
        <Tooltip ref="tooltip" :anchor-element="'target'" :position="position" :content="'tooltipTemplate'" :open-delay="0">
            <template v-slot:tooltipTemplate="{ props }">
                <div>
                    <div><strong>{{ entity.name }}</strong></div>
                    <div v-if="entity.websiteAddress">
                        <a :href="formatUrl(entity.websiteAddress)" target="_blank" class="underline-link text-white">Visit Website</a>
                    </div>
                    <div v-if="entity.emailAddress">
                        <a :href="'mailto:' + entity.emailAddress" class="text-white">{{ entity.emailAddress }}</a>
                    </div>
                    <div v-if="entity.contactPhone">{{ contactPhone }}</div>
                    <div v-if="entity.notes">
                        <br /><strong>Notes</strong>
                        <br /><span v-html="entity.notes" />
                    </div>
                </div>
            </template>                  
        </Tooltip>
        <button class="k-button k-button-md k-rounded-md k-button-solid-warning k-button-solid-base" 
                title="info" 
                @click.prevent="showTooltip" 
                @mouseenter.capture="showTooltip" 
                @mouseleave.capture="hideTooltip">
                <span class="k-button-text">
                    <i class="fa fa-info-circle"></i>
                </span>
        </button>
    </span>
</template>

<style>

</style>

<script>
import { Tooltip } from '@progress/kendo-vue-tooltip';
import { formatPhone, formatUrl } from '@/helpers';

export default {
    name: 'SupplierTooltip',
    props: {
      entity: {
        type: Object,
        description: 'Entity to show details for'
      },
      position: {
        type: String,
        default: 'right',
        description: 'Location to show tooltip'
      }
    },
    components: {
        Tooltip
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        contactPhone: function() {
            return formatPhone(this.entity.contactPhone);
        },
    },
    methods: {
        showTooltip(event) {
            this.$refs.tooltip && this.$refs.tooltip.handleMouseOver(event);
        },
        hideTooltip(event) {
            setTimeout(() => {
                this.$refs.tooltip && this.$refs.tooltip.handleMouseOut(event);
            }, 1000);
            
        },
        formatUrl(u) {
            return formatUrl(u);
        },
    },
}
</script>