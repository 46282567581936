<template>
    <span>
        <Tooltip ref="tooltip" :anchor-element="'target'" :position="position" :content="'tooltipTemplate'" :open-delay="0">
            <template v-slot:tooltipTemplate="{ props }">
                <table>
                    <tr><td><strong>Created on:</strong></td><td>{{ createdDate }}</td></tr>
                    <tr><td><strong>Created by:</strong></td><td>{{entity.createdByName}}</td></tr>
                    <tr><td><strong>Last updated on:</strong></td><td>{{ modifiedDate }}</td></tr>
                    <tr><td><strong>Last updated by:</strong></td><td>{{entity.modifiedByName}}</td></tr>
                </table>
            </template>                  
        </Tooltip>
        <button class="k-button k-button-md k-rounded-md k-button-solid-warning k-button-solid-base" 
                title="info" 
                @click.prevent="(event) => $refs.tooltip && $refs.tooltip.handleMouseOver(event)" 
                @mouseenter.capture="(event) => $refs.tooltip && $refs.tooltip.handleMouseOver(event)" 
                @mouseleave.capture="(event) => $refs.tooltip && $refs.tooltip.handleMouseOut(event)">
                <span class="k-button-text">
                    <i class="fa fa-info-circle"></i>
                </span>
        </button>
    </span>
</template>

<style>

</style>

<script>
import { Tooltip } from '@progress/kendo-vue-tooltip';
import { formatDateTime } from '@/helpers';

export default {
    name: 'EntityTooltip',
    props: {
      entity: {
        type: Object,
        description: 'Entity to show details for'
      },
      position: {
        type: String,
        default: 'left',
        description: 'Location to show tooltip'
      }
    },
    components: {
        Tooltip
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        createdDate: function() {
            return formatDateTime(this.entity.createdDate);
        },
        modifiedDate: function() {
            return formatDateTime(this.entity.modifiedDate);
        }
    },
    methods: {

    },
}
</script>