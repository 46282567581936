// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const projectService = {
    getAll,
    getById,
    add,
    update,
    updatePercentComplete,
    updatePriority,
    deleteProject,
    getStageBoard,
    getStageBoardFilterValues,
    moveBoardCard
};

function getAll() {
    // return axios.get(`/projects/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/projects/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/projects/${id}`).then(handleResponse);
}

function add(project) {
    return axios.post('/projects', project).then(handleResponse);
}

function update(project) {
    return axios.put(`/projects/${project.projectId}`, project).then(handleResponse);
}

function updatePercentComplete(project) {
    return axios.put(`/projects/${project.projectId}/percentcomplete`, project).then(handleResponse);
}

function updatePriority(project) {
    return axios.put(`/projects/${project.projectId}/priority`, project).then(handleResponse);
}

function deleteProject(project) {
    return axios.delete(`/projects/${project.projectId}`).then(handleResponse);
}

// boards
function getStageBoard(refProjectStatusId) {
    return axios.get(`/projects/board/${refProjectStatusId}`).then(handleResponse);
}

function getStageBoardFilterValues(refProjectStatusId) {
    return axios.get(`/projects/board/${refProjectStatusId}/filtervalues`).then(handleResponse);
}

function moveBoardCard(id, refProjectStatusId, refIdeaStageId, displayOrder) {
    return axios.post(`/projects/board/${refProjectStatusId}/movecard`, { projectId: id, targetStageId: refIdeaStageId, displayOrder } ).then(handleResponse);
}