// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refComputingTypeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefComputingType
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refComputingTypes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refComputingTypes/${id}`).then(handleResponse);
}

function add(refComputingType) {
    return axios.post('/refComputingTypes', refComputingType).then(handleResponse);
}

function update(refComputingType) {
    return axios.put(`/refComputingTypes/${refComputingType.refComputingTypeId}`, refComputingType).then(handleResponse);
}

function deleteRefComputingType(refComputingType) {
    return axios.delete(`/refComputingTypes/${refComputingType.refComputingTypeId}`).then(handleResponse);
}
