<template>
    <NotificationGroup :style="position.topRight">
        <Fade :appear="visible">
            <Notification v-if="visible" :type="notificationType">
                <span>{{ content }}</span>
            </Notification>
        </Fade>
    </NotificationGroup>
</template>

<script>
import { NotificationGroup } from '@progress/kendo-vue-notification';
import { Notification } from '@progress/kendo-vue-notification';
import { Fade } from '@progress/kendo-vue-animation';

export default {
    name: 'PopupNotifications',
    props: {
    },
    components: {
        Fade,
        NotificationGroup,
        Notification,
    },
    data() {
        return {
            content: null,
            visible: false,
            timeoutId: undefined,
            position: {
                topRight: { top: '30px', right: '30px', alignItems: 'flex-end' },
            },
            notificationType: {
                style: 'success', 
                icon: true
            }
        }
    },
    computed: {
    },
    methods: {
        show(message, type) {
            clearTimeout(this.timeoutId);

            this.content = message;
            this.notificationType.style = type;
            this.visible = true;
            this.timeoutId = setTimeout(() => {
                this.visible = false;
            }, 2000);
        },
    },
}
</script>