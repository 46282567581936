// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const tagService = {
    getByAccount,
    getById,
    add,
    update,
    deleteTag
};

function getByAccount(accountId) {
    // return axios.get(`/tags/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/tags/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/tags/${id}`).then(handleResponse);
}

function add(tag) {
    return axios.post('/tags', tag).then(handleResponse);
}

function update(tag) {
    return axios.put(`/tags/${tag.tagId}`, tag).then(handleResponse);
}

function deleteTag(tag) {
    return axios.delete(`/tags/${tag.tagId}`).then(handleResponse);
}
