// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refProjectStageService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefProjectStage
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refProjectStages/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refProjectStages/${id}`).then(handleResponse);
}

function add(refProjectStage) {
    return axios.post('/refProjectStages', refProjectStage).then(handleResponse);
}

function update(refProjectStage) {
    return axios.put(`/refProjectStages/${refProjectStage.refProjectStageId}`, refProjectStage).then(handleResponse);
}

function deleteRefProjectStage(refProjectStage) {
    return axios.delete(`/refProjectStages/${refProjectStage.refProjectStageId}`).then(handleResponse);
}
