// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const importService = {
    uploadFile,
    processFile,
    checkStatus,

    uploadContacts,
    uploadAreas,
    uploadTags,
    uploadProjects,
    uploadApplications,
    uploadPortfolio
};

function uploadFile(importData, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("importData", JSON.stringify(importData));
    return axios.post(`/imports/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function processFile(importData) {
    return axios.post(`/imports/process`, importData).then(handleResponse);
}

function checkStatus(id) {
    return axios.get(`/imports/status/${id}`).then(handleResponse);
}


function uploadContacts(formData) {
    return axios.post(`/imports/contacts`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function uploadAreas(formData) {
    return axios.post(`/imports/areas`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function uploadTags(formData) {
    return axios.post(`/imports/tags`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function uploadProjects(formData) {
    return axios.post(`/imports/projects`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function uploadApplications(formData) {
    return axios.post(`/imports/applications`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}

function uploadPortfolio(portfolioId, formData) {
    return axios.post(`/imports/portfolios/${portfolioId}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);
}
