// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const exportService = {
    getContacts,
    getAreas,
    getTags,
    getProjects,
    getIdeas,
    getApplications,
    getApplicationsByPortfolioId,
    getPortfolio,
    getPortfolioFiltered,
    getPortfolioApplicationScores,
    getITServices,
    getPortfolioMultiMatrix,
    getApplicationIntegrationsMultiMatrix,
    getITServiceMultiMatrix,
    getSuppliers
};

function getContacts(contactIds) {
    // return axios.get(`/contacts/?accountId=${accountId}`).then(handleResponse);
    return axios({
        url: `/exports/contacts/`,
        method: 'POST',
        responseType: 'blob',
        data: { contactIds: contactIds }
    }).then(handleResponse);
}

function getAreas() {
    return axios({
        url: `/exports/areas/`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getTags() {
    return axios({
        url: `/exports/tags/`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getProjects(projectIds) {
    return axios({
        url: `/exports/projects/`,
        method: 'POST',
        responseType: 'blob',
        data: { projectIds: projectIds }
    }).then(handleResponse);
}

function getIdeas(ideaIds) {
    return axios({
        url: `/exports/ideas/`,
        method: 'POST',
        responseType: 'blob',
        data: { ideaIds: ideaIds }
    }).then(handleResponse);
}

function getApplications(applicationIds) {
    return axios({
        url: `/exports/applications/`,
        method: 'POST',
        responseType: 'blob',
        data: { applicationIds: applicationIds }
    }).then(handleResponse);
}

function getApplicationsByPortfolioId(portfolioId) {
    return axios({
        url: `/exports/applications/?portfolioId=${portfolioId}`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}


function getPortfolio(portfolioId) {
    return axios({
        url: `/exports/portfolios/${portfolioId}`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getPortfolioFiltered(portfolioId, applicationIds) {
    return axios({
        url: `/exports/portfolios/${portfolioId}/filtered`,
        method: 'POST',
        responseType: 'blob',
        data: {applicationIds: applicationIds}
    }).then(handleResponse);
}

function getPortfolioApplicationScores(portfolioId) {
    return axios({
        url: `/exports/portfolios/${portfolioId}/scores`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getITServices(itServiceIds) {
    return axios({
        url: `/exports/itservices/`,
        method: 'POST',
        responseType: 'blob',
        data: { itServiceIds: itServiceIds }
    }).then(handleResponse);
}


function getPortfolioMultiMatrix(portfolioId) {
    return axios({
        url: `/exports/portfolios/${portfolioId}/multimatrix`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getApplicationIntegrationsMultiMatrix(applicationId) {
    return axios({
        url: `/exports/applications/${applicationId}/integrationmultimatrix`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getITServiceMultiMatrix(itServiceIds) {
    return axios({
        url: `/exports/itservices/multimatrix`,
        method: 'POST',
        responseType: 'blob',
        data: {itServiceIds: itServiceIds}
    }).then(handleResponse);
}

function getSuppliers() {
    return axios({
        url: `/exports/suppliers/`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}
