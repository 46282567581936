import { mapState, mapGetters } from "vuex";
import { commandbarService } from '@/services';

import { router } from "@/helpers";

import { SYSTEM_ADMINISTRATOR,
         CERTIFIED_PROVIDER_ADMINISTRATOR,
         GLOBAL_ADMINISTRATOR,
         COMPANY_ADMINISTRATOR,
         APPLICATION_MANAGER,
         PORTFOLIO_ADMINISTRATOR,
         AUTHOR_USER,
         VIEW_USER } from "@/helpers";

const commandBarMixin = {
    name: 'commandBarMixin',
    computed: {
      ...mapState("authentication", {
        currentUser: state => state.user
      }),
      ...mapGetters("authentication", [
        "isInRole",
        "isSystemAdministrator",
      ]),      
      ...mapState("account", {
        currentAccount: state => state.currentAccount,
      }),
    },
    async mounted() {
       
    },
    methods: {
      async setCommandBarUser() {
        if (this.currentUser != null && !!this.currentUser.userId) {

            await window.CommandBar.boot(this.currentUser.userId,
              {
                  name: this.currentUser.name,
                  email: this.currentUser.emailAddress,
                  phone: this.currentUser.contactPhone,
                  workspace: this.currentAccount.workspaceName
              },
              {
                formFactor: {
                  type: "inline",
                  rootElement: "commandBarSpotlight",
                },
              }
            );

            // set roles into metadata
            const isSystemAdministrator = this.isSystemAdministrator;
            const isCertifiedProviderAdministrator = !isSystemAdministrator && this.isInRole(CERTIFIED_PROVIDER_ADMINISTRATOR);
            const isGlobalAdministrator = !isSystemAdministrator && this.isInRole(GLOBAL_ADMINISTRATOR);
            const isCompanyAdministrator = !isSystemAdministrator && this.isInRole(COMPANY_ADMINISTRATOR);
            const isApplicationManager = !isSystemAdministrator && this.isInRole(APPLICATION_MANAGER);
            const isPortfolioAdministrator = !isSystemAdministrator && this.isInRole(PORTFOLIO_ADMINISTRATOR);
            const isAuthorUser = !isSystemAdministrator && this.isInRole(AUTHOR_USER);
            const isViewUser = !isSystemAdministrator && this.isInRole(VIEW_USER);

            window.CommandBar.addMetadataBatch( {
                ENTERPRISE_ADMINISTRATOR: isSystemAdministrator,
                CERTIFIED_PROVIDER_ADMINISTRATOR: isCertifiedProviderAdministrator,
                GLOBAL_ADMINISTRATOR: isGlobalAdministrator,
                COMPANY_ADMINISTRATOR: isCompanyAdministrator,
                APPLICATION_MANAGER: isApplicationManager,
                PORTFOLIO_ADMINISTRATOR: isPortfolioAdministrator,
                AUTHOR_USER: isAuthorUser,
                VIEW_USER: isViewUser
            });

            // router
            window.CommandBar.addRouter((newUrl) => router.push(newUrl));
            
            // records and record actions
            const getApplications = () => commandbarService.getApplications();
            const getPortfolioApplications = () => commandbarService.getPortfolioApplications();
            const getContacts = () => commandbarService.getContacts();
            const getIdeas = () => commandbarService.getIdeas();
            const getITServices = () => commandbarService.getITServices();
            const getPortfolios = () => commandbarService.getPortfolios();
            const getProjects = () => commandbarService.getProjects();
            const getPrograms = () => commandbarService.getPrograms();
  

            window.CommandBar.addRecords(
                "portfolios", 
                getPortfolios, 
                {
                    labelKey: "name",
                    searchableFields: ["name", "portfolioNumber"],
                }
            );
        
            window.CommandBar.addRecordAction('portfolios', {
                text: 'Open Portfolio',
                name: 'open_portfolio',
                template: {
                    type: 'link',
                    value: '/portfolios/{{record.portfolioId}}',
                    operation: 'router' // how should the page open
                },
            });              

            window.CommandBar.addRecords(
                "ideas", 
                getIdeas, 
                {
                    labelKey: "name",
                    descriptionKey: "shortDescription",
                    searchableFields: ["name", "ideaNumber", "shortDescription", "description", "purposeComments", "goalComments", "significantComments", "outcomeComments", "driversDescription",
                                        "project", "programs", "portfolios", "areas", "drivers", "stage", "type", "priority"]
                }
            );
        
            window.CommandBar.addRecordAction('ideas', {
                text: 'Open Idea',
                name: 'open_idea',
                template: {
                    type: 'link',
                    value: '/ideas/edit/{{record.ideaId}}',
                    operation: 'router' // how should the page open
                },
            });               

            window.CommandBar.addRecords(
                "projects", 
                getProjects, 
                {
                    labelKey: "name",
                    descriptionKey: "description",
                    searchableFields: ["name", "projectNumber", "description", "type", "stage", "statuse", "priority",
                                        "portfolios", "programs", "areas", "drivers", "purposeComments", "goalComments", "significantComments", "outcomeComments"],
                }
            );
        
            window.CommandBar.addRecordAction('projects', {
                text: 'Open Project',
                name: 'open_project',
                template: {
                    type: 'link',
                    value: '/projects/edit/{{record.projectId}}',
                    operation: 'router' // how should the page open
                },
            });               

            window.CommandBar.addRecords(
                "programs", 
                getPrograms, 
                {
                    labelKey: "name",
                    descriptionKey: "shortDescription",
                    searchableFields: ["name", "programNumber", "shortDescription", "notes", "drivers"],
                }
            );
        
            window.CommandBar.addRecordAction('programs', {
                text: 'Open Program',
                name: 'open_program',
                template: {
                    type: 'link',
                    value: '/programs/edit/{{record.programId}}',
                    operation: 'router' // how should the page open
                },
            });               


            window.CommandBar.addRecords(
                "applications", 
                getApplications, 
                {
                    labelKey: "name",
                    descriptionKey: "description",
                    searchableFields: ["name", "description", "applicationNumber", "shortDescription", "primaryUseCase", "applicationSuite", "state", "types",
                                        "technologyTypes", "portfolios", "projects", "areas", "businessAreas"],
                }
            );
        
            window.CommandBar.addRecordAction('applications', {
                text: 'Open Application',
                name: 'open_application',
                template: {
                    type: 'link',
                    value: '/applications/edit/{{record.applicationId}}',
                    operation: 'router' // how should the page open
                },
            });

            window.CommandBar.addRecords(
                "portfolio Applications", 
                getPortfolioApplications, 
                {
                    labelKey: "name",
                    descriptionKey: "description",
                    searchableFields: ["name", "description", "processes"],
                }
            );
        
            window.CommandBar.addRecordAction('portfolio Applications', {
                text: 'Open Portfolio Application',
                name: 'open_portfolio_application',
                template: {
                    type: 'link',
                    value: '/applications/workshop/{{record.portfolioId}}/{{record.applicationId}}',
                    operation: 'router' // how should the page open
                },
            });

            

            window.CommandBar.addRecords(
                "iTServices", 
                getITServices, 
                {
                    labelKey: "name",
                    descriptionKey: "description",
                    searchableFields: ["name", "description", "itServiceNumber", "shortDescription", "itServiceSuite", "suite", "state",
                                        "functionalAreas", "computingTypes", "projects", "areas", "classifications", "platforms"],
                }
            );
            
            window.CommandBar.addRecordAction('iTServices', {
                text: 'Open IT Service',
                name: 'open_it_service',
                template: {
                    type: 'link',
                    value: '/itservices/edit/{{record.itServiceId}}',
                    operation: 'router' // how should the page open
                },
            });


            window.CommandBar.addRecords(
                "contacts", 
                getContacts, 
                {
                    labelKey: "name",
                    descriptionKey: "position",
                    searchableFields: ["name", "position", "company", "emailAddress", "contactPhone", "areas"],
                }
            );
            
            window.CommandBar.addRecordAction('contacts', {
                text: 'Open Contact',
                name: 'open_contact',
                template: {
                    type: 'link',
                    value: '/contacts/edit/{{record.contactId}}',
                    operation: 'router' // how should the page open
                },
            });            
        }
      },
      shutdownCommandBar() {
        window.CommandBar.shutdown();
      }
    }
};
  
export default commandBarMixin;