import _ from 'lodash';
import Vue from 'vue';
import { itServiceService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import { v4 as uuidv4 } from 'uuid';
import {
    GET_ALL,
    GET_BY_ID,
    GET_NEW,
    ADD,
    UPDATE,
    DELETE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    ADD_CONTACT,
    REMOVE_CONTACT,
    ADD_PROJECT,
    REMOVE_PROJECT,
    ADD_AREA,
    REMOVE_AREA,

    ADD_CLASSIFICATION,
    REMOVE_CLASSIFICATION,
    ADD_COMPUTING_TYPE,
    REMOVE_COMPUTING_TYPE,
    ADD_PLATFORM,
    REMOVE_PLATFORM,

    ADD_APPLICATION_STATE,
    REMOVE_APPLICATION_STATE,

    ADD_APPLICATION_IT_SERVICE,
    SAVE_APPLICATION_IT_SERVICE,
    REMOVE_APPLICATION_IT_SERVICE,
    SET_CURRENT_APPLICATION_IT_SERVICE,
    CLEAR_CURRENT_APPLICATION_IT_SERVICE,
    UPDATE_CURRENT_APPLICATION_IT_SERVICE,

    ADD_IT_SERVICE_IT_SERVICE,
    SAVE_IT_SERVICE_IT_SERVICE,
    REMOVE_IT_SERVICE_IT_SERVICE,
    SAVE_USES_IT_SERVICE,
    REMOVE_USES_IT_SERVICE,
    SET_CURRENT_IT_SERVICE_IT_SERVICE,
    CLEAR_CURRENT_IT_SERVICE_IT_SERVICE,
    UPDATE_CURRENT_IT_SERVICE_IT_SERVICE,

    SET_FRAMEWORK_ITEMS,

    SET_MANUFACTURER,
    SET_VENDOR
  } from "./mutations.type";
import {
    FRAMEWORK_TSM
} from "@/helpers";


function getBlankProject() {
    return {
        itServiceProjectId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        projectId: null,
        name: ""
    }
}

function getBlankContact() {
    return {
        itServiceContactId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        contactId: null,
        refContactTypeId: null,
        name: "",
        refContactTypeName: ""
    }
}

function getBlankArea() {
    return {
        itServiceAreaId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        areaId: null,
        name: ""
    };
}

function getBlankState() {
    return {
        itServiceStateId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        refApplicationStateId: null,
        currentState: true,
        plannedDate: null,
        actualDate: null,
        notes: "",
        refApplicationStateName: ""
    };
}

function getBlankClassification() {
    return {
        itServiceClassificationId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        refClassificationId: null,
        refClassificationName: ""
    };
}


function getBlankComputingType() {
    return {
        itServiceComputingTypeId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        refComputingTypeId: null,
        refComputingTypeName: ""
    };
}

function getBlankPlatform() {
    return {
        itServicePlatformId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        refPlatformId: null,
        refPlatformName: ""
    };
}

function getBlankApplicationITService() {
    return {
        applicationITServiceId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        notes: "",
        itServiceName: "",
        applicationName: ""
    };
}

function getBlankITServiceITService() {
    return {
        itServiceITServiceId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        otherITServiceId: GUID_EMPTY,
        notes: "",
        otherITServiceName: "",
        applicationName: ""
    };
}

function getBlankFrameworkItem() {
    return {
        applicationFrameworkId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        frameworkItemId: GUID_EMPTY,
        hierarchyId: ""
    };
}

function getBlankITService() {
    return {
        itServiceId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        linkedApplicationId: null,
        name: "",
        shortDescription: "",
        longDescription: "",
        manufacturerSupplierId: null,
        manufacturerSupplier: {},
        vendorSupplierId: null,
        vendorSupplier: {},
        sourceList: "",
        isSuite: false,
        parentSuiteITServiceId: null,
        parentSuiteITService: {},
        lifecycleStartDate: null,
        lifecycleEndDate: null,
        notes: "",
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        applicationITServices: [],
        itServiceITServices: [],
        usesITServices: [],
        itServiceAreas: [],
        itServiceClassifications: [],
        itServiceComputingTypes: [],
        itServiceContacts: [],
        itServicePlatforms: [],
        itServiceProjects: [],
        itServiceStates: [],
        itServiceFrameworkItems: []
    };
}

export const itservices = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentITService: getBlankITService(),
        currentApplicationITService: getBlankApplicationITService(),
        // newApplicationITServiceTemporaryId: -1,
        currentITServiceITService: getBlankITServiceITService(),
        // newITServiceITServiceTemporaryId: -1
    },
    getters: {
        getCurrentITServiceCurrentStates: state => {
            return state.currentITService.itServiceStates.filter(s => s.currentState);
        },
        getSuiteITServices: (state) => (id) => {
            return state.items.filter(item => item.isSuite);
        },
    },

    actions: {
        [GET_ALL]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    itServiceService.getAll()
                        .then(
                            itServices => {
                                commit(GET_ALL_SUCCESS, itServices);
                                resolve(itServices);
                            },
                            error =>  {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_ID]({ commit }, itServiceId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return itServiceService.getById(itServiceId)
                    .then(
                        itService => {
                            commit(GET_BY_ID_SUCCESS, itService);
                            resolve(itService);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_NEW]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return itServiceService.getNew()
                    .then(
                        itService => {
                            commit(GET_BY_ID_SUCCESS, itService);
                            resolve(itService);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },        
        [ADD]({ dispatch, commit }, itService) {
            return itServiceService.add(itService);
        },
        [UPDATE]({ dispatch, commit }, itService ) {
            return itServiceService.update(itService);
        },
        [DELETE]({ dispatch, commit }, itService) {
            return itServiceService.deleteITService(itService);
        }
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, itServices) {
            state.status = { loaded: true };
            state.items = itServices;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, itService) {
            state.status = { loaded: true };
            state.currentITService = itService;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentITService = getBlankITService();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentITService = getBlankITService();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentITService, {
                [field]: value
            });
        },
        [ADD_CONTACT](state, { contactId, contactTypeId }) {
            var newContact = getBlankContact();
            newContact.contactId = contactId;
            newContact.refContactTypeId = contactTypeId;
            state.currentITService.itServiceContacts.push(newContact);
        },
        [REMOVE_CONTACT](state, { contactId, contactTypeId }) {
            let index = _.findIndex(state.currentITService.itServiceContacts, { 'contactId': contactId, 'refContactTypeId': contactTypeId });
            if (index > -1) {
                state.currentITService.itServiceContacts.splice(index, 1);
            }
            // _.remove(state.currentITService.itServiceContacts, { 'contactId': contactId, 'refContactTypeId': contactTypeId });
        },
        [ADD_PROJECT](state, projectId) {
            var newProject = getBlankProject();
            newProject.projectId = projectId;
            state.currentITService.itServiceProjects.push(newProject);
        },
        [REMOVE_PROJECT](state, projectId) {
            let index = _.findIndex(state.currentITService.itServiceProjects, { 'projectId': projectId });
            if (index > -1) {
                state.currentITService.itServiceProjects.splice(index, 1);
            }
            // _.remove(state.currentITService.itServiceProjects, { 'projectId': projectId });
        },
        [ADD_AREA](state, areaId) {
            var newArea = getBlankArea();
            newArea.areaId = areaId;
            state.currentITService.itServiceAreas.push(newArea);
        },
        [REMOVE_AREA](state, areaId) {
            let index = _.findIndex(state.currentITService.itServiceAreas, { 'areaId': areaId });
            if (index > -1) {
                state.currentITService.itServiceAreas.splice(index, 1);
            }
            //_.remove(state.currentITService.itServiceAreas, { 'areaId': areaId });
        },

        [ADD_CLASSIFICATION](state, classificationId) {
            var newEntry = getBlankClassification();
            newEntry.refClassificationId = classificationId;
            state.currentITService.itServiceClassifications.push(newEntry);
        },
        [REMOVE_CLASSIFICATION](state, classificationId) {
            let index = _.findIndex(state.currentITService.itServiceClassifications, { 'refClassificationId': classificationId });
            if (index > -1) {
                state.currentITService.itServiceClassifications.splice(index, 1);
            }
        },
        [ADD_COMPUTING_TYPE](state, computingTypeId) {
            var newEntry = getBlankComputingType();
            newEntry.refComputingTypeId = computingTypeId;
            state.currentITService.itServiceComputingTypes.push(newEntry);
        },
        [REMOVE_COMPUTING_TYPE](state, computingTypeId) {
            let index = _.findIndex(state.currentITService.itServiceComputingTypes, { 'refComputingTypeId': computingTypeId });
            if (index > -1) {
                state.currentITService.itServiceComputingTypes.splice(index, 1);
            }
        },
        [ADD_PLATFORM](state, platformId) {
            var newEntry = getBlankPlatform();
            newEntry.refPlatformId = platformId;
            state.currentITService.itServicePlatforms.push(newEntry);
        },
        [REMOVE_PLATFORM](state, platformId) {
            let index = _.findIndex(state.currentITService.itServicePlatforms, { 'refPlatformId': platformId });
            if (index > -1) {
                state.currentITService.itServicePlatforms.splice(index, 1);
            }
        },


        [SET_MANUFACTURER](state, supplier) {
            if (supplier && supplier.supplierId) {
                state.currentITService.manufacturerSupplier = supplier;
                state.currentITService.manufacturerSupplierId = supplier.supplierId;
            } else {
                state.currentITService.manufacturerSupplier = null;
                state.currentITService.manufacturerSupplierId = null;
            }
        },
        [SET_VENDOR](state, supplier) {
            if (supplier && supplier.supplierId) {
                state.currentITService.vendorSupplier = supplier;
                state.currentITService.vendorSupplierId = supplier.supplierId;
            } else {
                state.currentITService.vendorSupplier = null;
                state.currentITService.vendorSupplierId = null;
            }
        },


        [ADD_APPLICATION_STATE](state, applicationStateId) {
            // first clear the states
            _.remove(state.currentITService.itServiceStates, function(val) { return true });
            // add the new state
            var newEntry = getBlankState();
            newEntry.refApplicationStateId = applicationStateId;
            newEntry.currentState = true;
            state.currentITService.itServiceStates.push(newEntry);
        },
        [REMOVE_APPLICATION_STATE](state, applicationStateId) {
            let index = _.findIndex(state.currentITService.itServiceStates, { 'refApplicationStateId': applicationStateId });
            if (index > -1) {
                state.currentITService.itServiceStates.splice(index, 1);
            }
            // _.remove(state.currentITService.itServiceStates, { 'refApplicationStateId': applicationStateId });
        },

        [ADD_APPLICATION_IT_SERVICE](state) {
            var newEntry = getBlankApplicationITService();
            newEntry.applicationITServiceId = uuidv4(); //state.newApplicationITServiceTemporaryId;
            //state.newApplicationITServiceTemporaryId = state.newApplicationITServiceTemporaryId - 1;
            state.currentITService.applicationITServices.push(newEntry);
            state.currentApplicationITService = newEntry;
        },
        [SAVE_APPLICATION_IT_SERVICE](state, applicationITService) {
            if (applicationITService.applicationITServiceId == GUID_EMPTY) {
                applicationITService.applicationITServiceId = uuidv4(); //state.newApplicationITServiceTemporaryId;
                // state.newApplicationITServiceTemporaryId = state.newApplicationITServiceTemporaryId - 1;
            }
            if (state.currentITService.applicationITServices.find(i => i.applicationITServiceId == applicationITService.applicationITServiceId)) {
                Vue.set(state.currentITService.applicationITServices, 
                        state.currentITService.applicationITServices.findIndex(i => i.applicationITServiceId == applicationITService.applicationITServiceId),
                        applicationITService);
            } else {
                state.currentITService.applicationITServices.push(applicationITService);
            }

            state.currentApplicationITService = applicationITService;
        },
        [REMOVE_APPLICATION_IT_SERVICE](state, applicationITServiceId) {
            state.currentApplicationITService = getBlankApplicationITService();
            _.remove(state.currentITService.applicationITServices, { 'applicationITServiceId': applicationITServiceId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentITService.applicationITServices = state.currentITService.applicationITServices.slice(0);
        },
        [SET_CURRENT_APPLICATION_IT_SERVICE](state, applicationITService) {
            state.currentApplicationITService = applicationITService;
        },
        [CLEAR_CURRENT_APPLICATION_IT_SERVICE](state) {
            state.currentApplicationITService = getBlankApplicationITService();
        },
        [UPDATE_CURRENT_APPLICATION_IT_SERVICE](state, { field, value }) {
            Object.assign(state.currentApplicationITService, {
                [field]: value
            });
        },

        [ADD_IT_SERVICE_IT_SERVICE](state) {
            var newEntry = getBlankITServiceITService();
            newEntry.itServiceITServiceId = uuidv4(); //state.newITServiceITServiceTemporaryId;
            //state.newITServiceITServiceTemporaryId = state.newITServiceITServiceTemporaryId - 1;
            state.currentITService.itServiceITServices.push(newEntry);
            state.currentITServiceITService = newEntry;
        },
        
        [SAVE_IT_SERVICE_IT_SERVICE](state, itServiceITService) {
            if (itServiceITService.itServiceITServiceId == GUID_EMPTY) {
                itServiceITService.itServiceITServiceId = uuidv4(); //state.newITServiceITServiceTemporaryId;
                // state.newITServiceITServiceTemporaryId = state.newITServiceITServiceTemporaryId - 1;
            }

            if (state.currentITService.itServiceITServices.find(i => i.itServiceITServiceId == itServiceITService.itServiceITServiceId)) {
                Vue.set(state.currentITService.itServiceITServices, 
                        state.currentITService.itServiceITServices.findIndex(i => i.itServiceITServiceId == itServiceITService.itServiceITServiceId),
                        itServiceITService);
            } else {
                state.currentITService.itServiceITServices.push(itServiceITService);
            }

            state.currentITServiceITService = itServiceITService;
        },
        [REMOVE_IT_SERVICE_IT_SERVICE](state, itServiceITServiceId) {
            state.currentITServiceITService = getBlankITServiceITService();
            _.remove(state.currentITService.itServiceITServices, { 'itServiceITServiceId': itServiceITServiceId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentITService.itServiceITServices = state.currentITService.itServiceITServices.slice(0);
        },

        [SAVE_USES_IT_SERVICE](state, itServiceITService) {
            if (itServiceITService.itServiceITServiceId == GUID_EMPTY) {
                itServiceITService.itServiceITServiceId = uuidv4(); //state.newITServiceITServiceTemporaryId;
                // state.newITServiceITServiceTemporaryId = state.newITServiceITServiceTemporaryId - 1;
            }

            if (state.currentITService.usesITServices.find(i => i.itServiceITServiceId == itServiceITService.itServiceITServiceId)) {
                Vue.set(state.currentITService.usesITServices, 
                        state.currentITService.usesITServices.findIndex(i => i.itServiceITServiceId == itServiceITService.itServiceITServiceId),
                        itServiceITService);
            } else {
                state.currentITService.usesITServices.push(itServiceITService);
            }

            state.currentITServiceITService = itServiceITService;
        },
        [REMOVE_USES_IT_SERVICE](state, itServiceITServiceId) {
            state.currentITServiceITService = getBlankITServiceITService();
            _.remove(state.currentITService.usesITServices, { 'itServiceITServiceId': itServiceITServiceId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentITService.usesITServices = state.currentITService.usesITServices.slice(0);
        },


        [SET_CURRENT_IT_SERVICE_IT_SERVICE](state, itServiceITService) {
            state.currentITServiceITService = itServiceITService;
        },
        [CLEAR_CURRENT_IT_SERVICE_IT_SERVICE](state) {
            state.currentITServiceITService = getBlankITServiceITService();
        },
        [UPDATE_CURRENT_IT_SERVICE_IT_SERVICE](state, { field, value }) {
            Object.assign(state.currentITServiceITService, {
                [field]: value
            });
        },


        [SET_FRAMEWORK_ITEMS](state, { items, selected}) {
            // take a list of frameworkItemIds and add or remove from current apps applicationFrameworkItems
            for (let item of items) {     
                // is the framework item already in the applicationFrameworkItems?
                let existingItemIndex = state.currentITService.itServiceFrameworkItems.findIndex(f => f.frameworkItemId == item);

                if (selected && existingItemIndex < 0) {
                    // add
                    let newEntry = getBlankFrameworkItem();
                    newEntry.frameworkItemId = item;
                    state.currentITService.itServiceFrameworkItems.push(newEntry);
                } else if (!selected && existingItemIndex >= 0) {
                    // remove
                    state.currentITService.itServiceFrameworkItems.splice(existingItemIndex, 1);
                }
            }
        },
        
        // 
    }
}
