export const GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
export const GUID_NONE = "6d9e7134-16bd-43b1-8ea5-6a745a86ce86";

// various category20 colors from https://github.com/d3/d3-3.x-api-reference/blob/master/Ordinal-Scales.md
export const COLOR_LIST = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#aec7e8', '#ffbb78', '#98df8a', '#ff9896', '#c5b0d5', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5', '#393b79', 
                           '#5254a3', '#6b6ecf', '#9c9ede', '#637939', '#8ca252', '#b5cf6b', '#cedb9c', '#8c6d31', '#bd9e39', '#e7ba52', '#e7cb94', '#843c39', '#ad494a', '#d6616b', '#e7969c', '#7b4173', '#a55194', '#ce6dbd', '#de9ed6', '#3182bd', '#6baed6', 
                           '#9ecae1', '#c6dbef', '#e6550d', '#fd8d3c', '#fdae6b', '#fdd0a2', '#31a354', '#74c476', '#a1d99b', '#c7e9c0', '#756bb1', '#9e9ac8', '#bcbddc', '#dadaeb', '#636363', '#969696', '#bdbdbd', '#d9d9d9'];

// for context diagram
export const CONTEXT_LINK_COLOR_LIST = ['#1098F7', '#068D9D', '#373F51', '#6A2E35', '#D4ADCF', '#29339B', '#FF3864', '#320D6D', '#88665D', '#414288'];
export const CONTEXT_PRIMARY_APPLICATION_NODE_COLOR = '#FED8B1';
export const CONTEXT_APPLICATION_NODE_COLOR = 'orange';
export const CONTEXT_PRIMARY_IT_SERVICE_NODE_COLOR = '#CAF7CA';
export const CONTEXT_IT_SERVICE_NODE_COLOR = 'lightgreen';
export const CONTEXT_DEFAULT_LINK_COLOR = 'darkgray';

// header template for editable columns
export const AG_GRID_EDITABLE_HEADER_TEMPLATE = '<div class="ag-cell-label-container" role="presentation">' +
                                                '     <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>' +
                                                '     <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                                                '         <span ref="eText" class="ag-header-cell-text"></span>' +
                                                '         <span class="ag-header-icon ms-1"><i class="fa fa-pencil-square-o"></i></span>' +
                                                '         <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>' +
                                                '         <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>' +
                                                '         <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>' +
                                                '         <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>' +
                                                '         <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>' +
                                                '     </div>' +
                                                '</div>';

// kendo editor tools
export const KENDO_EDITOR_BASIC_TOOLS = ['formatting','foreColor','backColor','bold','italic','underline','strikethrough','justifyLeft','justifyCenter','justifyRight','insertUnorderedList','insertOrderedList','indent','outdent']
export const KENDO_EDITOR_ENHANCED_TOOLS = ['formatting','fontName','fontSize','foreColor','backColor','bold','italic','underline','strikethrough','justifyLeft','justifyCenter','justifyRight','insertUnorderedList','insertOrderedList','indent','outdent']

// default grid column width (px)
export const KENDO_GRID_COLUMN_DEFAULT_WIDTH = 125;

// account types
export const ACCOUNT_TYPE_CLIENT = 'e9faa66c-be35-4d29-b565-34d95f84e9a1';
export const ACCOUNT_TYPE_CERTIFIED_PROVIDER = 'e9faa66c-be35-4d29-b565-34d95f84e9a2';
export const ACCOUNT_TYPE_PARTNER = 'e9faa66c-be35-4d29-b565-34d95f84e9a3';

export const ACCOUNT_TYPE_CLIENT_NAME = 'Client';
export const ACCOUNT_TYPE_CERTIFIED_PROVIDER_NAME = 'Certified Provider';
export const ACCOUNT_TYPE_PARTNER_NAME = 'Premier Partner';

// default timestamps for lifecycle diagrams
export const LIFECYCLE_MIN_DATE = Date.UTC(2020, 0, 1);
export const LIFECYCLE_MAX_DATE = Date.UTC(2199, 11, 31);
export const LIFECYCLE_AT_RISK_MONTHS = 12;

// lifecycle states
export const LIFECYCLE_STATE_ACCEPTABLE = 'Acceptable';
export const LIFECYCLE_STATE_ATRISK = 'At Risk';
export const LIFECYCLE_STATE_CRITICAL = 'Critical';

// lifecycle colors
export const LIFECYCLE_GREEN = '#90ed7d';
export const LIFECYCLE_YELLOW = '#e4d354';
export const LIFECYCLE_RED = '#f45b5b';


// initial account setup steps
export const ACCOUNT_SETUP_STEP1 = 'COMPANY_DETAILS';
export const ACCOUNT_SETUP_STEP2 = 'FIRST_PORTFOLIO';
export const ACCOUNT_SETUP_STEP3 = 'APPS';
export const ACCOUNT_SETUP_STEP4 = 'BUSINESS_AREAS';
export const ACCOUNT_SETUP_STEP5 = 'CONTACTS';
export const SETUP_COMPLETE = 'COMPLETE';

// subscription setup steps
export const SUBSCRIPTION_SETUP_STEP1 = 'SELECT_PLAN';
export const SUBSCRIPTION_SETUP_STEP2 = 'BILLING_ADDRESS';
export const SUBSCRIPTION_SETUP_STEP3 = 'PAYMENT';
export const SUBSCRIPTION_SETUP_STEP_CONSULT = 'CONSULT';

// countries
export const CANADA_ID = 'cc7caa66-01e1-4853-98be-9417960f5f3c';
export const USA_ID = 'f705adcd-430b-41e8-825e-260fcad189d3';

// canada provinces
export const PROVINCE_LIST = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'];
// usa states
export const STATE_LIST = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'American Samoa', 'Guam', 'Northern Mariana Islands', 'Puerto Rico', 'Virgin Islands'];

// timezones
export const TIMEZONE_LIST = [
    { id: 'Dateline Standard Time', name: '(UTC-12:00) International Date Line West' }, 
    { id: 'UTC-11', name: '(UTC-11:00) Coordinated Universal Time-11' }, 
    { id: 'Aleutian Standard Time', name: '(UTC-10:00) Aleutian Islands' }, 
    { id: 'Hawaiian Standard Time', name: '(UTC-10:00) Hawaii' }, 
    { id: 'Marquesas Standard Time', name: '(UTC-09:30) Marquesas Islands' }, 
    { id: 'Alaskan Standard Time', name: '(UTC-09:00) Alaska' }, 
    { id: 'UTC-09', name: '(UTC-09:00) Coordinated Universal Time-09' }, 
    { id: 'Yukon Standard Time', name: '(UTC-07:00) Yukon' }, 
    { id: 'Pacific Standard Time (Mexico)', name: '(UTC-08:00) Baja California' }, 
    { id: 'UTC-08', name: '(UTC-08:00) Coordinated Universal Time-08' }, 
    { id: 'Pacific Standard Time', name: '(UTC-08:00) Pacific Time (US & Canada)' }, 
    { id: 'US Mountain Standard Time', name: '(UTC-07:00) Arizona' }, 
    { id: 'Mountain Standard Time (Mexico)', name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan' }, 
    { id: 'Mountain Standard Time', name: '(UTC-07:00) Mountain Time (US & Canada)' }, 
    { id: 'Central America Standard Time', name: '(UTC-06:00) Central America' }, 
    { id: 'Central Standard Time', name: '(UTC-06:00) Central Time (US & Canada)' }, 
    { id: 'Easter Island Standard Time', name: '(UTC-06:00) Easter Island' }, 
    { id: 'Central Standard Time (Mexico)', name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey' }, 
    { id: 'Canada Central Standard Time', name: '(UTC-06:00) Saskatchewan' }, 
    { id: 'SA Pacific Standard Time', name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco' }, 
    { id: 'Eastern Standard Time (Mexico)', name: '(UTC-05:00) Chetumal' }, 
    { id: 'Eastern Standard Time', name: '(UTC-05:00) Eastern Time (US & Canada)' }, 
    { id: 'Haiti Standard Time', name: '(UTC-05:00) Haiti' }, 
    { id: 'Cuba Standard Time', name: '(UTC-05:00) Havana' }, 
    { id: 'US Eastern Standard Time', name: '(UTC-05:00) Indiana (East)' }, 
    { id: 'Turks And Caicos Standard Time', name: '(UTC-05:00) Turks and Caicos' }, 
    { id: 'Paraguay Standard Time', name: '(UTC-04:00) Asuncion' }, 
    { id: 'Atlantic Standard Time', name: '(UTC-04:00) Atlantic Time (Canada)' }, 
    { id: 'Venezuela Standard Time', name: '(UTC-04:00) Caracas' }, 
    { id: 'Central Brazilian Standard Time', name: '(UTC-04:00) Cuiaba' }, 
    { id: 'SA Western Standard Time', name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' }, 
    { id: 'Pacific SA Standard Time', name: '(UTC-04:00) Santiago' }, 
    { id: 'Newfoundland Standard Time', name: '(UTC-03:30) Newfoundland' }, 
    { id: 'Tocantins Standard Time', name: '(UTC-03:00) Araguaina' }, 
    { id: 'E. South America Standard Time', name: '(UTC-03:00) Brasilia' }, 
    { id: 'SA Eastern Standard Time', name: '(UTC-03:00) Cayenne, Fortaleza' }, 
    { id: 'Argentina Standard Time', name: '(UTC-03:00) City of Buenos Aires' }, 
    { id: 'Greenland Standard Time', name: '(UTC-03:00) Greenland' }, 
    { id: 'Montevideo Standard Time', name: '(UTC-03:00) Montevideo' }, 
    { id: 'Magallanes Standard Time', name: '(UTC-03:00) Punta Arenas' }, 
    { id: 'Saint Pierre Standard Time', name: '(UTC-03:00) Saint Pierre and Miquelon' }, 
    { id: 'Bahia Standard Time', name: '(UTC-03:00) Salvador' }, 
    { id: 'UTC-02', name: '(UTC-02:00) Coordinated Universal Time-02' }, 
    { id: 'Mid-Atlantic Standard Time', name: '(UTC-02:00) Mid-Atlantic - Old' }, 
    { id: 'Azores Standard Time', name: '(UTC-01:00) Azores' }, 
    { id: 'Cape Verde Standard Time', name: '(UTC-01:00) Cabo Verde Is.' }, 
    { id: 'UTC', name: '(UTC) Coordinated Universal Time' }, 
    { id: 'GMT Standard Time', name: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London' }, 
    { id: 'Greenwich Standard Time', name: '(UTC+00:00) Monrovia, Reykjavik' }, 
    { id: 'Sao Tome Standard Time', name: '(UTC+00:00) Sao Tome' }, 
    { id: 'Morocco Standard Time', name: '(UTC+01:00) Casablanca' }, 
    { id: 'W. Europe Standard Time', name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' }, 
    { id: 'Central Europe Standard Time', name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' }, 
    { id: 'Romance Standard Time', name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' }, 
    { id: 'Central European Standard Time', name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' }, 
    { id: 'W. Central Africa Standard Time', name: '(UTC+01:00) West Central Africa' }, 
    { id: 'Jordan Standard Time', name: '(UTC+02:00) Amman' }, 
    { id: 'GTB Standard Time', name: '(UTC+02:00) Athens, Bucharest' }, 
    { id: 'Middle East Standard Time', name: '(UTC+02:00) Beirut' }, 
    { id: 'Egypt Standard Time', name: '(UTC+02:00) Cairo' }, 
    { id: 'E. Europe Standard Time', name: '(UTC+02:00) Chisinau' }, 
    { id: 'Syria Standard Time', name: '(UTC+02:00) Damascus' }, 
    { id: 'West Bank Standard Time', name: '(UTC+02:00) Gaza, Hebron' }, 
    { id: 'South Africa Standard Time', name: '(UTC+02:00) Harare, Pretoria' }, 
    { id: 'FLE Standard Time', name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' }, 
    { id: 'Israel Standard Time', name: '(UTC+02:00) Jerusalem' }, 
    { id: 'Kaliningrad Standard Time', name: '(UTC+02:00) Kaliningrad' }, 
    { id: 'Sudan Standard Time', name: '(UTC+02:00) Khartoum' }, 
    { id: 'Libya Standard Time', name: '(UTC+02:00) Tripoli' }, 
    { id: 'Namibia Standard Time', name: '(UTC+02:00) Windhoek' }, 
    { id: 'Arabic Standard Time', name: '(UTC+03:00) Baghdad' }, 
    { id: 'Turkey Standard Time', name: '(UTC+03:00) Istanbul' }, 
    { id: 'Arab Standard Time', name: '(UTC+03:00) Kuwait, Riyadh' }, 
    { id: 'Belarus Standard Time', name: '(UTC+03:00) Minsk' }, 
    { id: 'Russian Standard Time', name: '(UTC+03:00) Moscow, St. Petersburg' }, 
    { id: 'E. Africa Standard Time', name: '(UTC+03:00) Nairobi' }, 
    { id: 'Iran Standard Time', name: '(UTC+03:30) Tehran' }, 
    { id: 'Arabian Standard Time', name: '(UTC+04:00) Abu Dhabi, Muscat' }, 
    { id: 'Astrakhan Standard Time', name: '(UTC+04:00) Astrakhan, Ulyanovsk' }, 
    { id: 'Azerbaijan Standard Time', name: '(UTC+04:00) Baku' }, 
    { id: 'Russia Time Zone 3', name: '(UTC+04:00) Izhevsk, Samara' }, 
    { id: 'Mauritius Standard Time', name: '(UTC+04:00) Port Louis' }, 
    { id: 'Saratov Standard Time', name: '(UTC+04:00) Saratov' }, 
    { id: 'Georgian Standard Time', name: '(UTC+04:00) Tbilisi' }, 
    { id: 'Volgograd Standard Time', name: '(UTC+04:00) Volgograd' }, 
    { id: 'Caucasus Standard Time', name: '(UTC+04:00) Yerevan' }, 
    { id: 'Afghanistan Standard Time', name: '(UTC+04:30) Kabul' }, 
    { id: 'West Asia Standard Time', name: '(UTC+05:00) Ashgabat, Tashkent' }, 
    { id: 'Ekaterinburg Standard Time', name: '(UTC+05:00) Ekaterinburg' }, 
    { id: 'Pakistan Standard Time', name: '(UTC+05:00) Islamabad, Karachi' }, 
    { id: 'Qyzylorda Standard Time', name: '(UTC+05:00) Qyzylorda' }, 
    { id: 'India Standard Time', name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' }, 
    { id: 'Sri Lanka Standard Time', name: '(UTC+05:30) Sri Jayawardenepura' }, 
    { id: 'Nepal Standard Time', name: '(UTC+05:45) Kathmandu' }, 
    { id: 'Central Asia Standard Time', name: '(UTC+06:00) Astana' }, 
    { id: 'Bangladesh Standard Time', name: '(UTC+06:00) Dhaka' }, 
    { id: 'Omsk Standard Time', name: '(UTC+06:00) Omsk' }, 
    { id: 'Myanmar Standard Time', name: '(UTC+06:30) Yangon (Rangoon)' }, 
    { id: 'SE Asia Standard Time', name: '(UTC+07:00) Bangkok, Hanoi, Jakarta' }, 
    { id: 'Altai Standard Time', name: '(UTC+07:00) Barnaul, Gorno-Altaysk' }, 
    { id: 'W. Mongolia Standard Time', name: '(UTC+07:00) Hovd' }, 
    { id: 'North Asia Standard Time', name: '(UTC+07:00) Krasnoyarsk' }, 
    { id: 'N. Central Asia Standard Time', name: '(UTC+07:00) Novosibirsk' }, 
    { id: 'Tomsk Standard Time', name: '(UTC+07:00) Tomsk' }, 
    { id: 'China Standard Time', name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi' }, 
    { id: 'North Asia East Standard Time', name: '(UTC+08:00) Irkutsk' }, 
    { id: 'Singapore Standard Time', name: '(UTC+08:00) Kuala Lumpur, Singapore' }, 
    { id: 'W. Australia Standard Time', name: '(UTC+08:00) Perth' }, 
    { id: 'Taipei Standard Time', name: '(UTC+08:00) Taipei' }, 
    { id: 'Ulaanbaatar Standard Time', name: '(UTC+08:00) Ulaanbaatar' }, 
    { id: 'Aus Central W. Standard Time', name: '(UTC+08:45) Eucla' }, 
    { id: 'Transbaikal Standard Time', name: '(UTC+09:00) Chita' }, 
    { id: 'Tokyo Standard Time', name: '(UTC+09:00) Osaka, Sapporo, Tokyo' }, 
    { id: 'North Korea Standard Time', name: '(UTC+09:00) Pyongyang' }, 
    { id: 'Korea Standard Time', name: '(UTC+09:00) Seoul' }, 
    { id: 'Yakutsk Standard Time', name: '(UTC+09:00) Yakutsk' }, 
    { id: 'Cen. Australia Standard Time', name: '(UTC+09:30) Adelaide' }, 
    { id: 'AUS Central Standard Time', name: '(UTC+09:30) Darwin' }, 
    { id: 'E. Australia Standard Time', name: '(UTC+10:00) Brisbane' }, 
    { id: 'AUS Eastern Standard Time', name: '(UTC+10:00) Canberra, Melbourne, Sydney' }, 
    { id: 'West Pacific Standard Time', name: '(UTC+10:00) Guam, Port Moresby' }, 
    { id: 'Tasmania Standard Time', name: '(UTC+10:00) Hobart' }, 
    { id: 'Vladivostok Standard Time', name: '(UTC+10:00) Vladivostok' }, 
    { id: 'Lord Howe Standard Time', name: '(UTC+10:30) Lord Howe Island' }, 
    { id: 'Bougainville Standard Time', name: '(UTC+11:00) Bougainville Island' }, 
    { id: 'Russia Time Zone 10', name: '(UTC+11:00) Chokurdakh' }, 
    { id: 'Magadan Standard Time', name: '(UTC+11:00) Magadan' }, 
    { id: 'Norfolk Standard Time', name: '(UTC+11:00) Norfolk Island' }, 
    { id: 'Sakhalin Standard Time', name: '(UTC+11:00) Sakhalin' }, 
    { id: 'Central Pacific Standard Time', name: '(UTC+11:00) Solomon Is., New Caledonia' }, 
    { id: 'Russia Time Zone 11', name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky' }, 
    { id: 'New Zealand Standard Time', name: '(UTC+12:00) Auckland, Wellington' }, 
    { id: 'UTC+12', name: '(UTC+12:00) Coordinated Universal Time+12' }, 
    { id: 'Fiji Standard Time', name: '(UTC+12:00) Fiji' }, 
    { id: 'Kamchatka Standard Time', name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old' }, 
    { id: 'Chatham Islands Standard Time', name: '(UTC+12:45) Chatham Islands' }, 
    { id: 'UTC+13', name: '(UTC+13:00) Coordinated Universal Time+13' }, 
    { id: 'Tonga Standard Time', name: '(UTC+13:00) Nuku\'alofa' }, 
    { id: 'Samoa Standard Time', name: '(UTC+13:00) Samoa' }, 
    { id: 'Line Islands Standard Time', name: '(UTC+14:00) Kiritimati Island' }
]

// contact types, will need changed to be api-driven when we allow new contact types
export const CONTACT_TYPE_OWNER = '3623a00e-8278-4f65-bb75-185583363b11';
export const CONTACT_TYPE_PRIMARY_SUPPORT = '3623a00e-8278-4f65-bb75-185583363b12';
export const CONTACT_TYPE_SECONDARY_SUPPORT = '3623a00e-8278-4f65-bb75-185583363b13';
export const CONTACT_TYPE_EXPERT = '3623a00e-8278-4f65-bb75-185583363b14';

export const CONTACT_TYPE_ADMIN = '3623a00e-8278-4f65-bb75-185583363b15';
export const CONTACT_TYPE_SUPPORT = '3623a00e-8278-4f65-bb75-185583363b16';

// framework types
export const FRAMEWORK_TSM = 'TSM';
export const FRAMEWORK_APQC = 'APQC';

// area types
export const AREA_BUSINESS = 'Business';
export const AREA_BUSINESS_ID = '0701a7d4-f86f-4380-a737-811ef9338001';
export const AREA_SUPPORT = 'Support';
export const AREA_SUPPORT_ID = '0701a7d4-f86f-4380-a737-811ef9338002';
export const AREA_FUNCTIONAL = 'Functional';
export const AREA_FUNCTIONAL_ID = '0701a7d4-f86f-4380-a737-811ef9338003';

// integration directions
export const INTEGRATION_PUBLISH = 'Publish';
export const INTEGRATION_SUBSCRIBE = 'Subscribe';

// application state TIME types
export const TIMETYPE_TOLERATE = "Tolerate";
export const TIMETYPE_INVEST = "Invest";
export const TIMETYPE_MIGRATE = "Migrate";
export const TIMETYPE_ELIMINATE = "Eliminate";

// project note status
export const PROJECT_NOTE_STATUS_OPEN = "cf5e45ab-116a-4fe9-9fcb-97f379627691";

// total employees
export const TOTAL_EMPLOYEES_100_249 = "cba939a9-6022-41f8-a37a-86061d42c4f3";

// debit/credit
export const DEBIT = 'Debit';
export const CREDIT = 'Credit';

// tax type
export const TAX_TYPE_SAAS = 'saas';
export const TAX_TYPE_ESERVICE = 'eservice';

// payment
export const PAYMENT_CREDIT_CARD = 'Credit Card';
export const PAYMENT_CHEQUE = 'Cheque';
export const PAYMENT_EFT = 'EFT';
export const PAYMENT_INTERAC = 'Interac e-Transfer';

// plans
export const PLAN_ACADEMY = 'ACADEMY';
export const PLAN_ESSENTIALS = 'ESSENTIALS';
export const PLAN_PLUS = 'PLUS';
export const PLAN_ADVANCED = 'ADVANCED';
export const PLAN_FREE = 'FREE';

// plan names
export const PLAN_ACADEMY_NAME = 'Academy';
export const PLAN_ESSENTIALS_NAME = 'Essentials';
export const PLAN_PLUS_NAME = 'Master';
export const PLAN_ADVANCED_NAME = 'Advanced';
export const PLAN_FREE_NAME = 'Free';


// platforms
export const PLATFORM_ACADEMY = 'ACADEMY-PLATFORM';
export const PLATFORM_ESSENTIALS = 'ESSENTIALS-PLATFORM';
export const PLATFORM_PLUS = 'PLUS-PLATFORM';
export const PLATFORM_ADVANCED = 'ADVANCED-PLATFORM';
export const PLATFORM_FREE = 'FREE-PLATFORM';

// rate units
export const UNIT_MONTHLY = 'Month';
export const UNIT_YEAR = 'Year';
export const UNIT_ONETIME = 'One-time';

// product types
export const PRODUCT_TYPE_SUBSCRIPTION = 'Subscription';
export const PRODUCT_TYPE_PLATFORM = 'Platform';
export const PRODUCT_TYPE_ADDON = 'Add-on';

// tax product type
export const TAX_PRODUCT_TYPE_SAAS = 'saas';
export const TAX_PRODUCT_TYPE_ESERVICE = 'eservice';

// idea filters
export const IDEA_FILTER_ACTIVE = 'active';
export const IDEA_FILTER_ARCHIVED = 'archived';
export const IDEA_FILTER_PROMOTED = 'promoted';

// idea votes
export const IDEA_VOTE_YES = 'Yes';
export const IDEA_VOTE_NO = 'No';

// it service uses / used by
export const USES = 'USES';
export const USED_BY = 'USED_BY';

// import contact name formats
export const IMPORT_CONTACT_NAME_FORMAT_FIRST_LAST = 'First Last';
export const IMPORT_CONTACT_NAME_FORMAT_LAST_FIRST = 'Last, First';


// backup types
export const BACKUP_TYPE_AUTOMATED_ID = "4d1108f0-b294-4349-b67f-6ef234e2a7b1";
export const BACKUP_TYPE_SNAPSHOT_ID = "4d1108f0-b294-4349-b67f-6ef234e2a7b2";
export const BACKUP_TYPE_MANUAL_ID = "4d1108f0-b294-4349-b67f-6ef234e2a7b3";

// backup statuses
export const BACKUP_STATUS_REQUESTED_ID = "50c04685-6fc3-4037-819b-810482a130f1";
export const BACKUP_STATUS_STARTED_ID = "50c04685-6fc3-4037-819b-810482a130f2";
export const BACKUP_STATUS_COMPLETED_ID = "50c04685-6fc3-4037-819b-810482a130f3";
export const BACKUP_STATUS_FAILED_ID = "50c04685-6fc3-4037-819b-810482a130f4";


// roles
export const SYSTEM_ADMINISTRATOR = 'Enterprise Admin';
export const CERTIFIED_PROVIDER_ADMINISTRATOR = 'Certified Provider Admin';
export const GLOBAL_ADMINISTRATOR = 'Global Admin';
export const COMPANY_ADMINISTRATOR = 'Company Admin';
export const APPLICATION_MANAGER = 'Application Manager';
export const PORTFOLIO_ADMINISTRATOR = 'Portfolio Manager';

export const AUTHOR_USER = 'Author';
export const VIEW_USER = 'View User';

export const INHERITED_ROLE = "<< inherited >>";

// rights
export const RIGHT_EDIT_USER = 'EDIT_USER';
export const RIGHT_INVITE_USER = 'INVITE_USER';
export const RIGHT_REMOVE_USER = 'REMOVE_USER';
export const RIGHT_ASSIGN_ROLE_PREFIX = 'ASSIGN_ROLE_';
export const RIGHT_ASSIGN_CERTIFIED_PROVIDER_ADMINISTRATOR = `${RIGHT_ASSIGN_ROLE_PREFIX}${CERTIFIED_PROVIDER_ADMINISTRATOR}`;
export const RIGHT_ASSIGN_GLOBAL_ADMINISTRATOR = `${RIGHT_ASSIGN_ROLE_PREFIX}${GLOBAL_ADMINISTRATOR}`;
export const RIGHT_ASSIGN_COMPANY_ADMINISTRATOR = `${RIGHT_ASSIGN_ROLE_PREFIX}${COMPANY_ADMINISTRATOR}`;
export const RIGHT_ASSIGN_APPLICATION_MANAGER = `${RIGHT_ASSIGN_ROLE_PREFIX}${APPLICATION_MANAGER}`;
export const RIGHT_ASSIGN_PORTFOLIO_ADMINISTRATOR =  `${RIGHT_ASSIGN_ROLE_PREFIX}${PORTFOLIO_ADMINISTRATOR}`;
export const RIGHT_ASSIGN_AUTHOR_USER = `${RIGHT_ASSIGN_ROLE_PREFIX}${AUTHOR_USER}`;
export const RIGHT_ASSIGN_VIEW_USER = `${RIGHT_ASSIGN_ROLE_PREFIX}${VIEW_USER}`;

export const RIGHT_CREATE_PARENT_ACCOUNTS = 'CREATE_PARENT_ACCOUNTS';
export const RIGHT_CREATE_CHILD_ACCOUNTS = 'CREATE_CHILD_ACCOUNTS';
export const RIGHT_RESET_ACCOUNT_DATA = 'RESET_ACCOUNT_DATA';

export const RIGHT_FULL_PORTFOLIO_ACCESS = 'FULL_PORTFOLIO';
export const RIGHT_ADD_REMOVE_PORTFOLIO_APP = 'ADD_REMOVE_PORTFOLIO_APP';
export const RIGHT_EDIT_PORTFOLIO_APPLICATIONS = 'EDIT_PORTFOLIO_APPLICATIONS';
export const RIGHT_EDIT_APPLICATIONS = 'EDIT_APPLICATIONS';
export const RIGHT_DELETE_APPLICATIONS = 'DELETE_APPLICATIONS';
export const RIGHT_EDIT_IDEAS = 'EDIT_IDEAS';
export const RIGHT_DELETE_IDEAS = 'DELETE_IDEAS';
export const RIGHT_EDIT_IT_SERVICES = 'EDIT_IT_SERVICES';
export const RIGHT_DELETE_IT_SERVICES = 'DELETE_IT_SERVICES';
export const RIGHT_EDIT_CONTACTS = 'EDIT_CONTACTS';
export const RIGHT_DELETE_CONTACTS = 'DELETE_CONTACTS';
export const RIGHT_EDIT_PROJECTS = 'EDIT_PROJECTS';
export const RIGHT_DELETE_PROJECTS = 'DELETE_PROJECTS';
export const RIGHT_EDIT_PROGRAMS = 'EDIT_PROGRAMS';
export const RIGHT_DELETE_PROGRAMS = 'DELETE_PROGRAMS';
export const RIGHT_EDIT_BUSINESS_AREAS = 'EDIT_BUSINESS_AREAS';
export const RIGHT_DELETE_BUSINESS_AREAS = 'DELETE_BUSINESS_AREAS';

export const RIGHT_EDIT_BUSINESS_FACTORS = 'EDIT_BUSINESS_FACTORS';
export const RIGHT_EDIT_TECHNOLOGY_FACTORS = 'EDIT_TECHNOLOGY_FACTORS';
export const RIGHT_EDIT_TAGS = 'EDIT_TAGS';

export const RIGHT_IMPORT_DATA = 'IMPORT_DATA';
export const RIGHT_IMPORT_EXPORT_PORTFOLIO = 'IMPORT_EXPORT_PORTFOLIO';
export const RIGHT_IMPORT_EXPORT_APPLICATIONS = 'IMPORT_EXPORT_APPLICATIONS';
export const RIGHT_IMPORT_EXPORT_IT_SERVICES = 'IMPORT_EXPORT_IT_SERVICES';
export const RIGHT_IMPORT_EXPORT_CONTACTS = 'IMPORT_EXPORT_CONTACTS';
export const RIGHT_IMPORT_EXPORT_PROJECTS = 'IMPORT_EXPORT_PROJECTS';
export const RIGHT_IMPORT_EXPORT_PROGRAMS = 'IMPORT_EXPORT_PROGRAMS';
export const RIGHT_IMPORT_EXPORT_BUSINESS_AREAS = 'IMPORT_EXPORT_BUSINESS_AREAS';
export const RIGHT_IMPORT_EXPORT_TAGS = 'IMPORT_EXPORT_TAGS';
export const RIGHT_IMPORT_EXPORT_SUPPLIERS = 'IMPORT_EXPORT_SUPPLIERS';
export const RIGHT_IMPORT_EXPORT_IDEAS = 'IMPORT_EXPORT_IDEAS';

export const RIGHT_CREATE_PORTFOLIO = 'CREATE_PORTFOLIO';
export const RIGHT_EDIT_PORTFOLIO = 'EDIT_PORTFOLIO';
export const RIGHT_EDIT_PORTFOLIO_VALUES = 'EDIT_PORTFOLIO_VALUES';
export const RIGHT_DELETE_PORTFOLIO = 'DELETE_PORTFOLIO';
export const RIGHT_EDIT_PORTFOLIO_PERMISSIONS = 'EDIT_PORTFOLIO_PERMISSIONS';

export const RIGHT_EDIT_ACCOUNT = 'EDIT_ACCOUNT';
export const RIGHT_EDIT_APPLICATION_STATES = 'EDIT_APPLICATION_STATES';
export const RIGHT_EDIT_APPLICATION_TYPES = 'EDIT_APPLICATION_TYPES';
export const RIGHT_EDIT_TECHNOLOGY_TYPES = 'EDIT_TECHNOLOGY_TYPES';
export const RIGHT_EDIT_INTEGRATION_TYPES = 'EDIT_INTEGRATION_TYPES';
export const RIGHT_DELETE_APPLICATION_STATES = 'DELETE_APPLICATION_STATES';
export const RIGHT_DELETE_APPLICATION_TYPES = 'DELETE_APPLICATION_TYPES';
export const RIGHT_DELETE_TECHNOLOGY_TYPES = 'DELETE_TECHNOLOGY_TYPES';
export const RIGHT_DELETE_INTEGRATION_TYPES = 'DELETE_INTEGRATION_TYPES';

export const RIGHT_EDIT_IDEA_STATUSES = 'EDIT_IDEA_STATUSES';
export const RIGHT_EDIT_IDEA_TYPES = 'EDIT_IDEA_TYPES';
export const RIGHT_DELETE_IDEA_STATUSES = 'DELETE_IDEA_STATUSES';
export const RIGHT_DELETE_IDEA_TYPES = 'DELETE_IDEA_TYPES';

export const RIGHT_EDIT_PROJECT_SIZES = 'EDIT_PROJECT_SIZES';
export const RIGHT_DELETE_PROJECT_SIZES = 'DELETE_PROJECT_SIZES';
export const RIGHT_EDIT_PROJECT_TYPES = 'EDIT_PROJECT_TYPES';
export const RIGHT_DELETE_PROJECT_TYPES = 'DELETE_PROJECT_TYPES';
export const RIGHT_EDIT_PROJECT_STAGES = 'EDIT_PROJECT_STAGES';
export const RIGHT_DELETE_PROJECT_STAGES = 'DELETE_PROJECT_STAGES';
export const RIGHT_EDIT_PROJECT_STATUSES = 'EDIT_PROJECT_STATUSES';
export const RIGHT_DELETE_PROJECT_STATUSES = 'DELETE_PROJECT_STATUSES';

export const RIGHT_EDIT_DRIVERS = 'EDIT_DRIVERS';
export const RIGHT_DELETE_DRIVERS = 'DELETE_DRIVERS';

export const RIGHT_EDIT_CLASSIFICATIONS = 'EDIT_CLASSIFICATIONS';
export const RIGHT_EDIT_DATA_CLASSIFICATIONS = 'EDIT_DATA_CLASSIFICATIONS';
export const RIGHT_EDIT_COMPUTING_TYPES = 'EDIT_COMPUTING_TYPES';
export const RIGHT_EDIT_PLATFORMS = 'EDIT_PLATFORMS';
export const RIGHT_DELETE_CLASSIFICATIONS = 'DELETE_CLASSIFICATIONS';
export const RIGHT_DELETE_DATA_CLASSIFICATIONS = 'DELETE_DATA_CLASSIFICATIONS';
export const RIGHT_DELETE_COMPUTING_TYPES = 'DELETE_COMPUTING_TYPES';
export const RIGHT_DELETE_PLATFORMS = 'DELETE_PLATFORMS';

export const RIGHT_EDIT_CAPABILITY_SETS = 'EDIT_CAPABILITY_SETS';
export const RIGHT_DELETE_CAPABILITY_SETS = 'DELETE_CAPABILITY_SETS';

export const RIGHT_EDIT_SUPPLIERS = 'EDIT_SUPPLIERS';
export const RIGHT_DELETE_SUPPLIERS = 'DELETE_SUPPLIERS';

export const RIGHT_EDIT_LINKS = 'EDIT_LINKS';
export const RIGHT_DELETE_LINKS = 'DELETE_LINKS';

export const RIGHT_EDIT_CAPABILITIES = 'EDIT_CAPABILITIES';
export const RIGHT_DELETE_CAPABILITIES = 'DELETE_CAPABILITIES';
