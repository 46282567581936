import _ from 'lodash';
import { refIntegrationTypeService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankRefIntegrationType() {
    return {
        refIntegrationTypeId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        shortName: "",
        name: "",
        notes: "",
        displayOrder: null,
        applicationCount: 0
    };
}

export const refintegrationtypes = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentRefIntegrationType: getBlankRefIntegrationType()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                refIntegrationTypeService.getByAccount(accountId)
                    .then(
                        refIntegrationTypes => {
                            commit(GET_ALL_SUCCESS, refIntegrationTypes);
                            resolve(refIntegrationTypes);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, refIntegrationTypeId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return refIntegrationTypeService.getById(refIntegrationTypeId)
                    .then(
                        refIntegrationType => {
                            commit(GET_BY_ID_SUCCESS, refIntegrationType);
                            resolve(refIntegrationType);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, refIntegrationType) {
            return refIntegrationTypeService.add(refIntegrationType);
        },
        [UPDATE]({ dispatch, commit }, refIntegrationType) {
            return refIntegrationTypeService.update(refIntegrationType);
        },
        [DELETE]({ dispatch, commit }, refIntegrationType) {
            return refIntegrationTypeService.deleteRefIntegrationType(refIntegrationType);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, refIntegrationTypes) {
            state.status = { loaded: true };
            state.items = refIntegrationTypes;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, refIntegrationType) {
            state.status = { loaded: true };
            state.currentRefIntegrationType = refIntegrationType;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRefIntegrationType = getBlankRefIntegrationType();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentRefIntegrationType = getBlankRefIntegrationType();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRefIntegrationType, {
                [field]: value
            });
        },
    }
}
