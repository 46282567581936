import _ from 'lodash';
import { programService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_BY_ID,
    ADD,
    UPDATE,
    UPDATE_PERCENT_COMPLETE,
    DELETE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_LIST_ITEM,
    UPDATE_CURRENT_ITEM,
    ADD_PROGRAM_PROJECT,
    REMOVE_PROGRAM_PROJECT,
    ADD_PROGRAM_IDEA,
    REMOVE_PROGRAM_IDEA,
    ADD_PROGRAM_DRIVER,
    REMOVE_PROGRAM_DRIVER,
    SET_EXPANDED_ITEMS
} from "./mutations.type";

function getBlankProgramDriver() {
    return {
        programDriverId: GUID_EMPTY,
        programId: GUID_EMPTY,
        refDriverId: null,
        refDriverName: ""
    }
}

function getBlankProgramIdea() {
    return {
        programIdeaId: GUID_EMPTY,
        programId: GUID_EMPTY,
        ideaId: null,
        name: ""
    }
}

function getBlankProgramProject() {
    return {
        programProjectId: GUID_EMPTY,
        programId: GUID_EMPTY,
        projectId: null,
        name: ""
    }
}

function getBlankProgram() {
    return {
        programId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        name: "",
        shortDescription: "",
        notes: "",
        accountProgramNumber: 0,
        programCoordinatorContactId: null,

        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,

        programCoordinatorContact: null,
        programDrivers: [],
        programIdeas: [],
        programProjects: [],
        elements: [],

        portfoliosDescription: null,
        programDriversDescription: null,
        initiationDate: null,
        targetDate: null,
        percentComplete: null,
    };
}

export const programs = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        expandedItems: [],
        currentProgram: getBlankProgram()
    },
    getters: {
        
    },
    actions: {
        [GET_ALL]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    programService.getAll()
                        .then(
                            programs => {
                                commit(GET_ALL_SUCCESS, programs);
                                resolve(programs);
                            },
                            error =>  {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_ID]({ commit }, programId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return programService.getById(programId)
                    .then(
                        program => {
                            commit(GET_BY_ID_SUCCESS, program);
                            resolve(program);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, program) {
            return programService.add(program);
        },
        [UPDATE]({ dispatch, commit }, program) {
            return programService.update(program);
        },
        [UPDATE_PERCENT_COMPLETE]({ dispatch, commit }, program) {
            return programService.updatePercentComplete(program);
        },
        [DELETE]({ dispatch, commit }, program) {
            return programService.deleteProgram(program);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, programs) {
            state.status = { loaded: true };
            state.items = programs;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, program) {
            state.status = { loaded: true };
            state.currentProgram = program;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentProgram = getBlankProgram();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentProgram = getBlankProgram();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentProgram, {
                [field]: value
            });
        },
        [UPDATE_LIST_ITEM](state, { programId, field, value }) {
            let entity = state.items.find(i => i.programId === programId);
            if (entity) {
                // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
                Object.assign(entity, {
                    [field]: value
                });
            }
        },

        [ADD_PROGRAM_PROJECT](state, project) {
            var newRecord = getBlankProgramProject();
            newRecord.projectId = project.projectId;
            newRecord.name = project.name;
            // newRecord.name = name;
            state.currentProgram.programProjects.push(newRecord);
        },
        [REMOVE_PROGRAM_PROJECT](state, projectId) {
            let arr = state.currentProgram.programProjects;
            arr.splice(arr.findIndex(item => item.projectId === projectId), 1)
            //_.remove(state.currentProgram.programProjects, { 'projectId': projectId });
        },

        [ADD_PROGRAM_IDEA](state, idea) {
            var newRecord = getBlankProgramIdea();
            newRecord.ideaId = idea.ideaId;
             newRecord.name = idea.name;
            state.currentProgram.programIdeas.push(newRecord);
        },
        [REMOVE_PROGRAM_IDEA](state, ideaId) {
            let arr = state.currentProgram.programIdeas;
            arr.splice(arr.findIndex(item => item.ideaId === ideaId), 1)
            // _.remove(state.currentProgram.programIdeas, { 'ideaId': ideaId });
        },

        [ADD_PROGRAM_DRIVER](state, refDriverId) {
            var newRecord = getBlankProgramDriver();
            newRecord.refDriverId = refDriverId;
            // newRecord.refDriverName = name;
            state.currentProgram.programDrivers.push(newRecord);
        },
        [REMOVE_PROGRAM_DRIVER](state, refDriverId) {
            _.remove(state.currentProgram.programDrivers, { 'refDriverId': refDriverId });
        },

        [SET_EXPANDED_ITEMS](state, expandedItems) {
            state.expandedItems = expandedItems;
        }
    }
}
