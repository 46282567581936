// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const supplierService = {
    getByAccount,
    getById,
    add,
    update,
    deleteSupplier
};

function getByAccount(accountId) {
    // return axios.get(`/suppliers/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/suppliers/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/suppliers/${id}`).then(handleResponse);
}

function add(supplier) {
    return axios.post('/suppliers', supplier).then(handleResponse);
}

function update(supplier) {
    return axios.put(`/suppliers/${supplier.supplierId}`, supplier).then(handleResponse);
}

function deleteSupplier(supplier) {
    return axios.delete(`/suppliers/${supplier.supplierId}`).then(handleResponse);
}
