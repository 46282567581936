import _ from 'lodash';
import { contactService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_BY_ID,
    ADD,
    UPDATE,
    UPDATE_CONTACT_PHONE,
    UPDATE_EMAIL_ADDRESS,
    DELETE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    UPDATE_LIST_ITEM,
    ADD_AREA,
    REMOVE_AREA,
    ADD_APPLICATION_CONTACT,
    REMOVE_APPLICATION_CONTACT,
    ADD_IT_SERVICE_CONTACT,
    REMOVE_IT_SERVICE_CONTACT,
    ADD_SUPPLIER_CONTACT,
    REMOVE_SUPPLIER_CONTACT,
  } from "./mutations.type";

function getBlankApplicationContact() {
    return {
        applicationContactId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        contactId: null,
        refContactTypeId: null,
        firstName: "",
        lastName: "",
        name: "",
        refContactTypeName: ""
    }
}

function getBlankITServiceContact() {
    return {
        itServiceContactId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        contactId: null,
        refContactTypeId: null,
        firstName: "",
        lastName: "",
        name: "",
        refContactTypeName: ""
    }
}


function getBlankSupplierContact() {
    return {
        supplierContactId: GUID_EMPTY,
        supplierId: GUID_EMPTY,
        contactId: null,
        refContactTypeId: null,
        firstName: "",
        lastName: "",
        name: "",
        refContactTypeName: ""
    }
}

function getBlankContactArea() {
    return {
        contactAreaId: GUID_EMPTY,
        areaId: GUID_EMPTY,
        contactId: GUID_EMPTY,
        name: ""
    }
}

function getBlankContact() {
    return {
        contactId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        userId: null,
        areaId: null,
        firstName: "",
        lastName: "",
        name: "",
        firstNameLastName: "",
        position: "",
        company: "",
        emailAddress: "",
        contactPhone: "",
        imageFilename: "",
        thumbnailFilename: "",
        notes: "",
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        applicationContacts: [],
        itServiceContacts: [],
        supplierContacts: [],
        contactAreas: [],
        areasDescription: "",
        user: null
    };
}

export const contacts = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentContact: getBlankContact()
    },
    getters: {
        
    },
    actions: {
        [GET_ALL]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    contactService.getAll()
                        .then(
                            contacts => {
                                commit(GET_ALL_SUCCESS, contacts);
                                resolve(contacts);
                            },
                            error =>  {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_ID]({ commit }, contactId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return contactService.getById(contactId)
                    .then(
                        contact => {
                            commit(GET_BY_ID_SUCCESS, contact);
                            resolve(contact);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, { contact, file }) {
            return contactService.add(contact, file);
        },
        [UPDATE]({ dispatch, commit }, { contact, file }) {
            return contactService.update(contact, file);
        },
        [UPDATE_CONTACT_PHONE]({ dispatch, commit }, contact) {
            return contactService.updateContactPhone(contact);
        },
        [UPDATE_EMAIL_ADDRESS]({ dispatch, commit }, contact) {
            return contactService.updateEmailAddress(contact);
        },
        [DELETE]({ dispatch, commit }, contact) {
            return contactService.deleteContact(contact);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, contacts) {
            state.status = { loaded: true };
            state.items = contacts;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, contact) {
            state.status = { loaded: true };
            state.currentContact = contact;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentContact = getBlankContact();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentContact = getBlankContact();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentContact, {
                [field]: value
            });
        },
        [UPDATE_LIST_ITEM](state, { contactId, field, value }) {
            let entity = state.items.find(i => i.contactId === contactId);
            if (entity) {
                Object.assign(entity, {
                    [field]: value
                });
            }
        },

        [ADD_AREA](state, { areaId, name }) {
            var newRecord = getBlankContactArea();
            newRecord.areaId = areaId;
            newRecord.name = name;
            state.currentContact.contactAreas.push(newRecord);
        },
        [REMOVE_AREA](state, areaId) {
            let areaArray = state.currentContact.contactAreas;
            areaArray.splice(areaArray.findIndex(item => item.areaId === areaId), 1)
        },

        [ADD_APPLICATION_CONTACT](state, { applicationId, contactTypeId }) {
            var newAppContact = getBlankApplicationContact();
            newAppContact.applicationId = applicationId;
            newAppContact.refContactTypeId = contactTypeId;

            state.currentContact.applicationContacts.push(newAppContact);
        },
        [REMOVE_APPLICATION_CONTACT](state, { applicationId, contactTypeId }) {
            _.remove(state.currentContact.applicationContacts, { 'applicationId': applicationId, 'refContactTypeId': contactTypeId });
        },
        [ADD_IT_SERVICE_CONTACT](state, { itServiceId, contactTypeId }) {
            var newITServiceContact = getBlankITServiceContact();
            newITServiceContact.itServiceId = itServiceId;
            newITServiceContact.refContactTypeId = contactTypeId;

            state.currentContact.itServiceContacts.push(newITServiceContact);
        },
        [REMOVE_IT_SERVICE_CONTACT](state, { itServiceId, contactTypeId }) {
            _.remove(state.currentContact.itServiceContacts, { 'itServiceId': itServiceId, 'refContactTypeId': contactTypeId });
        },
        [ADD_SUPPLIER_CONTACT](state, { supplierId, contactTypeId }) {
            var newAppContact = getBlankSupplierContact();
            newAppContact.supplierId = supplierId;
            newAppContact.refContactTypeId = contactTypeId;

            state.currentContact.supplierContacts.push(newAppContact);
        },
        [REMOVE_SUPPLIER_CONTACT](state, { supplierId, contactTypeId }) {
            _.remove(state.currentContact.supplierContacts, { 'supplierId': supplierId, 'refContactTypeId': contactTypeId });
        },

    }
}
