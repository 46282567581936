// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const productService = {
    getAll,
    getActive,
    getById,
    add,
    update,
    deleteProduct,
    getRateById,
    addRate,
    updateRate,
    deleteRate
};


function getAll() {
    return axios.get(`/products/all`).then(handleResponse);
}

function getActive() {
    return axios.get(`/products/active`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/products/${id}`).then(handleResponse);
}

function add(product) {
    return axios.post('/products', product).then(handleResponse);
}

function update(product) {
    return axios.put(`/products/${product.productId}`, product).then(handleResponse);
}

function deleteProduct(product) {
    return axios.delete(`/products/${product.productId}`).then(handleResponse);
}

function getRateById(productId, id) {
    return axios.get(`/products/${productId}/rates/${id}`).then(handleResponse);
}

function addRate(rate) {
    return axios.post(`/products/${rate.productId}/rates`, rate).then(handleResponse);
}

function updateRate(rate) {
    return axios.put(`/products/${rate.productId}/rates/${rate.productRateId}`, rate).then(handleResponse);
}

function deleteRate(rate) {
    return axios.delete(`/products/${rate.productId}/rates/${rate.productRateId}`).then(handleResponse);
}
