// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const backupService = {
    getAll,
    getById,
    startBackup,
    downloadBackup,
    getRestores,
    restoreBackup
};

function getAll() {
    return axios.get(`/backups/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/backups/${id}`).then(handleResponse);
}

function startBackup() {
    return axios.post('/backups/start').then(handleResponse);
}

function downloadBackup(id) {
    return axios({
        url: `/backups/${id}/download`,
        method: 'GET',
        responseType: 'blob'
    }).then(handleResponse);
}

function getRestores() {
    return axios.get(`/backups/restores`).then(handleResponse);
}

function restoreBackup(id) {
    return axios.post(`/backups/${id}/restore`).then(handleResponse);
}
