import { userService } from './user.service';

export function handleResponse(response) {
    //console.log(response);
    // debugger;
    const data = response.data;
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        location.reload(true);
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    } else {
        return Promise.resolve(data);
    }
}