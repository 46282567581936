// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const linkService = {
    getByAccount,
    getById,
    add,
    update,
    deleteLink
};

function getByAccount(accountId) {
    // return axios.get(`/links/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/links/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/links/${id}`).then(handleResponse);
}

function add(link) {
    return axios.post('/links', link).then(handleResponse);
}

function update(link) {
    return axios.put(`/links/${link.linkId}`, link).then(handleResponse);
}

function deleteLink(link) {
    return axios.delete(`/links/${link.linkId}`).then(handleResponse);
}
