// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const applicationService = {
    getAll,
    getByPortfolio,
    getById,
    getNew,
    add,
    update,
    deleteApplication,
    calculateTechScores
};

function getAll() {
    // defaults to current account for now
    // return axios.get(`/applications/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/applications/`).then(handleResponse);
}

function getByPortfolio(portfolioId) {
    return axios.get(`/applications/?portfolioId=${portfolioId}`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/applications/${id}`).then(handleResponse);
}

function getNew(portfolioId) {
    if (portfolioId != null) {
        return axios.get(`/applications/new/?portfolioId=${portfolioId}`).then(handleResponse);
    } else {
        return axios.get(`/applications/new`).then(handleResponse);
    }
}

function add(application) {
    return axios.post('/applications', application).then(handleResponse);
}

function update(application, portfolioId) {
    if (portfolioId != null) {
        return axios.put(`/applications/${application.applicationId}?portfolioId=${portfolioId}`, application).then(handleResponse);
    } else {
        return axios.put(`/applications/${application.applicationId}`, application).then(handleResponse);
    }
}

function deleteApplication(application) {
    return axios.delete(`/applications/${application.applicationId}`).then(handleResponse);
}

function calculateTechScores(application) {
    return axios.post('/applications/techscores', application).then(handleResponse);
}

