import _ from 'lodash';
import { refProjectSizeService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankRefProjectSize() {
    return {
        refProjectSizeId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        shortName: "",
        name: "",
        lowerBoundary: null,
        upperBoundary: null,
        notes: "",
        displayOrder: null,
        projectCount: 0
    };
}

export const refprojectsizes = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentRefProjectSize: getBlankRefProjectSize()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                refProjectSizeService.getByAccount(accountId)
                    .then(
                        refProjectSizes => {
                            commit(GET_ALL_SUCCESS, refProjectSizes);
                            resolve(refProjectSizes);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, refProjectSizeId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return refProjectSizeService.getById(refProjectSizeId)
                    .then(
                        refProjectSize => {
                            commit(GET_BY_ID_SUCCESS, refProjectSize);
                            resolve(refProjectSize);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, refProjectSize) {
            return refProjectSizeService.add(refProjectSize);
        },
        [UPDATE]({ dispatch, commit }, refProjectSize) {
            return refProjectSizeService.update(refProjectSize);
        },
        [DELETE]({ dispatch, commit }, refProjectSize) {
            return refProjectSizeService.deleteRefProjectSize(refProjectSize);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, refProjectSizes) {
            state.status = { loaded: true };
            state.items = refProjectSizes;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, refProjectSize) {
            state.status = { loaded: true };
            state.currentRefProjectSize = refProjectSize;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRefProjectSize = getBlankRefProjectSize();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentRefProjectSize = getBlankRefProjectSize();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRefProjectSize, {
                [field]: value
            });
        },
    }
}
