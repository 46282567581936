// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refApplicationStateService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefApplicationState
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refApplicationStates/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refApplicationStates/${id}`).then(handleResponse);
}

function add(refApplicationState) {
    return axios.post('/refApplicationStates', refApplicationState).then(handleResponse);
}

function update(refApplicationState) {
    return axios.put(`/refApplicationStates/${refApplicationState.refApplicationStateId}`, refApplicationState).then(handleResponse);
}

function deleteRefApplicationState(refApplicationState) {
    return axios.delete(`/refApplicationStates/${refApplicationState.refApplicationStateId}`).then(handleResponse);
}
