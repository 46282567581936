import { mapState, mapGetters } from "vuex";

const intercom = {
    name: 'Intercom',
    computed: {
      ...mapState("authentication", {
        currentUser: state => state.user
      }),
      ...mapState("account", {
        currentAccount: state => state.currentAccount,
      }),
      isIntercomLoaded() {
        return this.$intercom.ready;
      },
      isIntercomInitialized() {
        return this.$intercom.isBooted;
      },
      isIntercomVisible() {
        return this.$intercom.visible;
      },
    },
    async mounted() {
        if (!this.isIntercomInitialized) {
            if (this.currentUser == null || !this.currentUser.userId) {
                this.$intercom.boot({
                  hide_default_launcher: true
              });
            } else {
                this.$intercom.boot({
                    user_id: this.currentUser.userId,
                    name: this.currentUser.name,
                    email: this.currentUser.emailAddress,
                    phone: this.currentUser.contactPhone,
                    Company: this.currentAccount.name,
                    user_hash: this.currentUser.intercomHash,
                    hide_default_launcher: true
                });
            }
        }
    },
    methods: {
      generateUserInfos({ name, email, _id: userId }) {
        return {
          name,
          email,
          user_id: userId,
        };
      },
      bootIntercom(user) {
        this.$intercom.boot(this.generateUserInfos(user));
      },
      shutdownIntercom() {
        this.$intercom.shutdown();
      },
      showIntercom() {
        this.$intercom.show();
      },
      hideIntercom() {
        this.$intercom.hide();
      },
      setIntercomUser() {
        if (this.currentUser != null && !!this.currentUser.userId) {
            this.updateIntercom({
                user_id: this.currentUser.userId,
                name: this.currentUser.name,
                email: this.currentUser.emailAddress,
                phone: this.currentUser.contactPhone,
                Company: this.currentAccount.name,
                user_hash: this.currentUser.intercomHash,
                hide_default_launcher: true
            });
        }
      },
      updateIntercom(...args) {
        this.$intercom.update(...args);
      },
      trackEventIntercom(...args) {
        this.$intercom.trackEvent(...args);
      },
    },
  };
  
  export default intercom;