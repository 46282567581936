// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const technologyQuestionService = {
    getByAccount,
    getById,
    update,
    changeQuestionLevel
};

function getByAccount(accountId) {
    // return axios.get(`/technologyquestions/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/technologyquestions/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/technologyquestions/${id}`).then(handleResponse);
}

function update(techQuestion) {
    return axios.put(`/technologyquestions/${techQuestion.questionId}`, techQuestion).then(handleResponse);
}

function changeQuestionLevel(refQuestionLevelId) {
    return axios.put(`/technologyquestions/level`, { refQuestionLevelId} ).then(handleResponse);
}
