// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refProjectStatusService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefProjectStatus
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refProjectStatuses/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refProjectStatuses/${id}`).then(handleResponse);
}

function add(refProjectStatus) {
    return axios.post('/refProjectStatuses', refProjectStatus).then(handleResponse);
}

function update(refProjectStatus) {
    return axios.put(`/refProjectStatuses/${refProjectStatus.refProjectStatusId}`, refProjectStatus).then(handleResponse);
}

function deleteRefProjectStatus(refProjectStatus) {
    return axios.delete(`/refProjectStatuses/${refProjectStatus.refProjectStatusId}`).then(handleResponse);
}
