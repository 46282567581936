<template>
  <div class="sidebar"
       :data-color="backgroundColor"
       :data-active-color="activeColor">
    <div class="logo">
      <!-- <a class="simple-text logo-mini"
         aria-label="sidebar mini logo"
         href="/">
          <div class="logo-img">
              <img src="images/icon.png" alt="">
          </div>
      </a>  -->
      <a class="simple-text logo-normal visible-on-sidebar-regular" href="#" @click="minimizeSidebar" style="margin-right:40px;">
          <div class="logo-img">
              <img :src="logo" alt="">
          </div>         
          <!-- {{ title }} -->
      </a>
      <a class="simple-text logo-normal visible-on-sidebar-mini" href="#" @click="minimizeSidebar" style="margin-right:40px;">
          <div class="logo-img">
              <img src="/images/logo_yellow.png" alt="">
          </div>         
          <!-- {{ title }} -->
      </a>
      <div class="navbar-minimize" style="margin-top: 20px;">
        <button id="miniIcon" class="btn btn-icon btn-round visible-on-sidebar-mini hidden-on-sidebar-mini-hover" @click="minimizeSidebar">
          <!-- <i class="nc-icon nc-right-arrow text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-left-arrow text-center visible-on-sidebar-regular"></i> -->
        </button>
        <button id="minimizeSidebar" class="btn btn-icon btn-round visible-on-sidebar-regular" @click="minimizeSidebar">
          <!-- <i class="nc-icon nc-right-arrow text-center visible-on-sidebar-mini"></i> -->
          <i class="nc-icon nc-left-arrow text-center"></i>
        </button>
      </div>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot>
      </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in visibleLinks"
                        :key="link.name + index"
                        :index="index"
                        :link="link">

            <sidebar-item v-for="(subLink, subIndex) in visibleChildLinks(link.children)"
                          :key="subLink.name + subIndex"
                          :index="(index * 100) + subIndex"
                          :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>

      </ul>
    </div>
    <div class="sidebar-footer text-center visible-on-sidebar-regular">{{ appVersion }} - {{ apiVersion }}</div>


  </div>
</template>
<script>
  import 'perfect-scrollbar/dist/css/perfect-scrollbar.css'
  import { mapState, mapGetters } from "vuex";
  import { overviewService } from "@/services";
  import { GUID_EMPTY } from "@/helpers";

  export default {
    props: {
      title: {
        type: String,
        default: 'Get In Sync',
        description: 'Sidebar title'
      },
      backgroundColor: {
        type: String,
        default: 'darkblue',
        validator: (value) => {
          let acceptedValues = ['white', 'brown', 'black', 'darkblue']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar background color (white|brown|black)'
      },
      activeColor: {
        type: String,
        default: 'teal',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger', 'white', 'teal']
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Sidebar active text color (primary|info|success|warning|danger|white|teal)'
      },
      logo: {
        type: String,
        default: '/images/logo.png',
        description: 'Sidebar Logo'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description: 'Sidebar links. Can be also provided as children components (sidebar-item)'
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      }
    },
    data() {
        return {
            appVersion: process.env.VUE_APP_VERSION,
            apiVersion: null
        };
    },
    computed: {
      ...mapGetters("authentication", [
          "isImpersonating",
          "isInRole",
          "hasRightOrPortfolioRight"
      ]),
      ...mapGetters("account", [
          "isClientAccount",
          "isProviderAccount"
      ]),
      ...mapState("account", {
          currentAccount: state => state.currentAccount
      }),
      visibleLinks: function() {
          return _.filter(this.sidebarLinks, link => {
              let visible = true;

              if (link.right) {
                  visible = this.hasRightOrPortfolioRight(link.right);
              }

              if (link.inWorkspace !== undefined) {
                if (link.inWorkspace && (this.currentAccount.accountId == GUID_EMPTY)) {
                  visible = false;
                }
                if (!link.inWorkspace && (this.currentAccount.accountId != GUID_EMPTY)) {
                  visible = false;
                }
              }

              if (link.inProvider !== undefined) {
                if (link.inProvider && this.isClientAccount) {
                  visible = false;
                }
                if (!link.inProvider && this.isProviderAccount) {
                  visible = false;
                }
              }

              return visible;
          });
      }
    },
    async created() {
        try {
            const response = await overviewService.getVersion();
            this.apiVersion = response.version;
        } catch (error) {
            this.apiVersion = "";
        }        
    },
    methods: {
      visibleChildLinks(children) {
          return _.filter(children, link => {
              if (!link.right) {
                  return true;
              } else {
                  return this.hasRightOrPortfolioRight(link.right);
              }
          });
      },
      async initScrollBarAsync () {
        let isWindows = navigator.platform.startsWith('Win');
        if(!isWindows) {
          return;
        }
        const PerfectScroll = await import('perfect-scrollbar')
        PerfectScroll.initialize(this.$refs.sidebarScrollArea, { suppressScrollX: true })
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
    },
    mounted () {
      this.initScrollBarAsync()
    },
    beforeDestroy () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    }
  }

</script>
<style>
  @media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu{
      display: none;
    }
  }
</style>
