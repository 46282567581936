// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refClassificationService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefClassification
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refClassifications/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refClassifications/${id}`).then(handleResponse);
}

function add(refClassification) {
    return axios.post('/refClassifications', refClassification).then(handleResponse);
}

function update(refClassification) {
    return axios.put(`/refClassifications/${refClassification.refClassificationId}`, refClassification).then(handleResponse);
}

function deleteRefClassification(refClassification) {
    return axios.delete(`/refClassifications/${refClassification.refClassificationId}`).then(handleResponse);
}
