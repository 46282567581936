// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const commandbarService = {
    getApplications,
    getPortfolioApplications,
    getContacts,
    getIdeas,
    getITServices,
    getPortfolios,
    getPrograms,
    getProjects
};

function getApplications() {
    return axios.get(`/commandbar/applications/`).then(handleResponse);
}

function getPortfolioApplications() {
    return axios.get(`/commandbar/portfolio_applications/`).then(handleResponse);
}

function getContacts() {
    return axios.get(`/commandbar/contacts/`).then(handleResponse);
}

function getIdeas() {
    return axios.get(`/commandbar/ideas/`).then(handleResponse);
}

function getITServices() {
    return axios.get(`/commandbar/itservices/`).then(handleResponse);
}

function getPortfolios() {
    return axios.get(`/commandbar/portfolios/`).then(handleResponse);
}

function getPrograms() {
    return axios.get(`/commandbar/programs/`).then(handleResponse);
}

function getProjects() {
    return axios.get(`/commandbar/projects/`).then(handleResponse);
}


