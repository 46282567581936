// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refDriverService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefDriver
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refDrivers/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refDrivers/${id}`).then(handleResponse);
}

function add(refDriver) {
    return axios.post('/refDrivers', refDriver).then(handleResponse);
}

function update(refDriver) {
    return axios.put(`/refDrivers/${refDriver.refDriverId}`, refDriver).then(handleResponse);
}

function deleteRefDriver(refDriver) {
    return axios.delete(`/refDrivers/${refDriver.refDriverId}`).then(handleResponse);
}
