// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refDataClassificationService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefDataClassification
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refDataClassifications/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refDataClassifications/${id}`).then(handleResponse);
}

function add(refDataClassification) {
    return axios.post('/refDataClassifications', refDataClassification).then(handleResponse);
}

function update(refDataClassification) {
    return axios.put(`/refDataClassifications/${refDataClassification.refDataClassificationId}`, refDataClassification).then(handleResponse);
}

function deleteRefDataClassification(refDataClassification) {
    return axios.delete(`/refDataClassifications/${refDataClassification.refDataClassificationId}`).then(handleResponse);
}
