// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refIntegrationTypeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefIntegrationType
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refIntegrationTypes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refIntegrationTypes/${id}`).then(handleResponse);
}

function add(refIntegrationType) {
    return axios.post('/refIntegrationTypes', refIntegrationType).then(handleResponse);
}

function update(refIntegrationType) {
    return axios.put(`/refIntegrationTypes/${refIntegrationType.refIntegrationTypeId}`, refIntegrationType).then(handleResponse);
}

function deleteRefIntegrationType(refIntegrationType) {
    return axios.delete(`/refIntegrationTypes/${refIntegrationType.refIntegrationTypeId}`).then(handleResponse);
}
