import Vue from 'vue'

// theme
import './kendo-theme/app.scss'

// native components
import { AutoComplete, DropDownList } from '@progress/kendo-vue-dropdowns';
import { Button } from '@progress/kendo-vue-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import { NumericTextBox } from '@progress/kendo-vue-inputs';
import { ProgressBar } from '@progress/kendo-vue-progressbars';

Vue.component('k-autocomplete', AutoComplete);
Vue.component('k-dropdownlist', DropDownList);
Vue.component('k-button', Button);
Vue.component('k-dialog', Dialog);
Vue.component('k-dialog-actions-bar', DialogActionsBar);
Vue.component('k-numeric-textbox', NumericTextBox);
Vue.component('k-progress-bar', ProgressBar);
