import _ from 'lodash';
import { linkService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";


function getBlankLink() {
    return {
        linkId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        accountLinkNumber: null,
        name: "",
        websiteAddress: "",
        notes: "",
        displayOrder: 0,
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
    };
}

export const links = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentLink: getBlankLink()
    },
    getters: {
        getAccountLinks: (state) => (id) => {
            return state.items.filter(item => item.accountId != null);
        },
        getGlobalLinks: (state) => (id) => {
            return state.items.filter(item => item.accountId == null);
        },
    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                linkService.getByAccount(accountId)
                    .then(
                        links => {
                            commit(GET_ALL_SUCCESS, links);
                            resolve(links);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, linkId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return linkService.getById(linkId)
                    .then(
                        link => {
                            commit(GET_BY_ID_SUCCESS, link);
                            resolve(link);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, link) {
            return linkService.add(link);
        },
        [UPDATE]({ dispatch, commit }, link) {
            return linkService.update(link);
        },
        [DELETE]({ dispatch, commit }, link) {
            return linkService.deleteLink(link);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, links) {
            state.status = { loaded: true };
            state.items = links;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, link) {
            state.status = { loaded: true };
            state.currentLink = link;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentLink = getBlankLink();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentLink = getBlankLink();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentLink, {
                [field]: value
            });
        },


    }
}
