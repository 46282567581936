import _ from 'lodash';
import { capabilitySetService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankCapabilitySet() {
    return {
        capabilitySetId: GUID_EMPTY,
        portfolioId: GUID_EMPTY,
        name: "",
        modifiedDate: null,
        modifiedByName: "",
        capabilitySetFrameworkItems: []
    };
}

export const capabilitysets = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentCapabilitySet: getBlankCapabilitySet()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    capabilitySetService.getByAccount()
                        .then(
                            capabilitySets => {
                                commit(GET_ALL_SUCCESS, capabilitySets);
                                resolve(capabilitySets);
                            },
                            error => {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_ID]({ commit }, capabilitySetId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return capabilitySetService.getById(capabilitySetId)
                    .then(
                        capabilitySet => {
                            commit(GET_BY_ID_SUCCESS, capabilitySet);
                            resolve(capabilitySet);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, capabilitySet) {
            return capabilitySetService.add(capabilitySet);
        },
        [UPDATE]({ dispatch, commit }, capabilitySet) {
            return capabilitySetService.update(capabilitySet);
        },
        [DELETE]({ dispatch, commit }, capabilitySet) {
            return capabilitySetService.deleteCapabilitySet(capabilitySet);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, capabilitySets) {
            state.status = { loaded: true };
            state.items = capabilitySets;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, capabilitySet) {
            state.status = { loaded: true };
            state.currentCapabilitySet = capabilitySet;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentCapabilitySet = getBlankCapabilitySet();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentCapabilitySet = getBlankCapabilitySet();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentCapabilitySet, {
                [field]: value
            });
        },
    }
}
