// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refProjectSizeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefProjectSize
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refProjectSizes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refProjectSizes/${id}`).then(handleResponse);
}

function add(refProjectSize) {
    return axios.post('/refProjectSizes', refProjectSize).then(handleResponse);
}

function update(refProjectSize) {
    return axios.put(`/refProjectSizes/${refProjectSize.refProjectSizeId}`, refProjectSize).then(handleResponse);
}

function deleteRefProjectSize(refProjectSize) {
    return axios.delete(`/refProjectSizes/${refProjectSize.refProjectSizeId}`).then(handleResponse);
}
