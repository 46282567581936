import _ from 'lodash';
import { refProjectStatusService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankRefProjectStatus() {
    return {
        refProjectStatusId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        shortName: "",
        name: "",
        notes: "",
        displayOrder: null,
        isActiveProjectFlag: false,
        excludeFromProgramCalculations: false,
        projectCount: 0
    };
}

export const refprojectstatuses = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentRefProjectStatus: getBlankRefProjectStatus()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                refProjectStatusService.getByAccount(accountId)
                    .then(
                        refProjectStatuses => {
                            commit(GET_ALL_SUCCESS, refProjectStatuses);
                            resolve(refProjectStatuses);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, refProjectStatusId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return refProjectStatusService.getById(refProjectStatusId)
                    .then(
                        refProjectStatus => {
                            commit(GET_BY_ID_SUCCESS, refProjectStatus);
                            resolve(refProjectStatus);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, refProjectStatus) {
            return refProjectStatusService.add(refProjectStatus);
        },
        [UPDATE]({ dispatch, commit }, refProjectStatus) {
            return refProjectStatusService.update(refProjectStatus);
        },
        [DELETE]({ dispatch, commit }, refProjectStatus) {
            return refProjectStatusService.deleteRefProjectStatus(refProjectStatus);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, refProjectStatuses) {
            state.status = { loaded: true };
            state.items = refProjectStatuses;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, refProjectStatus) {
            state.status = { loaded: true };
            state.currentRefProjectStatus = refProjectStatus;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRefProjectStatus = getBlankRefProjectStatus();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentRefProjectStatus = getBlankRefProjectStatus();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRefProjectStatus, {
                [field]: value
            });
        },
    }
}
