// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refPlatformService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefPlatform
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refPlatforms/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refPlatforms/${id}`).then(handleResponse);
}

function add(refPlatform) {
    return axios.post('/refPlatforms', refPlatform).then(handleResponse);
}

function update(refPlatform) {
    return axios.put(`/refPlatforms/${refPlatform.refPlatformId}`, refPlatform).then(handleResponse);
}

function deleteRefPlatform(refPlatform) {
    return axios.delete(`/refPlatforms/${refPlatform.refPlatformId}`).then(handleResponse);
}
