export const PREF_FILTER_SUFFIX = '-filter';
export const PREF_GROUP_SUFFIX = '-group';
export const PREF_SORT_SUFFIX = '-sort';
export const PREF_PAGE_SIZE_SUFFIX = '-page-size';
export const PREF_HIDDEN_COLUMNS_SUFFIX = '-hidden-columns';
export const PREF_SIZES_SUFFIX = '-column-sizes';
export const PREF_ORDER_SUFFIX = '-column-order';

export const PREF_AG_FILTER_SUFFIX = '-ag-filter';
export const PREF_AG_GROUP_SUFFIX = '-ag-group';
export const PREF_AG_COLUMNS_SUFFIX = '-ag-columns';
export const PREF_AG_PIVOT_SUFFIX = '-ag-pivot';
export const PREF_AG_PAGE_SIZE_SUFFIX = '-ag-page-size';

export const PREF_APPLICATION_GRID = 'applications';
export const PREF_APPLICATION_BY_PORTFOLIO_GRID = 'applications-by-portfolio';

export const PREF_OVERVIEW_PROJECT_GRID = 'overview-projects';
export const PREF_PORTFOLIO_GRID = 'portfolios';
export const PREF_PROGRAM_GRID = 'programs';
export const PREF_PROGRAM_ELEMENT_DETAIL_GRID = 'program-element-details';
export const PREF_PROGRAM_PROJECT_DETAIL_GRID = 'program-project-details';
export const PREF_PROGRAM_IDEA_DETAIL_GRID = 'program-idea-details';
export const PREF_PROJECT_GRID = 'projects';
export const PREF_IT_SERVICE_GRID = 'it-services';
export const PREF_CONTACT_GRID = 'contacts';
export const PREF_CAPABILITY_APPLICATIONS_GRID = 'capability-applications';

export const PREF_ACCOUNT_USERS_GRID = 'account-users';
export const PREF_ACCOUNT_DOMAINS_GRID = 'account-domains';
export const PREF_ACCOUNTS_SYSTEM_ADMIN_GRID = 'accounts-system-admin';
export const PREF_APPLICATION_CAPABILITIES_GRID = 'application-edit-capabilities';
export const PREF_ABANDONED_SYSTEM_ADMIN_GRID = 'abandoned-system-admin';
export const PREF_APPLICATION_CONTACT_GRID = 'application-contacts';
export const PREF_APPLICATION_EDIT_INTEGRATIONS_GRID = 'application-edit-integrations';
export const PREF_APPLICATION_EDIT_IT_SERVICES_GRID = 'application-edit-it-services';
export const PREF_AREA_APPLICATION_GRID = 'area-applications';
export const PREF_AREA_GRID = 'areas';
export const PREF_IT_SERVICE_CONTACT_GRID = 'it-service-contacts';
export const PREF_IT_SERVICE_APPLICATIONS_GRID = 'it-service-applications';
export const PREF_IT_SERVICE_IT_SERVICES_GRID = 'it-service-it-services';
export const PREF_IT_SERVICE_USES_IT_SERVICES_GRID = 'it-service-uses-it-services';
export const PREF_PORTFOLIO_APPLICATION_GRID = 'portfolio-applications';
export const PREF_PORTFOLIO_PROJECT_GRID = 'portfolio-projects';
export const PREF_PORTFOLIO_EDIT_APP_INTEGRATIONS_GRID = 'edit-portfolio-app-integrations';
export const PREF_PORTFOLIO_EDIT_APPS_IN_PORTFOLIO_GRID = 'edit-portfolio-apps-in-portfolio';
export const PREF_PORTFOLIO_EDIT_APPS_NOT_IN_PORTFOLIO_GRID = 'edit-portfolio-apps-not-in-portfolio';
export const PREF_PORTFOLIO_COST_GRID = 'portfolio-costs';

export const PREF_APPLICATION_STATE_GRID = 'application-states';
export const PREF_APPLICATION_TYPE_GRID = 'application-types';
export const PREF_INTEGRATION_TYPE_GRID = 'integration-types';
export const PREF_TECHNOLOGY_TYPE_GRID = 'technology-types';
export const PREF_CAPABILITY_SET_GRID = 'capability-sets';
export const PREF_CLASSIFICATION_GRID = 'classifications';
export const PREF_DATA_CLASSIFICATION_GRID = 'data-classifications';
export const PREF_COMPUTING_TYPE_GRID = 'computing-types';
export const PREF_PLATFORM_GRID = 'platforms';
export const PREF_PORTFOLIO_ADMIN_GRID = 'portfolios-admin';
export const PREF_PORTFOLIO_ADMIN_SORT_GRID = 'portfolios-admin-sort';
export const PREF_DRIVER_GRID = 'drivers';
export const PREF_PROGRAM_ADMIN_GRID = 'programs-admin';
export const PREF_PROJECT_SIZE_GRID = 'project-sizes';
export const PREF_PROJECT_STAGE_GRID = 'project-stage';
export const PREF_PROJECT_STATUS_GRID = 'project-status';
export const PREF_PROJECT_TYPE_GRID = 'project-types';

export const PREF_PRODUCT_ADJUSTMENTS_GRID = 'product-adjustments';
export const PREF_ACCOUNT_OWNERS_GRID = 'account-owners';
export const PREF_LICENSED_USERS_GRID = 'licensed-users';
export const PREF_PRODUCT_INVOICES_GRID = 'product-invoices';

export const PREF_BILLING_TRANSACTIONS_GRID = 'billing-transactions';
export const PREF_BILLING_PAYMENTS_GRID = 'billing-payments';
export const PREF_BILLING_PRODUCTS_GRID = 'billing-products';
export const PREF_BILLING_ADJUSTMENTS_GRID = 'billing-adjustments';

export const PREF_BILLING_PRODUCT_COUPONS_GRID = 'billing-product-coupons';

export const PREF_PROGRAM_EDIT_PROJECTS_GRID = 'program-edit-projects';
export const PREF_PROGRAM_EDIT_IDEAS_GRID = 'program-edit-ideas';

export const PREF_PROJECT_EDIT_APPLICATIONS_GRID = 'project-edit-applications';
export const PREF_PROJECT_EDIT_ELEMENTS_GRID = 'project-edit-elements';
export const PREF_PROJECT_EDIT_IT_SERVICES_GRID = 'project-edit-it-services';
export const PREF_PROJECT_EDIT_NOTES_GRID = 'project-edit-notes';
export const PREF_PROJECT_EDIT_VITALS_GRID = 'project-edit-vitals';
export const PREF_PROJECT_EDIT_RESOURCES_GRID = 'project-edit-resources';
export const PREF_TAG_APPLICATION_GRID = 'tag-applications';
export const PREF_TAG_GRID = 'tags';

export const PREF_BUSINESS_FACTOR_GRID = 'business-factor';
export const PREF_TECHNOLOGY_FACTOR_GRID = 'technology-factor';
export const PREF_CONTACT_APPLICATION_GRID = 'contact-applications';
export const PREF_CONTACT_IT_SERVICE_GRID = 'contact-it-services';
export const PREF_CONTACT_SUPPLIER_GRID = 'contact-suppliers';
export const PREF_CONTACT_PROJECT_GRID = 'contact-projects';
export const PREF_CONTACT_IDEA_GRID = 'contact-ideas';
export const PREF_CONTACT_PORTFOLIO_GRID = 'contact-portfolios';
export const PREF_CONTACT_PROGRAM_GRID = 'contact-programs';

export const PREF_PORTFOLIO_APP_COST_GRID = 'portfolio-app-costs';
export const PREF_PORTFOLIO_APP_QUESTION_GRID = 'portfolio-app-questions';

export const PREF_NAMESPACES_SYSTEM_ADMIN_GRID = 'namespaces-system-admin';
export const PREF_NAMESPACES_DETAIL_GROUP_GRID = 'namespaces-detail-group';
export const PREF_NAMESPACES_DETAIL_DASHBOARD_GRID = 'namespaces-detail-dashboard';
export const PREF_NAMESPACES_DETAIL_USER_GRID = 'namespaces-detail-user';

export const PREF_WORKSPACE_PROVIDERS_GRID = 'workspace-providers';
export const PREF_WORKSPACE_AVAILABLE_GRID = 'workspace-available';
export const PREF_WORKSPACE_MY_GRID = 'workspace-mine';

export const PREF_SUPPLIER_GRID = 'suppliers';
export const PREF_SUPPLIER_EDIT_ELEMENTS_GRID = 'supplier-edit-elements';
export const PREF_SUPPLIER_EDIT_RESOURCES_GRID = 'supplier-edit-resources';

export const PREF_LINK_GRID = 'links';

export const PREF_PRODUCTS_SYSTEM_ADMIN_GRID = 'products-system-admin';
export const PREF_PRODUCT_RATES_SYSTEM_ADMIN_GRID = 'product-rates-system-admin';
export const PREF_COUPONS_SYSTEM_ADMIN_GRID = 'coupons-system-admin';

export const PREF_IDEA_STATUS_GRID = 'idea-statuses';
export const PREF_IDEA_TYPE_GRID = 'idea-types';
export const PREF_IDEA_EDIT_APPLICATIONS_GRID = 'idea-edit-applications';
export const PREF_IDEA_EDIT_IT_SERVICES_GRID = 'idea-edit-it-services'

export const PREF_IDEA_GRID = 'ideas';
export const PREF_IDEA_DRIVER_GRID = 'idea-drivers';

export const PREF_VALUE_PLAN_GRID = 'valuedrivers';

export const PREF_AI_PROMPT_SYSTEM_ADMIN_GRID = 'ai-prompts';

export const PREF_BACKUPS_GRID = 'backups';
export const PREF_BACKUP_ENTITIES_GRID = "backup-entities";
export const PREF_RESTORES_GRID = 'restores';

export const PREF_CAPABILITY_GRID = 'capabilities';
export const PREF_CAPABILITY_APPLICATION_GRID = 'capability-applications';