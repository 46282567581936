import _ from 'lodash';
import { tagService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    ADD_APPLICATION_TAG,
    REMOVE_APPLICATION_TAG
  } from "./mutations.type";

function getBlankApplicationTag() {
    return {
        applicationTagId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        tagId: null,
        name: ""
    }
}

function getBlankTag() {
    return {
        tagId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        name: "",
        description: "",
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        applicationTags: []
    };
}

export const tags = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentTag: getBlankTag()
    },
    getters: {
        
    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                tagService.getByAccount(accountId)
                    .then(
                        tags => {
                            commit(GET_ALL_SUCCESS, tags);
                            resolve(tags);
                        },
                        error =>  {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, tagId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return tagService.getById(tagId)
                    .then(
                        tag => {
                            commit(GET_BY_ID_SUCCESS, tag);
                            resolve(tag);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, tag) {
            return tagService.add(tag);
        },
        [UPDATE]({ dispatch, commit }, tag) {
            return tagService.update(tag);
        },
        [DELETE]({ dispatch, commit }, tag) {
            return tagService.deleteTag(tag);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, tags) {
            state.status = { loaded: true };
            state.items = tags;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, tag) {
            state.status = { loaded: true };
            state.currentTag = tag;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentTag = getBlankTag();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentTag = getBlankTag();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentTag, {
                [field]: value
            });
        },
        [ADD_APPLICATION_TAG](state, applicationId) {
            var newAppTag = getBlankApplicationTag();
            newAppTag.applicationId = applicationId;
            state.currentTag.applicationTags.push(newAppTag);
        },
        [REMOVE_APPLICATION_TAG](state, applicationId) {
            _.remove(state.currentTag.applicationTags, { 'applicationId': applicationId });
        },
    }
}
