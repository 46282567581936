// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const businessQuestionService = {
    getAll,
    getByPortfolio,
    getById,
    update
};

function getAll() {
    // return axios.get(`/businessquestions/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/businessquestions/`).then(handleResponse);
}

function getByPortfolio(portfolioId) {
    return axios.get(`/businessquestions/?portfolioId=${portfolioId}`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/businessquestions/${id}`).then(handleResponse);
}

function update(businessQuestion) {
    return axios.put(`/businessquestions/${businessQuestion.accountQuestionId}`, businessQuestion).then(handleResponse);
}

