import { 
    RIGHT_EDIT_BUSINESS_FACTORS,
    RIGHT_EDIT_TECHNOLOGY_FACTORS,
    RIGHT_EDIT_ACCOUNT,
    RIGHT_EDIT_BUSINESS_AREAS,
    RIGHT_EDIT_TAGS,
    RIGHT_EDIT_DATA_CLASSIFICATIONS,
    RIGHT_EDIT_APPLICATION_STATES,
    RIGHT_EDIT_APPLICATION_TYPES,
    RIGHT_EDIT_TECHNOLOGY_TYPES,
    RIGHT_EDIT_IDEA_STATUSES,
    RIGHT_EDIT_INTEGRATION_TYPES,
    RIGHT_EDIT_CLASSIFICATIONS,
    RIGHT_EDIT_COMPUTING_TYPES,
    RIGHT_EDIT_PLATFORMS,
    RIGHT_EDIT_CAPABILITY_SETS,
    RIGHT_DELETE_CAPABILITY_SETS,
    RIGHT_EDIT_PROJECT_TYPES,
    RIGHT_EDIT_PROJECT_STAGES,
    RIGHT_EDIT_PROJECT_STATUSES,
    RIGHT_EDIT_DRIVERS,
    RIGHT_EDIT_PROGRAMS,
    RIGHT_EDIT_PORTFOLIO,
    RIGHT_EDIT_SUPPLIERS,
    RIGHT_EDIT_LINKS,
    RIGHT_EDIT_CAPABILITIES,
    RIGHT_IMPORT_DATA
} from "@/helpers";

export default [
    // { name: 'Manage Company', path: '/admin/account', right: RIGHT_EDIT_ACCOUNT },
    // { name: 'Manage Subscription', path: '/admin/subscription', right: RIGHT_EDIT_ACCOUNT  }, // TODO - right
    { name: 'Backups', path: '/admin/backups', right: RIGHT_EDIT_ACCOUNT },
    { name: 'Import Data', path: '/admin/import', right: RIGHT_IMPORT_DATA },
    { name: 'Factor Setup', path: '/admin/factors', right: RIGHT_EDIT_BUSINESS_FACTORS },
    { name: 'Manage Application Sets', path: '/admin/applicationsets', right: RIGHT_EDIT_APPLICATION_STATES },
    { name: 'Manage Areas', path: '/admin/areas', right: RIGHT_EDIT_BUSINESS_AREAS },
    { name: 'Manage Capabilities', path: '/admin/capabilities', right: RIGHT_EDIT_CAPABILITIES },
    { name: 'Manage Idea Sets', path: '/admin/ideasets', right: RIGHT_EDIT_IDEA_STATUSES },
    { name: 'Manage IT Service Sets', path: '/admin/itservicesets', right: RIGHT_EDIT_COMPUTING_TYPES },
    { name: 'Manage My Documents', path: '/admin/links', right: RIGHT_EDIT_LINKS },
    { name: 'Manage Portfolios', path: '/admin/portfolios', right: RIGHT_EDIT_PORTFOLIO },
    { name: 'Manage Process Sets', path: '/admin/processsets', right: RIGHT_DELETE_CAPABILITY_SETS },
    { name: 'Manage Programs', path: '/admin/programs', right: RIGHT_EDIT_PROGRAMS },
    { name: 'Manage Program Attributes', path: '/admin/programattributes', right: RIGHT_EDIT_DRIVERS },
    { name: 'Manage Project Sets', path: '/admin/projectsets', right: RIGHT_EDIT_PROJECT_STAGES },
    { name: 'Manage Suppliers', path: '/admin/suppliers', right: RIGHT_EDIT_SUPPLIERS },
    { name: 'Manage Tag Attributes', path: '/admin/tagattributes', right: RIGHT_EDIT_DATA_CLASSIFICATIONS },
    { name: 'Manage Tags', path: '/admin/tags', right: RIGHT_EDIT_TAGS },
    { name: 'Process Sets', path: '/processsets', right: RIGHT_EDIT_CAPABILITY_SETS },
    // {
    //     name: 'Application Types',
    //     path: '/admin/applicationtypes',
    //     right: RIGHT_EDIT_APPLICATION_TYPES
    // },
    // {
    //     name: 'Capability Sets',
    //     path: '/admin/capabilities',
    //     right: RIGHT_DELETE_CAPABILITY_SETS
    // },
    // {
    //     name: 'Classifications',
    //     path: '/admin/classifications',
    //     right: RIGHT_EDIT_CLASSIFICATIONS
    // },
    // {
    //     name: 'Computing Types',
    //     path: '/admin/computingtypes',
    //     right: RIGHT_EDIT_COMPUTING_TYPES
    // },
    // {
    //     name: 'Drivers',
    //     path: '/admin/drivers',
    //     right: RIGHT_EDIT_DRIVERS
    // },
    // {
    //     name: 'Integration Methods',
    //     path: '/admin/integrationtypes',
    //     right: RIGHT_EDIT_INTEGRATION_TYPES
    // },
    // {
    //     name: 'Platforms',
    //     path: '/admin/platforms',
    //     right: RIGHT_EDIT_PLATFORMS
    // },
    // {
    //     name: 'Programs',
    //     path: '/admin/programs',
    //     right: RIGHT_EDIT_PROGRAMS
    // },
    // {
    //     name: 'Project Stages',
    //     path: '/admin/projectstages',
    //     right: RIGHT_EDIT_PROJECT_STAGES
    // },
    // {
    //     name: 'Project Statuses',
    //     path: '/admin/projectstatuses',
    //     right: RIGHT_EDIT_PROJECT_STATUSES
    // },
    // {
    //     name: 'Project Types',
    //     path: '/admin/projecttypes',
    //     right: RIGHT_EDIT_PROJECT_TYPES
    // },
    // {
    //     name: 'Technology Types',
    //     path: '/admin/technologytypes',
    //     right: RIGHT_EDIT_TECHNOLOGY_TYPES
    // },
] 

