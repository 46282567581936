// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const ideaService = {
    getAll,
    getById,
    add,
    update,
    deleteEntity,

    vote, 
    
    // boards
    getStatusBoard,
    getStatusBoardFilterValues,
    moveBoardCard
};

function getAll() {
    // return axios.get(`/ideas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/ideas/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/ideas/${id}`).then(handleResponse);
}

function add(idea) {
    return axios.post('/ideas', idea).then(handleResponse);
}

function update(idea) {
    return axios.put(`/ideas/${idea.ideaId}`, idea).then(handleResponse);
}

function deleteEntity(idea) {
    return axios.delete(`/ideas/${idea.ideaId}`).then(handleResponse);
}

// vote
function vote(idea, vote) {
    return axios.put(`/ideas/${idea.ideaId}/vote`, { vote }).then(handleResponse);
}

// boards
function getStatusBoard() {
    // return axios.get(`/ideas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/ideas/board`).then(handleResponse);
}

function getStatusBoardFilterValues() {
    return axios.get(`/ideas/board/filtervalues`).then(handleResponse);
}

function moveBoardCard(id, refIdeaStatusId, displayOrder) {
    return axios.post(`/ideas/board/movecard`, { ideaId: id, targetStatusId: refIdeaStatusId, displayOrder } ).then(handleResponse);
}