<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <!-- <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar"> -->
          <!-- <i class="nc-icon nc-right-arrow text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-left-arrow text-center visible-on-sidebar-regular"></i> -->
        <!-- </button> -->
        <!-- <button v-if="!currentAccount.thumbnailLogoFilename" id="miniIcon" class="btn btn-icon btn-round"> -->
          <!-- <i class="nc-icon nc-right-arrow text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-left-arrow text-center visible-on-sidebar-regular"></i> -->
        <!-- </button> -->

        <!-- <button v-if="!currentAccount.thumbnailLogoFilename" id="miniIcon" class="btn btn-icon btn-round">
        </button> -->

        <img v-if="!!currentAccount.thumbnailLogoFilename && !$route.meta.nonWorkspaceRoute" :src="`${LOGO_IMAGE_BASE_URL}/${currentAccount.thumbnailLogoFilename}`" />

      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">
        </navbar-toggle-button>
      </div>
      <div class="navbar-brand" v-if="$route.meta.nonWorkspaceRoute"></div>
      <div class="navbar-brand" v-else-if="isSystemAdministrator && !!currentAccount.parentAccountName">{{currentUser.accountName}} ({{currentAccount.parentAccountName}})</div>
      <div class="navbar-brand" v-else>{{currentUser.accountName}}</div>
      
    </div>

    <template slot="navbar-menu">
      <!-- <form>
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form> -->
      <k-button :theme-color="'error'" @click="verifyEmailAddress" v-if="!currentUser.emailAddressVerified">Verify email to unlock trial!</k-button>
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link btn-magnify" href="#pablo">
            <i class="nc-icon nc-layout-11"></i>
            <p>
              <span class="d-md-block">Stats</span>
            </p>
          </a>
        </li> -->


        <navbar-accounts-menu />

        <navbar-admin-menu :links="adminLinks" v-if="isClientAccount" />

        <navbar-system-admin-menu />

        <drop-down icon="fa fa-user" tag="li"
                   position="right"
                   direction="none" 
                   class="nav-item dropdown"> <!-- btn-rotate -->
          <a slot="title"
             slot-scope="{isOpen}"
             class="nav-link dropdown-toggle"
             data-toggle="dropdown"
             aria-haspopup="true"
             :aria-expanded="isOpen">
            <i class="fa fa-user"></i>
            <p>
              <span class="d-lg-none d-md-block">Profile&nbsp;</span>
            </p>
          </a>
          <router-link :to="{name: 'admin.editPersonalProfile', query: { returnTo: $route.name, returnToId: $route.params.id }}" class="dropdown-item">{{userDescription}}</router-link>
          <router-link v-if="!isImpersonating" :to="{name: 'logout'}" class="dropdown-item"> Logout</router-link>
          <a v-if="isImpersonating" class="dropdown-item" href="#" @click.prevent="endImpersonation()">End Impersonation</a>
        </drop-down>
        <!-- <li class="nav-item">
          <a class="nav-link btn-rotate" href="#pablo">
            <i class="nc-icon nc-settings-gear-65"></i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li> -->
      </ul>

      <!-- <span class="navbar-text" style="padding-top: 0.7rem;">
        <span class="semi-bold"><router-link :to="{name: 'admin.editPersonalProfile'}">{{userDescription}}</router-link></span>&nbsp; 
        <span v-if="!isImpersonating"><router-link :to="{name: 'logout'}"> Logout</router-link></span>
        <span v-if="isImpersonating">(<a href="#" @click.prevent="endImpersonation()">End Impersonation</a>)</span>
      </span> -->
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import NavbarSystemAdminMenu from './NavbarSystemAdminMenu';
  import NavbarAdminMenu from './NavbarAdminMenu';
  import NavbarAccountsMenu from './NavbarAccountsMenu';
  import { mapState, mapGetters } from "vuex";
  import { AUTHENTICATION_END_IMPERSONATION } from "@/store/actions.type";
  import { LOGO_IMAGE_BASE_URL } from "@/helpers";
  import adminLinks from 'src/sidebarAdminLinks'
  // import systemAdminLinks from 'src/sidebarSystemAdminLinks'

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
      NavbarSystemAdminMenu,
      NavbarAdminMenu,
      NavbarAccountsMenu
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false,
        LOGO_IMAGE_BASE_URL,
        adminLinks,
        // systemAdminLinks
      }
    },
    computed: {
        ...mapGetters("authentication", [
            "isImpersonating",
            "isInRole",
            "hasRight",
            "isSystemAdministrator"
        ]),
        ...mapGetters("account", [
            "isClientAccount"
        ]),
        ...mapState("account", {
            currentAccount: state => state.currentAccount
        }),
        ...mapState("authentication", {
            currentUser: state => state.user,
            authStatus: state => state.status
        }),
        userDescription: function() {
            if (this.isImpersonating) {
                return `${this.currentUser.originalUserFirstNameLastName} (impersonating ${this.currentUser.firstNameLastName})`;
            } else {
                return this.currentUser.firstNameLastName;
            }
        }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      async endImpersonation() {
          try {
              await this.$store.dispatch(AUTHENTICATION_END_IMPERSONATION);
              this.$router.go(); // refresh current page
          } catch (error) {
              console.log(error);
          }
      },        
      verifyEmailAddress() {
        this.$router.push({ name: "admin.verifyEmail" });
      }
    }
  }

</script>
<style>

</style>
