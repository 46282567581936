import _ from 'lodash';
import { refProjectTypeService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankRefProjectType() {
    return {
        refProjectTypeId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        shortName: "",
        name: "",
        notes: "",
        displayOrder: null,
        projectCount: 0
    };
}

export const refprojecttypes = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentRefProjectType: getBlankRefProjectType()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                refProjectTypeService.getByAccount(accountId)
                    .then(
                        refProjectTypes => {
                            commit(GET_ALL_SUCCESS, refProjectTypes);
                            resolve(refProjectTypes);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, refProjectTypeId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return refProjectTypeService.getById(refProjectTypeId)
                    .then(
                        refProjectType => {
                            commit(GET_BY_ID_SUCCESS, refProjectType);
                            resolve(refProjectType);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, refProjectType) {
            return refProjectTypeService.add(refProjectType);
        },
        [UPDATE]({ dispatch, commit }, refProjectType) {
            return refProjectTypeService.update(refProjectType);
        },
        [DELETE]({ dispatch, commit }, refProjectType) {
            return refProjectTypeService.deleteRefProjectType(refProjectType);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, refProjectTypes) {
            state.status = { loaded: true };
            state.items = refProjectTypes;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, refProjectType) {
            state.status = { loaded: true };
            state.currentRefProjectType = refProjectType;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRefProjectType = getBlankRefProjectType();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentRefProjectType = getBlankRefProjectType();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRefProjectType, {
                [field]: value
            });
        },
    }
}
