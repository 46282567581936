import _ from 'lodash';
import Vue from 'vue';
import { projectService } from '@/services';
import { GUID_EMPTY, PROJECT_NOTE_STATUS_OPEN } from '@/helpers';
import { v4 as uuidv4 } from 'uuid';
import {
    GET_ALL,
    GET_BY_ID,
    ADD,
    UPDATE,
    UPDATE_PERCENT_COMPLETE,
    UPDATE_PRIORITY,
    DELETE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_LIST_ITEM,
    UPDATE_CURRENT_ITEM,
    ADD_APPLICATION_PROJECT,
    REMOVE_APPLICATION_PROJECT,
    ADD_IT_SERVICE_PROJECT,
    REMOVE_IT_SERVICE_PROJECT,
    ADD_PROGRAM_PROJECT,
    REMOVE_PROGRAM_PROJECT,
    ADD_PROJECT_PORTFOLIO,
    REMOVE_PROJECT_PORTFOLIO,
    ADD_PROJECT_DRIVER,
    REMOVE_PROJECT_DRIVER,
    ADD_AREA,
    REMOVE_AREA,

    SAVE_VALUE_DRIVER,
    REMOVE_VALUE_DRIVER,

    ADD_NOTE,
    SAVE_NOTE,
    REMOVE_NOTE,
    SET_CURRENT_NOTE,
    CLEAR_CURRENT_NOTE,
    UPDATE_CURRENT_NOTE,

    ADD_NOTE_COMMENT,

    UPDATE_FILTER,

    VIEW_BOARD, 
    VIEW_GRID, 
    SET_SELECTED_BOARD_STATUS,
    ADD_FILTER_PRIORITY, REMOVE_FILTER_PRIORITY,
    ADD_FILTER_TYPE, REMOVE_FILTER_TYPE,
    ADD_FILTER_PROGRAM, REMOVE_FILTER_PROGRAM,
    ADD_FILTER_SPONSOR, REMOVE_FILTER_SPONSOR,
    ADD_FILTER_INITIATED_BY, REMOVE_FILTER_INITIATED_BY 
  } from "./mutations.type";

function getBlankApplicationProject() {
    return {
        applicationProjectId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        projectId: null,
        name: ""
    }
}

function getBlankITServiceProject() {
    return {
        itServiceProjectId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        projectId: null,
        name: ""
    }
}

function getBlankProgramProject() {
    return {
        programProjectId: GUID_EMPTY,
        programId: GUID_EMPTY,
        projectId: GUID_EMPTY,
        name: ""
    }
}

function getBlankProjectPortfolio() {
    return {
        projectPortfolioId: GUID_EMPTY,
        projectId: GUID_EMPTY,
        portfolioId: GUID_EMPTY,
        name: ""
    }
}

function getBlankProjectDriver() {
    return {
        projectDriverId: GUID_EMPTY,
        refDriverId: GUID_EMPTY,
        projectId: GUID_EMPTY,
        programId: GUID_EMPTY,
        programName: "",
        name: ""
    }
}

function getBlankProjectArea() {
    return {
        projectAreaId: GUID_EMPTY,
        areaId: GUID_EMPTY,
        projectId: GUID_EMPTY,
        name: ""
    }
}

function getBlankProjectNote() {
    return {
        projectNoteId: GUID_EMPTY,
        refProjectNoteTypeId: GUID_EMPTY,
        refProjectNoteStatusId: PROJECT_NOTE_STATUS_OPEN,
        refProjectNoteStatusName: "Open",
        projectId: GUID_EMPTY,
        title: "",
        comments: []
    }
}

function getBlankProject() {
    return {
        projectId: GUID_EMPTY,
        accountId: GUID_EMPTY,

        ideaId: null,

        refProjectTypeId: null,
        refProjectStageId: null,
        refProjectStatusId: null,
        refPriorityId: null,
        accountProjectNumber: 0,
        name: "",
        initiationDate: null,
        targetDate: null,
        completionDate: null,
        percentComplete: null,
        notes: "",
        longDescription: "",
        initiatedByContactId: null,
        projectSponsorContactId: null,
        projectManagerContactId: null,
        // projectStage: null,

        accountBudgetVitalSignWeight: null,
        accountScheduleVitalSignWeight: null,
        accountScopeVitalSignWeight: null,
        accountResourcesVitalSignWeight: null,
        
        budgetVitalSignWeight: null,
        scheduleVitalSignWeight: null,
        scopeVitalSignWeight: null,
        resourcesVitalSignWeight: null,
        
        budgetVitalSignValue: null,
        scheduleVitalSignValue: null,
        scopeVitalSignValue: null,
        resourcesVitalSignValue: null,
        projectConditionValue: null,

        vitalChangeComments: null,

        projectSize: null,

        purposeComments: null,
        goalComments: null,
        significantComments: null,
        outcomeComments: null,

        boardDisplayOrder: null,

        ideaId: null,
        ideaName: null,

        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        refProjectTypeName: "",
        initiatedByContact: null,
        projectSponsorContact: null,
        projectManagerContact: null,
        applicationProjects: [],
        itServiceProjects: [],
        programProjects: [],
        projectPortfolios: [],
        projectResources: [],
        projectNotes: [],
        projectVitals: [],
        projectAreas: [],
        projectDrivers: [],
        valueDrivers: [],
        programDrivers: [],
        drivers: []
    };
}

export const projects = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        viewBoard: true,
        selectedBoardStatusId: null,
        selectedPriorities: [],
        selectedTypes: [],
        selectedPrograms: [],
        selectedSponsors: [],
        selectedInitiatedBy: [],
        items: [],
        filterStatusId: 0,
        currentProject: getBlankProject(),
        currentNote: getBlankProjectNote(),
        // newNoteTemporaryId: -1,
        // newCommentTemporaryId: -1
    },
    getters: {
        
    },
    actions: {
        [GET_ALL]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    projectService.getAll()
                        .then(
                            projects => {
                                commit(GET_ALL_SUCCESS, projects);
                                resolve(projects);
                            },
                            error =>  {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_ID]({ commit }, projectId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return projectService.getById(projectId)
                    .then(
                        project => {
                            commit(GET_BY_ID_SUCCESS, project);
                            resolve(project);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, project) {
            return projectService.add(project);
        },
        [UPDATE]({ dispatch, commit }, project) {
            return projectService.update(project);
        },
        [UPDATE_PERCENT_COMPLETE]({ dispatch, commit }, project) {
            return projectService.updatePercentComplete(project);
        },
        [UPDATE_PRIORITY]({ dispatch, commit }, project) {
            return projectService.updatePriority(project);
        },
        [DELETE]({ dispatch, commit }, project) {
            return projectService.deleteProject(project);
        },
    },
    mutations: {
        [VIEW_BOARD](state) {
            state.viewBoard = true;
        },
        [VIEW_GRID](state) {
            state.viewBoard = false;
        },
        [SET_SELECTED_BOARD_STATUS](state, value) {
            state.selectedBoardStatusId = value;
        },
        [ADD_FILTER_PRIORITY](state, value) {
            state.selectedPriorities.push(value);
        },
        [REMOVE_FILTER_PRIORITY](state, value) {
            state.selectedPriorities.splice(state.selectedPriorities.findIndex(item => item.refPriorityId === value.refPriorityId), 1);
        },
        [ADD_FILTER_TYPE](state, value) {
            state.selectedTypes.push(value);
        },
        [REMOVE_FILTER_TYPE](state, value) {
            state.selectedTypes.splice(state.selectedTypes.findIndex(item => item.refProjectTypeId === value.refProjectTypeId), 1);
        },
        [ADD_FILTER_PROGRAM](state, value) {
            state.selectedPrograms.push(value);
        },
        [REMOVE_FILTER_PROGRAM](state, value) {
            state.selectedPrograms.splice(state.selectedPrograms.findIndex(item => item.programId === value.programId), 1);
        },
        [ADD_FILTER_SPONSOR](state, value) {
            state.selectedSponsors.push(value);
        },
        [REMOVE_FILTER_SPONSOR](state, value) {
            state.selectedSponsors.splice(state.selectedSponsors.findIndex(item => item.contactId === value.contactId), 1);
        },
        [ADD_FILTER_INITIATED_BY](state, value) {
            state.selectedInitiatedBy.push(value);
        },
        [REMOVE_FILTER_INITIATED_BY](state, value) {
            state.selectedInitiatedBy.splice(state.selectedInitiatedBy.findIndex(item => item.contactId === value.contactId), 1);
        },

        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, projects) {
            state.status = { loaded: true };
            state.items = projects;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, project) {
            state.status = { loaded: true };
            state.currentProject = project;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentProject = getBlankProject();
        },
        [UPDATE_FILTER](state, filterValue) {
            state.filterStatusId = filterValue;
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentProject = getBlankProject();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentProject, {
                [field]: value
            });
        },
        [UPDATE_LIST_ITEM](state, { projectId, field, value }) {
            let entity = state.items.find(i => i.projectId === projectId);
            if (entity) {
                Object.assign(entity, {
                    [field]: value
                });
            }
        },
        [ADD_APPLICATION_PROJECT](state, { applicationId, name }) {
            var newAppProject = getBlankApplicationProject();
            newAppProject.applicationId = applicationId;
            newAppProject.name = name;
            state.currentProject.applicationProjects.push(newAppProject);
        },
        [REMOVE_APPLICATION_PROJECT](state, applicationId) {
            let arr = state.currentProject.applicationProjects;
            arr.splice(arr.findIndex(item => item.applicationId === applicationId), 1)
        },

        [ADD_IT_SERVICE_PROJECT](state, { itServiceId, name }) {
            var newITServiceProject = getBlankITServiceProject();
            newITServiceProject.itServiceId = itServiceId;
            newITServiceProject.name = name;
            state.currentProject.itServiceProjects.push(newITServiceProject);
        },
        [REMOVE_IT_SERVICE_PROJECT](state, itServiceId) {
            let arr = state.currentProject.itServiceProjects;
            arr.splice(arr.findIndex(item => item.itServiceId === itServiceId), 1)
        },

        [ADD_PROGRAM_PROJECT](state, program) {
            var newRecord = getBlankProgramProject();
            newRecord.programId = program.programId;
            newRecord.name = program.name;
            state.currentProject.programProjects.push(newRecord);

            // remove any drivers if this is the first program added
            if (state.currentProject.programProjects.length === 1)
                state.currentProject.drivers.splice(0);

            // remove any existing project drivers
            state.currentProject.projectDrivers.splice(0);

            // add all program drivers to the currentEntity list of drivers
            if (program.programDrivers) {
                program.programDrivers.forEach(driver => {
                    let newDriver = getBlankProjectDriver();
                    newDriver.refDriverId = driver.refDriverId;
                    newDriver.programName = program.name;
                    newDriver.programId = program.programId;
                    newDriver.name = driver.refDriverName;
                    state.currentProject.drivers.push(newDriver);
                });
            }
            
            // 
        },
        [REMOVE_PROGRAM_PROJECT](state, program) {
            let programArray = state.currentProject.programProjects;
            programArray.splice(programArray.findIndex(item => item.programId === program.programId), 1)

            // remove any matching driver records
            program.programDrivers.forEach(driver => {
                let driverArray = state.currentProject.drivers;
                driverArray.splice(driverArray.findIndex(item => item.programId == program.programId && item.refDriverId === driver.refDriverId), 1)
            });            
        },

        [ADD_PROJECT_PORTFOLIO](state, { portfolioId, name }) {
            var newRecord = getBlankProjectPortfolio();
            newRecord.portfolioId = portfolioId;
            newRecord.name = name;
            state.currentProject.projectPortfolios.push(newRecord);
        },
        [REMOVE_PROJECT_PORTFOLIO](state, portfolioId) {
            let projectArray = state.currentProject.projectPortfolios;
            projectArray.splice(projectArray.findIndex(item => item.portfolioId === portfolioId), 1)
        },
        
        [ADD_PROJECT_DRIVER](state, { refDriverId, name }) {
            var newRecord = getBlankProjectDriver();
            newRecord.refDriverId = refDriverId;
            newRecord.name = name;
            state.currentProject.projectDrivers.push(newRecord);
        },
        [REMOVE_PROJECT_DRIVER](state, refDriverId) {
            let projectArray = state.currentProject.projectDrivers;
            projectArray.splice(projectArray.findIndex(item => item.refDriverId === refDriverId), 1)
        },
        

        [ADD_AREA](state, { areaId, name }) {
            var newRecord = getBlankProjectArea();
            newRecord.areaId = areaId;
            newRecord.name = name;
            state.currentProject.projectAreas.push(newRecord);
        },
        [REMOVE_AREA](state, areaId) {
            let areaArray = state.currentProject.projectAreas;
            areaArray.splice(areaArray.findIndex(item => item.areaId === areaId), 1)
            // _.remove(state.currentProject.programProjects, { 'programId': programId });
            // state.currentProject.programProjects = [...state.currentProject.programProjects];
        },

        [SAVE_VALUE_DRIVER](state, valueDriver) {
            if (valueDriver.valueDriverId == GUID_EMPTY) {
                valueDriver.valueDriverId = uuidv4(); //state.newNoteTemporaryId;
                // state.newNoteTemporaryId = state.newNoteTemporaryId - 1;
            }
            if (state.currentProject.valueDrivers.find(i => i.valueDriverId == valueDriver.valueDriverId)) {
                Vue.set(state.currentProject.valueDrivers, 
                        state.currentProject.valueDrivers.findIndex(i => i.valueDriverId == valueDriver.valueDriverId),
                        valueDriver);
            } else {
                state.currentProject.valueDrivers.push(valueDriver);
            }

            // state.currentValueDriver = valueDriver;
        },
        [REMOVE_VALUE_DRIVER](state, valueDriverId) {
            // state.currentValueDriver = getBlankProjectNote();
            _.remove(state.currentProject.valueDrivers, { 'valueDriverId': valueDriverId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentProject.valueDrivers = state.currentProject.valueDrivers.slice(0);
        },


        [ADD_NOTE](state) {
            var newEntry = getBlankProjectNote();
            newEntry.projectNoteId = uuidv4(); //state.newNoteTemporaryId;
            // state.newNoteTemporaryId = state.newNoteTemporaryId - 1;
            state.currentProject.projectNotes.push(newEntry);
            state.currentNote = newEntry;
        },
        [SAVE_NOTE](state, note) {
            if (note.projectNoteId == GUID_EMPTY) {
                note.projectNoteId = uuidv4(); //state.newNoteTemporaryId;
                // state.newNoteTemporaryId = state.newNoteTemporaryId - 1;
            }
            if (state.currentProject.projectNotes.find(i => i.projectNoteId == note.projectNoteId)) {
                Vue.set(state.currentProject.projectNotes, 
                        state.currentProject.projectNotes.findIndex(i => i.projectNoteId == note.projectNoteId),
                        note);
            } else {
                state.currentProject.projectNotes.push(note);
            }

            state.currentNote = note;
        },
        [REMOVE_NOTE](state, projectNoteId) {
            state.currentNote = getBlankProjectNote();
            _.remove(state.currentProject.projectNotes, { 'projectNoteId': projectNoteId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentProject.projectNotes = state.currentProject.projectNotes.slice(0);
        },
        [SET_CURRENT_NOTE](state, note) {
            state.currentNote = note;
        },
        [CLEAR_CURRENT_NOTE](state) {
            state.currentNote = getBlankProjectNote();
        },
        [UPDATE_CURRENT_NOTE](state, { field, value }) {
            Object.assign(state.currentNote, {
                [field]: value
            });
        },

        [ADD_NOTE_COMMENT](state, comment) {
            comment.projectNoteCommentId = uuidv4(); //state.newCommentTemporaryId;
            // state.newCommentTemporaryId = state.newCommentTemporaryId - 1;
            state.currentNote.comments.push(comment);
        }
    }
}
