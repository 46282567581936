// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';
import { v4 as uuidv4 } from 'uuid';

export const contactService = {
    getAll,
    getById,
    checkEmail,
    add,
    update,
    updateEmailAddress,
    updateContactPhone,
    deleteContact
};

function getAll() {
    // return axios.get(`/contacts/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/contacts/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/contacts/${id}`).then(handleResponse);
}

function checkEmail(emailAddress, contactId) {
    //return axios.get(`/contacts/checkEmail/${encodeURIComponent(emailAddress)}?contactId=${contactId}&x=${uuidv4()}`).then(handleResponse);
    let payload = { emailAddress, contactId };
    return axios.post('/contacts/checkemail', payload)
        .then(handleResponse)
}

function add(contact, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("contactData", JSON.stringify(contact))

    return axios.post(`/contacts`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);

    // return axios.post('/contacts', contact).then(handleResponse);
}

function update(contact, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("contactData", JSON.stringify(contact));

    return axios.put(`/contacts/${contact.contactId}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);

    // return axios.put(`/contacts/${contact.contactId}`, contact).then(handleResponse);
}

function updateEmailAddress(contact) {
    return axios.put(`/contacts/${contact.contactId}/emailAddress`, contact).then(handleResponse);
}

function updateContactPhone(contact) {
    return axios.put(`/contacts/${contact.contactId}/contactPHone`, contact).then(handleResponse);
}

function deleteContact(contact) {
    return axios.delete(`/contacts/${contact.contactId}`).then(handleResponse);
}

