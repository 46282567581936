// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const aiPromptService = {
    getAll,
    getById,
    add,
    update,
    deleteEntity,
};

function getAll() {
    // return axios.get(`/aiprompts/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/aiprompts/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/aiprompts/${id}`).then(handleResponse);
}

function add(aiprompt) {
    return axios.post('/aiprompts', aiprompt).then(handleResponse);
}

function update(aiprompt) {
    return axios.put(`/aiprompts/${aiprompt.aiPromptId}`, aiprompt).then(handleResponse);
}

function deleteEntity(aiprompt) {
    return axios.delete(`/aiprompts/${aiprompt.aiPromptId}`).then(handleResponse);
}

