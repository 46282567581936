// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const transactionService = {
    getAll,
    getById,
    getPendingSetup,
    getPaymentSetup
};


function getAll() {
    return axios.get(`/transactions/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/transactions/${id}`).then(handleResponse);
}

function getPendingSetup() {
    return axios.get(`/transactions/pending_setup`).then(handleResponse);
}

// stripe values to setup new payment method
function getPaymentSetup() {
    return axios.get(`/transactions/payment_setup`).then(handleResponse);
}
