import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// import AdminLayout from '../components/Dashboard/Layout/AdminLayout.vue'
import PrintLayout from '../components/Dashboard/Layout/PrintLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'

// // Dashboard pages
// const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
// const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// // Pages
// import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
// import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
// import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
// import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
// import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// // Components pages
// import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
// import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
// import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
// import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
// import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
// import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
// import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// // Forms pages
// const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
// const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
// const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
// const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// // TableList pages
// const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
// const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
// const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// // Maps pages
// const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
// const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
// const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

// // Calendar
// import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// // Charts
// const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')

// AEA
// import Home from 'src/views/Home.vue';
const Login = () => import(/* webpackChunkName: "register" */ 'src/views/account/Login.vue')
const Logout = () => import(/* webpackChunkName: "register" */ 'src/views/account/Logout.vue')
const Register = () => import(/* webpackChunkName: "register" */ 'src/views/account/Register.vue')
const RegisterBeta = () => import(/* webpackChunkName: "register" */ 'src/views/account/RegisterBeta.vue')
const ForgotPassword = () => import(/* webpackChunkName: "register" */ 'src/views/account/ForgotPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "register" */ 'src/views/account/ResetPassword.vue')
const VerifyEmailNeeded = () => import(/* webpackChunkName: "register" */ 'src/views/account/VerifyEmailNeeded.vue')
const VerifyEmail = () => import(/* webpackChunkName: "register" */ 'src/views/account/VerifyEmail.vue')
const Trial = () => import(/* webpackChunkName: "register" */ 'src/views/account/Trial.vue')

const Terms = () => import(/* webpackChunkName: "misc" */ 'src/views/Terms.vue')
const Privacy = () => import(/* webpackChunkName: "misc" */ 'src/views/Privacy.vue')
const AccountCheck = () => import(/* webpackChunkName: "misc" */ 'src/views/AccountCheck.vue')

const Overview = () => import(/* webpackChunkName: "overview" */ 'src/views/overview/Overview.vue')
const OverviewPrint = () => import(/* webpackChunkName: "overview" */ 'src/views/overview/OverviewPrint.vue')

const Analytics = () => import(/* webpackChunkName: "analytics" */ 'src/views/Analytics.vue')

const MyDocuments = () => import(/* webpackChunkName: "overview" */ 'src/views/documents/MyDocuments.vue')

const Portfolios = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/Portfolios.vue')
const PortfolioImport = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioImport.vue')
const PortfolioDetails = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioDetails.vue')
const PortfolioDetailsPrint = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioDetailsPrint.vue')
const PortfolioScores = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioScores.vue')
const PortfolioApplicationScores = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioApplicationScores.vue')
const PortfolioApplicationScoresPrint = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioApplicationScoresPrint.vue')
const PortfolioAdd = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioAdd.vue')
const PortfolioEdit = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioEdit.vue')
const PortfolioEditPermissions = () => import(/* webpackChunkName: "portfolios" */ 'src/views/portfolios/PortfolioEditPermissions.vue')

const Applications = () => import(/* webpackChunkName: "applications" */ 'src/views/applications/Applications.vue')
const ApplicationEdit = () => import(/* webpackChunkName: "applications" */ 'src/views/applications/ApplicationEdit.vue')
const ApplicationEditWorkshop = () => import(/* webpackChunkName: "applications" */ 'src/views/applications/ApplicationEditWorkshop.vue')
const ApplicationsImport = () => import(/* webpackChunkName: "applications" */ 'src/views/applications/ApplicationsImport.vue')
// const ApplicationContextDiagram = () => import(/* webpackChunkName: "applications" */ 'src/views/applications/ApplicationContextDiagram.vue')

const ProcessSets = () => import(/* webpackChunkName: "processsets" */ 'src/views/processsets/ProcessSets.vue')

const ITServices = () => import(/* webpackChunkName: "itservices" */ 'src/views/itservices/ITServices.vue')
const ITServiceEdit = () => import(/* webpackChunkName: "itservices" */ 'src/views/itservices/ITServiceEdit.vue')
const ITServicesImport = () => import(/* webpackChunkName: "itservices" */ 'src/views/itservices/ITServicesImport.vue')

const Contacts = () => import(/* webpackChunkName: "contacts" */ 'src/views/contacts/Contacts.vue')
const ContactEdit = () => import(/* webpackChunkName: "contacts" */ 'src/views/contacts/ContactEdit.vue')
const ContactsImport = () => import(/* webpackChunkName: "contacts" */ 'src/views/contacts/ContactsImport.vue')

const Ideas = () => import(/* webpackChunkName: "ideas" */ 'src/views/ideas/Ideas.vue')
const IdeasPrint = () => import(/* webpackChunkName: "ideas" */ 'src/views/ideas/IdeasPrint.vue')
const IdeaEdit = () => import(/* webpackChunkName: "ideas" */ 'src/views/ideas/IdeaEdit.vue')
const IdeasImport = () => import(/* webpackChunkName: "ideas" */ 'src/views/ideas/IdeasImport.vue')
const IdeaBoard = () => import(/* webpackChunkName: "ideas" */ 'src/views/ideas/IdeaBoard.vue')

const Projects = () => import(/* webpackChunkName: "projects" */ 'src/views/projects/Projects.vue')
const ProjectBoard = () => import(/* webpackChunkName: "projects" */ 'src/views/projects/ProjectsBoard.vue')
const ProjectsPrint = () => import(/* webpackChunkName: "projects" */ 'src/views/projects/ProjectsPrint.vue')
const ProjectEdit = () => import(/* webpackChunkName: "projects" */ 'src/views/projects/ProjectEdit.vue')
const ProjectEditPrint = () => import(/* webpackChunkName: "projects" */ 'src/views/projects/ProjectEditPrint.vue')
const ProjectsImport = () => import(/* webpackChunkName: "projects" */ 'src/views/projects/ProjectsImport.vue')

const Programs = () => import(/* webpackChunkName: "programs" */ 'src/views/programs/Programs.vue')
const ProgramsPrint = () => import(/* webpackChunkName: "programs" */ 'src/views/programs/ProgramsPrint.vue')
const ProgramEdit = () => import(/* webpackChunkName: "programs" */ 'src/views/programs/ProgramEdit.vue')
// const ProgramsImport = () => import(/* webpackChunkName: "programs" */ 'src/views/programs/ProgramsImport.vue')


const Areas = () => import(/* webpackChunkName: "areas" */ 'src/views/areas/Areas.vue')
const AreaEdit = () => import(/* webpackChunkName: "areas" */ 'src/views/areas/AreaEdit.vue')
const AreasImport = () => import(/* webpackChunkName: "areas" */ 'src/views/areas/AreasImport.vue')

const Tags = () => import(/* webpackChunkName: "tags" */ 'src/views/tags/Tags.vue')
const TagEdit = () => import(/* webpackChunkName: "tags" */ 'src/views/tags/TagEdit.vue')
const TagsImport = () => import(/* webpackChunkName: "tags" */ 'src/views/tags/TagsImport.vue')

const Factors = () => import(/* webpackChunkName: "factors" */ 'src/views/factors/Factors.vue')

const Setup = () => import(/* webpackChunkName: "setup" */ 'src/views/setup/Setup.vue')
const SetupSubscription = () => import(/* webpackChunkName: "setup" */ 'src/views/setup/SetupSubscription.vue')

// const AdminSettings = () => import(/* webpackChunkName: "account" */ 'src/views/admin/AdminSettings.vue')
const Admin = () => import(/* webpackChunkName: "account" */ 'src/views/admin/Admin.vue')
const AdminAccountDomains = () => import(/* webpackChunkName: "account" */ 'src/views/admin/domains/AccountDomains.vue')
const AdminAccountResetData = () => import(/* webpackChunkName: "account" */ 'src/views/admin/ResetAccountData.vue')
const AccountJump = () => import(/* webpackChunkName: "account" */ 'src/views/admin/AccountJump.vue') 
const InviteUser = () => import(/* webpackChunkName: "account" */ 'src/views/admin/InviteUser.vue')
const EditUserProfile = () => import(/* webpackChunkName: "account" */ 'src/views/admin/EditUserProfile.vue')
const ResendEmailVerifyMessage = () => import(/* webpackChunkName: "account" */ 'src/views/admin/ResendEmailVerifyMessage.vue') 

const Import = () => import(/* webpackChunkName: "import" */ 'src/views/admin/import/Import.vue')


const SubscriptionAdmin = () => import(/* webpackChunkName: "subscription" */ 'src/views/admin/subscription/SubscriptionAdmin.vue')
const SubscriptionChangePlan = () => import(/* webpackChunkName: "subscription" */ 'src/views/admin/subscription/SubscriptionChangePlan.vue')
const SubscriptionChangePaymentMethod = () => import(/* webpackChunkName: "subscription" */ 'src/views/admin/subscription/SubscriptionChangePaymentMethod.vue')
const SubscriptionChangeTrial = () => import(/* webpackChunkName: "subscription" */ 'src/views/admin/subscription/SubscriptionChangeTrial.vue')
const SubscriptionChangeDemo = () => import(/* webpackChunkName: "subscription" */ 'src/views/admin/subscription/SubscriptionChangeDemo.vue')
const PayTest = () => import(/* webpackChunkName: "subscription" */ 'src/views/admin/subscription/PayTest.vue')

const Workspaces = () => import(/* webpackChunkName: "workspaces" */ 'src/views/admin/workspaces/Workspaces.vue')
const CreateWorkspace = () => import(/* webpackChunkName: "workspaces" */ 'src/views/admin/workspaces/CreateWorkspace.vue')
const CreateProviderWorkspace = () => import(/* webpackChunkName: "workspaces" */ 'src/views/admin/workspaces/CreateProvider.vue')
const CreateChildWorkspace = () => import(/* webpackChunkName: "workspaces" */ 'src/views/admin/workspaces/CreateChildWorkspace.vue')

const RefApplicationSets = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/applicationsets/ApplicationSets.vue')
const RefApplicationStateEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/applicationsets/ApplicationStateEdit.vue')
const RefApplicationTypeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/applicationsets/ApplicationTypeEdit.vue')
const RefTechnologyTypeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/applicationsets/TechnologyTypeEdit.vue')
const RefIntegrationTypeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/applicationsets/IntegrationTypeEdit.vue')

const RefIdeaSets = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/ideasets/IdeaSets.vue')
const RefIdeaStatusEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/ideasets/IdeaStatusEdit.vue')
const RefIdeaTypeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/ideasets/IdeaTypeEdit.vue')

const RefITServiceSets = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/itservicesets/ITServiceSets.vue')
const RefClassificationEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/itservicesets/ClassificationEdit.vue')
const RefComputingTypeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/itservicesets/ComputingTypeEdit.vue')
const RefPlatformEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/itservicesets/PlatformEdit.vue')

const AdminProcessSets = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/processsets/ProcessSets.vue')
const AdminProcessSetEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/processsets/ProcessSetEdit.vue')

const RefProjectSets = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/projectsets/ProjectSets.vue')
const RefProjectSizeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/projectsets/ProjectSizeEdit.vue')
const RefProjectTypeEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/projectsets/ProjectTypeEdit.vue')
const RefProjectStageEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/projectsets/ProjectStageEdit.vue')
const RefProjectStatusEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/projectsets/ProjectStatusEdit.vue')

const RefProgramAttributes = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/programattributes/ProgramAttributes.vue')
const RefDriverEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/programattributes/DriverEdit.vue')

const RefTagAttributes = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/tagattributes/TagAttributes.vue')
const RefDataClassificationEdit = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/tagattributes/DataClassificationEdit.vue')

const Capabilities = () => import(/* webpackChunkName: "capabilities" */ 'src/views/admin/capabilities/Capabilities.vue')
const CapabilityEdit = () => import(/* webpackChunkName: "capabilities" */ 'src/views/admin/capabilities/CapabilityEdit.vue')

const AdminPrograms = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/programs/Programs.vue')

const AdminPortfolios = () => import(/* webpackChunkName: "refadmin" */ 'src/views/admin/ref/portfolios/Portfolios.vue')

const SystemAdminAccounts = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/accounts/Accounts.vue')

const SystemAdminBilling = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/BillingAdmin.vue')

const SystemAdminAbandonedRegistrations = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/registrations/AbandonedRegistrations.vue')

const SystemAdminBillingCertifiedProviderEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/provider/CertifiedProviderEdit.vue')
const SystemAdminBillingBillingDayEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/billingday/BillingDayEdit.vue')
const SystemAdminBillingBillingMethodEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/billingmethod/BillingMethodEdit.vue')
const SystemAdminBillingBillChildAccountsEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/provider/BillChildAccountsEdit.vue')
const SystemAdminBillingProviderMarginEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/provider/ProviderMarginEdit.vue')

const SystemAdminBillingProductDetails = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/accountproducts/AccountProductDetails.vue')
const SystemAdminBillingProductEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/accountproducts/AccountProductEdit.vue')
const SystemAdminBillingProductCouponEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/accountproducts/AccountProductCouponEdit.vue')

const SystemAdminBillingPaymentEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/payments/AccountPaymentEdit.vue')
const SystemAdminBillingAdjustmentEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/billing/adjustments/AccountAdjustmentEdit.vue')

const SystemAdminCoupons = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/coupons/Coupons.vue')
const SystemAdminCouponEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/coupons/CouponEdit.vue')

const SystemAdminProducts = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/products/Products.vue')
const SystemAdminProductDetails = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/products/ProductDetails.vue')
const SystemAdminProductEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/products/ProductEdit.vue')
const SystemAdminProductRateEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/products/RateEdit.vue')

const SystemAdminCopyAccount = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/CopyAccount.vue')
const SystemAdminAnalyticsNamespaces = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/analytics/Namespaces.vue')
const SystemAdminAnalyticsNamespaceDetails = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/analytics/NamespaceDetails.vue')

const SystemAdminAIPrompts = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/ai/Prompts.vue')
const SystemAdminAIPromptEdit = () => import(/* webpackChunkName: "systemadmin" */ 'src/views/admin/system/ai/PromptEdit.vue')

const Backups = () => import(/* webpackChunkName: "backups" */ 'src/views/admin/backups/Backups.vue')
const BackupDetails = () => import(/* webpackChunkName: "backups" */ 'src/views/admin/backups/BackupDetails.vue')

const Suppliers = () => import(/* webpackChunkName: "suppliers" */ 'src/views/admin/suppliers/Suppliers.vue')
const SupplierEdit = () => import(/* webpackChunkName: "suppliers" */ 'src/views/admin/suppliers/SupplierEdit.vue')

const Links = () => import(/* webpackChunkName: "links" */ 'src/views/admin/links/Links.vue')
const LinkEdit = () => import(/* webpackChunkName: "links" */ 'src/views/admin/links/LinkEdit.vue')

const SystemAdminLinks = () => import(/* webpackChunkName: "links" */ 'src/views/admin/globallinks/Links.vue')
const SystemAdminLinkEdit = () => import(/* webpackChunkName: "links" */ 'src/views/admin/globallinks/LinkEdit.vue')



import { 
    RIGHT_EDIT_BUSINESS_FACTORS,
    RIGHT_EDIT_TECHNOLOGY_FACTORS,
    RIGHT_INVITE_USER,
    RIGHT_EDIT_APPLICATION_STATES,
    RIGHT_EDIT_APPLICATION_TYPES,
    RIGHT_EDIT_TECHNOLOGY_TYPES,
    RIGHT_EDIT_INTEGRATION_TYPES,
    RIGHT_EDIT_CLASSIFICATIONS,
    RIGHT_EDIT_DATA_CLASSIFICATIONS,
    RIGHT_EDIT_COMPUTING_TYPES,
    RIGHT_EDIT_PLATFORMS,
    RIGHT_EDIT_PROJECT_SIZES,
    RIGHT_EDIT_PROJECT_TYPES,
    RIGHT_EDIT_PROJECT_STAGES,
    RIGHT_EDIT_PROJECT_STATUSES,
    RIGHT_EDIT_DRIVERS,
    RIGHT_EDIT_IDEA_STATUSES,
    RIGHT_EDIT_IDEA_TYPES,
    RIGHT_EDIT_SUPPLIERS,
    RIGHT_EDIT_LINKS,
    RIGHT_DELETE_CAPABILITY_SETS,
    RIGHT_CREATE_PORTFOLIO,
    RIGHT_IMPORT_DATA,
    RIGHT_IMPORT_EXPORT_PORTFOLIO,
    RIGHT_IMPORT_EXPORT_APPLICATIONS,
    RIGHT_IMPORT_EXPORT_IT_SERVICES,
    RIGHT_IMPORT_EXPORT_CONTACTS,
    RIGHT_IMPORT_EXPORT_PROJECTS,
    RIGHT_IMPORT_EXPORT_PROGRAMS,
    RIGHT_IMPORT_EXPORT_BUSINESS_AREAS,
    RIGHT_IMPORT_EXPORT_TAGS,
    RIGHT_IMPORT_EXPORT_IDEAS
} from "@/helpers";

let sitePages = {
    path: '/', component: DashboardLayout, redirect: '/overview',
    children: [
      { path: '/overview', name: 'overview', component: Overview },
      { path: '/analytics', name: 'analytics', component: Analytics },
      { path: '/jump/:id', name: 'account.jump', component: AccountJump },
      { path: '/account_check', name: 'account.check', component: AccountCheck },

      { path: '/documents', name: 'my.documents', component: MyDocuments },

      { path: '/portfolios', name: 'portfolios', component: Portfolios },
      { path: '/portfolios/:id/import', name: 'portfolio.import', component: PortfolioImport },
      { path: '/portfolios/add', name: 'portfolio.add', component: PortfolioAdd, meta: { right: RIGHT_CREATE_PORTFOLIO } },
      { path: '/portfolios/edit/:id', name: 'portfolio.edit', component: PortfolioEdit },
      { path: '/portfolios/edit/:id/permissions', name: 'portfolio.edit.permissions', component: PortfolioEditPermissions },
      { path: '/portfolios/:id/costs', name: 'portfolio.scores', component: PortfolioScores },
      { path: '/portfolios/:id/applications', name: 'portfolio.application.scores', component: PortfolioApplicationScores },
      { path: '/portfolios/:id', name: 'portfolio.detail', component: PortfolioDetails },
      
      { path: '/applications', name: 'applications', component: Applications },
      { path: '/applications/import', name: 'applications.import', component: ApplicationsImport, meta: { right: RIGHT_IMPORT_EXPORT_APPLICATIONS } },
      { path: '/applications/add', name: 'application.add', component: ApplicationEdit },
      { path: '/applications/edit/:id', name: 'application.edit', component: ApplicationEdit },
      { path: '/applications/workshop/:portfolioId', name: 'application.workshop.add', component: ApplicationEditWorkshop },
      { path: '/applications/workshop/:portfolioId/:id', name: 'application.workshop.edit', component: ApplicationEditWorkshop },
      // { path: '/applications/diagram/:id', name: 'application.context.diagram', component: ApplicationContextDiagram },

      { path: '/processsets', name: 'processsets', component: ProcessSets },

      { path: '/itservices', name: 'itservices', component: ITServices },
      { path: '/itservices/import', name: 'itservices.import', component: ITServicesImport, meta: { right: RIGHT_IMPORT_EXPORT_IT_SERVICES } },
      { path: '/itservices/add', name: 'itservice.add', component: ITServiceEdit },
      { path: '/itservices/edit/:id', name: 'itservice.edit', component: ITServiceEdit },

      { path: '/contacts', name: 'contacts', component: Contacts },
      { path: '/contacts/import', name: 'contacts.import', component: ContactsImport, meta: { right: RIGHT_IMPORT_EXPORT_CONTACTS } },
      { path: '/contacts/add', name: 'contact.add', component: ContactEdit },
      { path: '/contacts/edit/:id', name: 'contact.edit', component: ContactEdit },

      { path: '/ideas', name: 'ideas', component: Ideas },
      { path: '/ideas/board', name: 'ideas.board', component: IdeaBoard },
      { path: '/ideas/import', name: 'ideas.import', component: IdeasImport, meta: { right: RIGHT_IMPORT_EXPORT_IDEAS } },
      { path: '/ideas/add', name: 'idea.add', component: IdeaEdit },
      { path: '/ideas/edit/:id', name: 'idea.edit', component: IdeaEdit },

      { path: '/projects', name: 'projects', component: Projects },
      { path: '/projects/board', name: 'projects.board', component: ProjectBoard },
      { path: '/projects/import', name: 'projects.import', component: ProjectsImport, meta: { right: RIGHT_IMPORT_EXPORT_PROJECTS } },
      { path: '/projects/add', name: 'project.add', component: ProjectEdit },
      { path: '/projects/edit/:id', name: 'project.edit', component: ProjectEdit },
      
      { path: '/programs', name: 'programs', component: Programs },
      // { path: '/programs/import', name: 'programs.import', component: ProgramsImport, meta: { right: RIGHT_IMPORT_EXPORT_PROGRAMS } },
      { path: '/programs/add', name: 'program.add', component: ProgramEdit },
      { path: '/programs/edit/:id', name: 'program.edit', component: ProgramEdit },
      
      { path: '/admin/areas', name: 'areas', component: Areas },
      { path: '/admin/areas/import', name: 'areas.import', component: AreasImport, meta: { right: RIGHT_IMPORT_EXPORT_BUSINESS_AREAS } },
      { path: '/admin/areas/add', name: 'area.add', component: AreaEdit },
      { path: '/admin/areas/edit/:id', name: 'area.edit', component: AreaEdit },

      { path: '/admin/tags', name: 'tags', component: Tags },
      { path: '/admin/tags/import', name: 'tags.import', component: TagsImport, meta: { right: RIGHT_IMPORT_EXPORT_TAGS } },
      { path: '/admin/tags/add', name: 'tag.add', component: TagEdit },
      { path: '/admin/tags/edit/:id', name: 'tag.edit', component: TagEdit },

      { path: '/admin/tagattributes', name: 'admin.tagattributes', component: RefTagAttributes },
      { path: '/admin/dataclassifications/add', name: 'admin.dataclassifications.add', component: RefDataClassificationEdit, meta: { right: RIGHT_EDIT_DATA_CLASSIFICATIONS } },
      { path: '/admin/dataclassifications/edit/:id', name: 'admin.dataclassifications.edit', component: RefDataClassificationEdit, meta: { right: RIGHT_EDIT_DATA_CLASSIFICATIONS } },

      { path: '/admin/capabilities', name: 'capabilities', component: Capabilities },
      { path: '/admin/capabilities/add', name: 'capability.add', component: CapabilityEdit },
      { path: '/admin/capabilities/edit/:id', name: 'capability.edit', component: CapabilityEdit },


      { path: '/admin/import', name: 'import', component: Import, meta: { rights: [RIGHT_IMPORT_DATA] } },

      { path: '/admin/factors', name: 'factors', component: Factors, meta: { rights: [RIGHT_EDIT_BUSINESS_FACTORS, RIGHT_EDIT_TECHNOLOGY_FACTORS] } },

      { path: '/setup', name: 'setup.account', component: Setup },
      { path: '/setup/subscription', name: 'setup.subscription', component: SetupSubscription },
  
      // { path: '/admin/settings', name: 'admin.settings', component: AdminSettings },
      { path: '/admin/account', name: 'admin.account', component: Admin },
      { path: '/admin/account/domains', name: 'admin.account.domains', component: AdminAccountDomains },
      { path: '/admin/account/resetdata', name: 'admin.account.resetdata', component: AdminAccountResetData },

      { path: '/admin/subscription', name: 'admin.subscription', component: SubscriptionAdmin },
      { path: '/admin/subscription/trial', name: 'admin.trial', component: SubscriptionChangeTrial, meta: { sysAdminRequired: true } },
      { path: '/admin/subscription/demo', name: 'admin.demo', component: SubscriptionChangeDemo, meta: { sysAdminRequired: true } },
      { path: '/admin/subscription/plan', name: 'admin.changeSubscription', component: SubscriptionChangePlan },
      { path: '/admin/subscription/changepayment', name: 'admin.changePaymentMethod', component: SubscriptionChangePaymentMethod },
      
      { path: '/admin/pay', name: 'admin.pay', component: PayTest },

      { path: '/admin/workspaces', name: 'admin.workspaces', component: Workspaces, meta: { nonWorkspaceRoute: true  } },
      { path: '/admin/workspaces/add', name: 'admin.workspaces.add', component: CreateWorkspace, meta: { nonWorkspaceRoute: true  } },
      { path: '/admin/workspaces/provider/add', name: 'admin.workspaces.provider.add', component: CreateProviderWorkspace, meta: { nonWorkspaceRoute: true  } },
      { path: '/admin/workspaces/sub/add', name: 'admin.workspaces.child.add', component: CreateChildWorkspace, meta: { nonWorkspaceRoute: true  } },

      { path: '/admin/invite/:contactId', name: 'admin.inviteContact', component: InviteUser, meta: { right: RIGHT_INVITE_USER } },
      { path: '/admin/invite', name: 'admin.inviteUser', component: InviteUser, meta: { right: RIGHT_INVITE_USER } },
      { path: '/admin/editprofile/:id', name: 'admin.editUserProfile', component: EditUserProfile },
      { path: '/admin/profile', name: 'admin.editPersonalProfile', component: EditUserProfile },
      { path: '/admin/verifyemail', name: 'admin.verifyEmail', component: ResendEmailVerifyMessage },
      
      { path: '/admin/programs', name: 'admin.programs', component: AdminPrograms },
      { path: '/admin/portfolios', name: 'admin.portfolios', component: AdminPortfolios },
      
      { path: '/admin/applicationsets', name: 'admin.applicationsets', component: RefApplicationSets },
      { path: '/admin/applicationstates/add', name: 'admin.applicationstates.add', component: RefApplicationStateEdit, meta: { right: RIGHT_EDIT_APPLICATION_STATES } },
      { path: '/admin/applicationstates/edit/:id', name: 'admin.applicationstates.edit', component: RefApplicationStateEdit, meta: { right: RIGHT_EDIT_APPLICATION_STATES } },
      { path: '/admin/applicationtypes/add', name: 'admin.applicationtypes.add', component: RefApplicationTypeEdit, meta: { right: RIGHT_EDIT_APPLICATION_TYPES } },
      { path: '/admin/applicationtypes/edit/:id', name: 'admin.applicationtypes.edit', component: RefApplicationTypeEdit, meta: { right: RIGHT_EDIT_APPLICATION_TYPES } },
      { path: '/admin/technologytypes/add', name: 'admin.technologytypes.add', component: RefTechnologyTypeEdit, meta: { right: RIGHT_EDIT_TECHNOLOGY_TYPES } },
      { path: '/admin/technologytypes/edit/:id', name: 'admin.technologytypes.edit', component: RefTechnologyTypeEdit, meta: { right: RIGHT_EDIT_TECHNOLOGY_TYPES } },

      { path: '/admin/ideasets', name: 'admin.ideasets', component: RefIdeaSets },
      { path: '/admin/ideastages/add', name: 'admin.ideastatuses.add', component: RefIdeaStatusEdit, meta: { right: RIGHT_EDIT_IDEA_STATUSES } },
      { path: '/admin/ideastages/edit/:id', name: 'admin.ideastatuses.edit', component: RefIdeaStatusEdit, meta: { right: RIGHT_EDIT_IDEA_STATUSES } },
      { path: '/admin/ideatypes/add', name: 'admin.ideatypes.add', component: RefIdeaTypeEdit, meta: { right: RIGHT_EDIT_IDEA_TYPES } },
      { path: '/admin/ideatypes/edit/:id', name: 'admin.ideatypes.edit', component: RefIdeaTypeEdit, meta: { right: RIGHT_EDIT_IDEA_TYPES } },

      { path: '/admin/itservicesets', name: 'admin.itservicesets', component: RefITServiceSets },
      { path: '/admin/integrationtypes/add', name: 'admin.integrationtypes.add', component: RefIntegrationTypeEdit, meta: { right: RIGHT_EDIT_INTEGRATION_TYPES } },
      { path: '/admin/integrationtypes/edit/:id', name: 'admin.integrationtypes.edit', component: RefIntegrationTypeEdit, meta: { right: RIGHT_EDIT_INTEGRATION_TYPES } },
      { path: '/admin/classifications/add', name: 'admin.classifications.add', component: RefClassificationEdit, meta: { right: RIGHT_EDIT_CLASSIFICATIONS } },
      { path: '/admin/classifications/edit/:id', name: 'admin.classifications.edit', component: RefClassificationEdit, meta: { right: RIGHT_EDIT_CLASSIFICATIONS } },
      { path: '/admin/computingtypes/add', name: 'admin.computingtypes.add', component: RefComputingTypeEdit, meta: { right: RIGHT_EDIT_COMPUTING_TYPES } },
      { path: '/admin/computingtypes/edit/:id', name: 'admin.computingtypes.edit', component: RefComputingTypeEdit, meta: { right: RIGHT_EDIT_COMPUTING_TYPES } },
      { path: '/admin/platforms/add', name: 'admin.platforms.add', component: RefPlatformEdit, meta: { right: RIGHT_EDIT_PLATFORMS } },
      { path: '/admin/platforms/edit/:id', name: 'admin.platforms.edit', component: RefPlatformEdit, meta: { right: RIGHT_EDIT_PLATFORMS } },

      { path: '/admin/projectsets', name: 'admin.projectsets', component: RefProjectSets },
      { path: '/admin/projectsizes/add', name: 'admin.projectsizes.add', component: RefProjectSizeEdit, meta: { right: RIGHT_EDIT_PROJECT_SIZES } },
      { path: '/admin/projectsizes/edit/:id', name: 'admin.projectsizes.edit', component: RefProjectSizeEdit, meta: { right: RIGHT_EDIT_PROJECT_SIZES } },
      { path: '/admin/projecttypes/add', name: 'admin.projecttypes.add', component: RefProjectTypeEdit, meta: { right: RIGHT_EDIT_PROJECT_TYPES } },
      { path: '/admin/projecttypes/edit/:id', name: 'admin.projecttypes.edit', component: RefProjectTypeEdit, meta: { right: RIGHT_EDIT_PROJECT_TYPES } },
      { path: '/admin/projectstages/add', name: 'admin.projectstages.add', component: RefProjectStageEdit, meta: { right: RIGHT_EDIT_PROJECT_STAGES } },
      { path: '/admin/projectstages/edit/:id', name: 'admin.projectstages.edit', component: RefProjectStageEdit, meta: { right: RIGHT_EDIT_PROJECT_STAGES } },
      { path: '/admin/projectstatuses/add', name: 'admin.projectstatuses.add', component: RefProjectStatusEdit, meta: { right: RIGHT_EDIT_PROJECT_STATUSES } },
      { path: '/admin/projectstatuses/edit/:id', name: 'admin.projectstatuses.edit', component: RefProjectStatusEdit, meta: { right: RIGHT_EDIT_PROJECT_STATUSES } },

      { path: '/admin/programattributes', name: 'admin.programattributes', component: RefProgramAttributes },
      { path: '/admin/drivers/add', name: 'admin.drivers.add', component: RefDriverEdit, meta: { right: RIGHT_EDIT_DRIVERS } },
      { path: '/admin/drivers/edit/:id', name: 'admin.drivers.edit', component: RefDriverEdit, meta: { right: RIGHT_EDIT_DRIVERS } },

      // use the delete right for these because regular users can edit the capability sets through the main capaibilities screen
      { path: '/admin/processsets', name: 'admin.processsets', component: AdminProcessSets, meta: { right: RIGHT_DELETE_CAPABILITY_SETS } },
    //   { path: '/admin/capabilities/add', name: 'admin.processsets.add', component: CapabilitySetEdit, meta: { right: RIGHT_DELETE_CAPABILITY_SETS } },
      { path: '/admin/processsets/edit/:id', name: 'admin.processsets.edit', component: AdminProcessSetEdit, meta: { right: RIGHT_DELETE_CAPABILITY_SETS } },

      { path: '/admin/backups', name: 'admin.backups', component: Backups },
      { path: '/admin/backups/:id', name: 'admin.backups.details', component: BackupDetails },

      { path: '/admin/suppliers', name: 'admin.suppliers', component: Suppliers },
      { path: '/admin/suppliers/add', name: 'admin.supplier.add', component: SupplierEdit, meta: { right: RIGHT_EDIT_SUPPLIERS } },
      { path: '/admin/suppliers/edit/:id', name: 'admin.supplier.edit', component: SupplierEdit, meta: { right: RIGHT_EDIT_SUPPLIERS } },

      { path: '/admin/links', name: 'admin.links', component: Links },
      { path: '/admin/links/add', name: 'admin.link.add', component: LinkEdit, meta: { right: RIGHT_EDIT_LINKS } },
      { path: '/admin/links/edit/:id', name: 'admin.link.edit', component: LinkEdit, meta: { right: RIGHT_EDIT_LINKS } },

      // system admin
      { path: '/admin/system/accounts', name: 'system.admin.accounts', component: SystemAdminAccounts, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/billing', name: 'system.admin.billing', component: SystemAdminBilling, meta: { sysAdminRequired: true } },

      { path: '/admin/system/abandonedregistrations', name: 'system.admin.abandonedregistrations', component: SystemAdminAbandonedRegistrations, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },

      { path: '/admin/system/billing/provider', name: 'system.admin.billing.certifiedprovider.edit', component: SystemAdminBillingCertifiedProviderEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/billingday', name: 'system.admin.billing.billingday.edit', component: SystemAdminBillingBillingDayEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/billingmethod', name: 'system.admin.billing.billingmethod.edit', component: SystemAdminBillingBillingMethodEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/billchildaccounts', name: 'system.admin.billing.billchildaccounts.edit', component: SystemAdminBillingBillChildAccountsEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/providermargin', name: 'system.admin.billing.providermargin.edit', component: SystemAdminBillingProviderMarginEdit, meta: { sysAdminRequired: true } },
      

      { path: '/admin/system/billing/products/add', name: 'system.admin.billing.product.add', component: SystemAdminBillingProductEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/products/edit/:id', name: 'system.admin.billing.product.edit', component: SystemAdminBillingProductEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/products/:id', name: 'system.admin.billing.product.details', component: SystemAdminBillingProductDetails, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/products/:accountProductId/coupons/add', name: 'system.admin.billing.product.coupon.add', component: SystemAdminBillingProductCouponEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/products/:accountProductId/coupons/edit/:id', name: 'system.admin.billing.product.coupon.edit', component: SystemAdminBillingProductCouponEdit, meta: { sysAdminRequired: true } },

      { path: '/admin/system/billing/payments/add', name: 'system.admin.billing.payment.add', component: SystemAdminBillingPaymentEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/payments/edit/:id', name: 'system.admin.billing.payment.edit', component: SystemAdminBillingPaymentEdit, meta: { sysAdminRequired: true } },

      { path: '/admin/system/billing/adjustments/add', name: 'system.admin.billing.adjustment.add', component: SystemAdminBillingAdjustmentEdit, meta: { sysAdminRequired: true } },
      { path: '/admin/system/billing/adjustments/edit/:id', name: 'system.admin.billing.adjustment.edit', component: SystemAdminBillingAdjustmentEdit, meta: { sysAdminRequired: true } },

      { path: '/admin/system/coupons', name: 'system.admin.coupons', component: SystemAdminCoupons, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/coupons/add', name: 'system.admin.coupon.add', component: SystemAdminCouponEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/coupons/edit/:id', name: 'system.admin.coupon.edit', component: SystemAdminCouponEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },

      { path: '/admin/system/products', name: 'system.admin.products', component: SystemAdminProducts, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/products/add', name: 'system.admin.product.add', component: SystemAdminProductEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/products/edit/:id', name: 'system.admin.product.edit', component: SystemAdminProductEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/products/:id', name: 'system.admin.product.details', component: SystemAdminProductDetails, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/products/:productId/rates/add', name: 'system.admin.product.rate.add', component: SystemAdminProductRateEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/products/:productId/rates/edit/:id', name: 'system.admin.product.rate.edit', component: SystemAdminProductRateEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      
      { path: '/admin/system/copyaccount', name: 'system.admin.copyaccount', component: SystemAdminCopyAccount, meta: { sysAdminRequired: true } },
      { path: '/admin/system/analytics/namespaces', name: 'system.admin.analytics.namespaces', component: SystemAdminAnalyticsNamespaces, meta: { sysAdminRequired: true, nonWorkspaceRoute: true } },
      { path: '/admin/system/analytics/namespaces/:id', name: 'system.admin.analytics.namespaces.details', component: SystemAdminAnalyticsNamespaceDetails, meta: { sysAdminRequired: true, nonWorkspaceRoute: true } },
      
      { path: '/admin/system/ai/prompts', name: 'system.admin.ai.prompts', component: SystemAdminAIPrompts, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/ai/prompts/add', name: 'system.admin.ai.prompts.add', component: SystemAdminAIPromptEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/ai/prompts/:id', name: 'system.admin.ai.prompts.edit', component: SystemAdminAIPromptEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },

      { path: '/admin/system/links', name: 'system.admin.links', component: SystemAdminLinks, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/links/add', name: 'system.admin.link.add', component: SystemAdminLinkEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },
      { path: '/admin/system/links/edit/:id', name: 'system.admin.link.edit', component: SystemAdminLinkEdit, meta: { sysAdminRequired: true, nonWorkspaceRoute: true  } },

    ]
}

let printPages = {
  path: '/print', component: PrintLayout, redirect: '/print/overview',
  children: [
    { path: '/print/overview', name: 'overview.print', component: OverviewPrint },

    // { path: '/print/portfolios/:id/costs', name: 'portfolio.scores.print', component: PortfolioScores },
    { path: '/print/portfolios/:id/applications', name: 'portfolio.application.scores.print', component: PortfolioApplicationScoresPrint },
    { path: '/print/portfolios/:id', name: 'portfolio.print', component: PortfolioDetailsPrint },
    
    { path: '/print/projects', name: 'projects.print', component: ProjectsPrint },
    { path: '/print/projects/:id', name: 'project.print', component: ProjectEditPrint },

    { path: '/print/ideas', name: 'ideas.print', component: IdeasPrint },


    { path: '/print/programs', name: 'programs.print', component: ProgramsPrint },
  ]
}

// let adminPages = {
//   path: '/admin', component: AdminLayout, redirect: '/admin/settings',
//   children: [
//     { path: '/admin/settings', name: 'admin.settings', component: AdminSettings },
//     { path: '/admin/account', name: 'admin.account', component: Admin },
//   ]
// }


let loginPage = { path: '/login', name: 'login', component: Login, meta: { allowAnonymous: true } }
let logoutPage = { path: '/logout', name: 'logout', component: Logout, meta: { allowAnonymous: false } }

let trialPage = { path: '/trial', name: 'trial', component: Trial, meta: { allowAnonymous: true } }

let registerPage = { path: '/register', name: 'register', component: Register, meta: { allowAnonymous: true } }
let registerBetaPage = { path: '/register-beta', name: 'register.beta', component: RegisterBeta, meta: { allowAnonymous: true } }

let forgotPasswordPage = { path: '/forgotpassword', name: 'forgotpassword', component: ForgotPassword, meta: { allowAnonymous: true } }
let resetPasswordPage = { path: '/resetpassword', name: 'resetpassword', component: ResetPassword, meta: { allowAnonymous: true } }

let verifyEmailNeededPage = { path: '/emailverification', name: 'verify.email.needed', component: VerifyEmailNeeded, meta: { allowAnonymous: true } }
let verifyEmailPage = { path: '/verifyemail', name: 'verify.email', component: VerifyEmail, meta: { allowAnonymous: true } }

let termsPage = { path: '/terms', name: 'terms', component: Terms, meta: { allowAnonymous: true } }
let privacyPage = { path: '/privacy', name: 'privacy', component: Privacy, meta: { allowAnonymous: true } }


const routes = [
  sitePages,
  printPages,
  // adminPages,

  loginPage,
  logoutPage,
  trialPage,
  registerPage,
  registerBetaPage,
  forgotPasswordPage,
  resetPasswordPage,
  verifyEmailNeededPage,
  verifyEmailPage,

  termsPage,
  privacyPage,

  {path: '*', component: NotFound}
];

export default routes
