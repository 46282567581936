// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refApplicationTypeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefApplicationType
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refApplicationTypes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refApplicationTypes/${id}`).then(handleResponse);
}

function add(refApplicationType) {
    return axios.post('/refApplicationTypes', refApplicationType).then(handleResponse);
}

function update(refApplicationType) {
    return axios.put(`/refApplicationTypes/${refApplicationType.refApplicationTypeId}`, refApplicationType).then(handleResponse);
}

function deleteRefApplicationType(refApplicationType) {
    return axios.delete(`/refApplicationTypes/${refApplicationType.refApplicationTypeId}`).then(handleResponse);
}
