<template>
  <drop-down
    icon=""
    tag="li"
    position="right"
    direction="none"
    class="nav-item btn-rotate dropdown"
    v-if="isSystemAdministrator"
  >
    <a
      slot="title"
      slot-scope="{ isOpen }"
      class="nav-link dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      :aria-expanded="isOpen"
    >
      <!-- <i class="fa fa-gear"></i> -->
      <p>
        <span class="d-md-block">Control Panel&nbsp;</span>
      </p>
    </a>

    <router-link :to="{name: 'system.admin.accounts'}" class="dropdown-item">Accounts</router-link>
    <router-link :to="{name: 'system.admin.abandonedregistrations'}" class="dropdown-item">Abandoned Registrations</router-link>
    <hr class="mt-0 mb-2" />
    <router-link :to="{name: 'system.admin.billing'}" class="dropdown-item">Billing</router-link>
    <router-link :to="{name: 'system.admin.copyaccount'}" class="dropdown-item">Copy Account</router-link>
    <hr class="mt-0 mb-2" />
    <router-link :to="{name: 'system.admin.ai.prompts'}" class="dropdown-item">AI Prompts</router-link>
    <hr class="mt-0 mb-2" />
    <router-link :to="{name: 'system.admin.analytics.namespaces'}" class="dropdown-item">Analytics Namespaces</router-link>
    <router-link :to="{name: 'system.admin.links'}" class="dropdown-item">Documents</router-link>
    <hr class="mt-0 mb-2" />
    <router-link :to="{name: 'system.admin.coupons'}" class="dropdown-item">Coupons</router-link>
    <router-link :to="{name: 'system.admin.products'}" class="dropdown-item">Products</router-link>

    <!-- <router-link
      v-for="(link, index) in authorizedLinks"
      :key="link.name + index"
      :to="link.path"
      :href="link.path"
      class="dropdown-item"
      >{{ link.name }}</router-link> -->
  </drop-down>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    // links: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  components: {

  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("authentication", [
        "hasRight", "isSystemAdministrator"
    ]),
    // authorizedLinks: function() {
    //     return _.filter(this.links, link => {
    //         if (!link.right) {
    //             return true;
    //         } else {
    //             return this.hasRight(link.right);
    //         }
    //     });
    // }
  },
  methods: {

  },
};
</script>

