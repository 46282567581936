<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <!-- <nav class="footer-nav">
          <ul>
          </ul>
        </nav> -->
        <div class="col-12 text-end">
          <div class="copyright pull-right text-white">
              &copy; GetInSync
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer'
  }
</script>
<style>
</style>
