// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const analyticsService = {
    getDashboards,
    getDashboardEmbedUrl,
    getNamespaces,
    getNamespaceById
};

function getDashboards() {
    return axios.get('/analytics/dashboards').then(handleResponse);
}

function getDashboardEmbedUrl(dashboardId) {
    return axios.get(`/analytics/embedurl/${dashboardId}`).then(handleResponse);
}

function getNamespaces() {
    return axios.get(`/analytics/namespaces`).then(handleResponse);
}

function getNamespaceById(id) {
    return axios.get(`/analytics/namespaces/${id}`).then(handleResponse);
}