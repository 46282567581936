// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const programService = {
    getAll,
    getById,
    add,
    update,
    deleteProgram
};

function getAll() {
    // return axios.get(`/programs/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/programs/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/programs/${id}`).then(handleResponse);
}

function add(program) {
    return axios.post('/programs', program).then(handleResponse);
}

function update(program) {
    return axios.put(`/programs/${program.programId}`, program).then(handleResponse);
}

function deleteProgram(program) {
    return axios.delete(`/programs/${program.programId}`).then(handleResponse);
}
