export const AI_APPLICATION_DESCRIPTION = "APPLICATION_DESCRIPTION";
export const AI_APPLICATION_USE_CASE = "APPLICATION_USE_CASE";

export const AI_IT_SERVICE_DESCRIPTION = "IT_SERVICE_DESCRIPTION";

export const AI_IDEA_DEFINITION = "IDEA_DEFINITION";
export const AI_IDEA_OBJECTIVE_PURPOSE = "IDEA_OBJECTIVE_PURPOSE";
export const AI_IDEA_OBJECTIVE_SIGNIFICANT = "IDEA_OBJECTIVE_SIGNIFICANT";
export const AI_IDEA_OBJECTIVE_GOAL = "IDEA_OBJECTIVE_GOAL";
export const AI_IDEA_OBJECTIVE_OUTCOME = "IDEA_OBJECTIVE_OUTCOME";
export const AI_IDEA_VALUE_DRIVERS = "IDEA_VALUE_DRIVERS";

export const AI_PROJECT_DEFINITION = "PROJECT_DEFINITION";
export const AI_PROJECT_OBJECTIVE_PURPOSE = "PROJECT_OBJECTIVE_PURPOSE";
export const AI_PROJECT_OBJECTIVE_SIGNIFICANT = "PROJECT_OBJECTIVE_SIGNIFICANT";
export const AI_PROJECT_OBJECTIVE_GOAL = "PROJECT_OBJECTIVE_GOAL";
export const AI_PROJECT_OBJECTIVE_OUTCOME = "PROJECT_OBJECTIVE_OUTCOME";
export const AI_PROJECT_VALUE_DRIVERS = "PROJECT_VALUE_DRIVERS";

