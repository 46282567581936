import _ from 'lodash';
import { userService } from '@/services';
import {
    LOGIN,
    LOGOUT,
    REGISTER_SET_EMAIL,
    REGISTER,
    CREATE_SANDBOX,
    SWITCH_ACCOUNT,
    JOIN_ACCOUNT,
    IMPERSONATE,
    END_IMPERSONATION,
    REFRESH_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    CHANGE_PASSWORD,
    CHANGE_MY_PASSWORD,
    SEND_EMAIL_VERIFY,
    VERIFY_EMAIL
  } from "./actions.type";
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    PROCESS_LOGOUT,
    SWITCH_ACCOUNT_REQUEST,
    SWITCH_ACCOUNT_FAILURE,
    SWITCH_ACCOUNT_SUCCESS,
    JOIN_ACCOUNT_REQUEST,
    JOIN_ACCOUNT_FAILURE,
    JOIN_ACCOUNT_SUCCESS,
    IMPERSONATE_REQUEST,
    IMPERSONATE_FAILURE,
    IMPERSONATE_SUCCESS,
    END_IMPERSONATION_REQUEST,
    END_IMPERSONATION_FAILURE,
    END_IMPERSONATION_SUCCESS,
    REFRESH_USER_REQUEST,
    REFRESH_USER_FAILURE,
    REFRESH_USER_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    CHANGE_MY_PASSWORD_REQUEST,
    CHANGE_MY_PASSWORD_FAILURE,
    CHANGE_MY_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    SEND_EMAIL_VERIFY_REQUEST,
    SEND_EMAIL_VERIFY_FAILURE,
    SEND_EMAIL_VERIFY_SUCCESS,
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_FAILURE,
    VERIFY_EMAIL_SUCCESS,
    CREATE_TRIAL_REQUEST,
    CREATE_TRIAL_FAILURE,
    CREATE_TRIAL_SUCCESS,
    REGISTER_SET_EMAIL_REQUEST,
    REGISTER_SET_EMAIL_FAILURE,
    REGISTER_SET_EMAIL_SUCCESS,
    REGISTER_REQUEST,
    REGISTER_FAILURE,
    REGISTER_SUCCESS
  } from "./mutations.type";
import {
    SYSTEM_ADMINISTRATOR,
    CERTIFIED_PROVIDER_ADMINISTRATOR,
    RIGHT_FULL_PORTFOLIO_ACCESS,
    RIGHT_EDIT_USER,
    RIGHT_EDIT_ACCOUNT
} from '@/helpers';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loading: false, loggedIn: true, loggingIn: false, failed: false, error: null }, user }
    : { status: { loading: false, loggedIn: false, loggingIn: false, failed: false, error: null}, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    getters: {
        isAuthenticated: state => !!state.user,
        loggedInUser: state => state.user,
        authToken: (state, getters) => {
            if (getters.isAuthenticated) {
                return state.user.token;
            }
            return '';
        },
        isInRole: (state, getters) => (role) => {
            var result = false;
            if (getters.isAuthenticated && state.user.roles)  {
                var matchingRole = state.user.roles.find(r => r.roleName == role);
                result = !!matchingRole;
            }
            return result;
        },
        hasRight: (state, getters, rootState, rootGetters) => (right) => {
            var result = false;

            const currentAccount = rootState.account.currentAccount;
            const trialExpired = currentAccount ? currentAccount.isTrialExpired : false;

            if (getters.isAuthenticated) {
                result = (getters.isSystemAdministrator);
                if (!result && trialExpired && right != RIGHT_EDIT_ACCOUNT) {
                    // if trial is expired, the only right we return is to edit the account
                    result = false;
                } 
                else if (!result && state.user.roles) {
                    //var matchingRole = state.user.roles.find(r => r.portfolioId == null && r.rights && r.rights.indexOf(right) > -1);
                    var matchingRole = state.user.roles.find(r => r.portfolioId == null && r.rights && r.rights.indexOf(right) > -1);
                    result = !!matchingRole;
                }
            }
            return result;
        },
        hasPortfolioRight: (state, getters, rootState, rootGetters) => (portfolioId, right) => {
            var result = false;

            const currentAccount = rootState.account.currentAccount;
            const trialExpired = currentAccount ? currentAccount.isTrialExpired : false;

            if (getters.isAuthenticated) {
                if (!result && trialExpired && right != RIGHT_EDIT_ACCOUNT) {
                    // if trial is expired, the only right we return is to edit the account, which isn't portfolio
                    result = getters.isSystemAdministrator;
                } else {
                    result = (getters.isSystemAdministrator 
                                || getters.hasRight(RIGHT_FULL_PORTFOLIO_ACCESS) 
                                || (state.user.roles && !!state.user.roles.find(r => r.portfolioId == portfolioId && r.rights && r.rights.indexOf(right) > -1)));
                }
            }
            return result;
        },
        hasRightOrPortfolioRight: (state, getters, rootState, rootGetters) => (right) => {
            var result = false;

            const currentAccount = rootState.account.currentAccount;
            const trialExpired = currentAccount ? currentAccount.isTrialExpired : false;

            if (getters.isAuthenticated) {
                result = (getters.isSystemAdministrator);
                if (!result && trialExpired && right != RIGHT_EDIT_ACCOUNT) {
                    // if trial is expired, the only right we return is to edit the account
                    result = false;
                } 
                else if (!result && state.user.roles) {
                    //var matchingRole = state.user.roles.find(r => r.portfolioId == null && r.rights && r.rights.indexOf(right) > -1);
                    var matchingRole = state.user.roles.find(r => r.rights && r.rights.indexOf(right) > -1);
                    result = !!matchingRole;
                }
            }
            return result;
        },
        canEditUser: (state, getters) => (userId) => {
            var result = false;
            if (getters.isAuthenticated) {
                result = (getters.isSystemAdministrator || state.user.userId == userId || getters.hasRight(RIGHT_EDIT_USER));
            }
            return result;
        },        
        // hasAnyPortfolioRight: (state, getters) => (right) => {
        //     var result = false;
        //     if (getters.isAuthenticated) {
        //         result = (getters.isSystemAdministrator 
        //                     || getters.hasRight(RIGHT_FULL_PORTFOLIO_ACCESS) 
        //                     || (state.user.roles && !!state.user.roles.find(r => r.portfolioId != null && r.rights && r.rights.indexOf(right) > -1)));
        //     }
        //     return result;
        // },
        isSystemAdministrator: (state, getters) => {
            if (getters.isAuthenticated) {
                return getters.isInRole(SYSTEM_ADMINISTRATOR);
            }
            return false;
        },
        isCertifiedProviderAdministrator: (state, getters) => {
            if (getters.isAuthenticated) {
                return getters.isSystemAdministrator || getters.isInRole(CERTIFIED_PROVIDER_ADMINISTRATOR);
            }
            return false;
        },
        errorMessage: (state) => {
            if (state.status.error &&
                    state.status.error.response &&
                    state.status.error.response.data) {
                        return state.status.error.response.data.message;
                    }
            return '';
        },
        isImpersonating: state => state.user && state.user.userId != state.user.originalUserId,
    },
    actions: {
        [LOGIN]({ commit }, { email, password, rememberMe }) {
            return new Promise((resolve, reject) => {
                commit(LOGIN_REQUEST, { email });
                userService.login(email, password, rememberMe)
                    .then(
                        user => {
                            commit(LOGIN_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(LOGIN_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [REGISTER_SET_EMAIL]({ commit }, email) {
            return new Promise((resolve, reject) => {
                commit(REGISTER_SET_EMAIL_REQUEST, { email });

                userService.registerEmail(email)
                    .then(
                        response => {
                            commit(REGISTER_SET_EMAIL_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(REGISTER_SET_EMAIL_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [REGISTER]({ commit }, { firstName, lastName, position, company, refCompanyEmployeeId, email, password, inviteToken, verifyToken, sampleData, agreeTerms }) {
            return new Promise((resolve, reject) => {
                commit(REGISTER_REQUEST, { email });

                userService.register(firstName, lastName, position, company, refCompanyEmployeeId, email, password, inviteToken, verifyToken, sampleData, agreeTerms)
                    .then(
                        user => {
                            commit(REGISTER_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(REGISTER_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [CREATE_SANDBOX]({ commit }, { firstName, lastName, position, company, email, refCompanyEmployeeId }) {
            return new Promise((resolve, reject) => {
                commit(CREATE_TRIAL_REQUEST, { email });

                userService.createSandbox(firstName, lastName, position, company, email, refCompanyEmployeeId)
                    .then(
                        userTrial => {
                            commit(CREATE_TRIAL_SUCCESS, userTrial);
                            resolve(userTrial);
                        },
                        error => {
                            commit(CREATE_TRIAL_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [SWITCH_ACCOUNT]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(SWITCH_ACCOUNT_REQUEST);

                userService.switchAccount(accountId)
                    .then(
                        user => {
                            commit(SWITCH_ACCOUNT_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(SWITCH_ACCOUNT_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [JOIN_ACCOUNT]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(JOIN_ACCOUNT_REQUEST);

                userService.joinAccount(accountId)
                    .then(
                        user => {
                            commit(JOIN_ACCOUNT_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(JOIN_ACCOUNT_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [IMPERSONATE]({ commit }, userId) {
            return new Promise((resolve, reject) => {
                commit(IMPERSONATE_REQUEST);

                userService.impersonateUser(userId)
                    .then(
                        user => {
                            commit(IMPERSONATE_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(IMPERSONATE_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [END_IMPERSONATION]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(END_IMPERSONATION_REQUEST);

                userService.endImpersonation()
                    .then(
                        user => {
                            commit(END_IMPERSONATION_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(END_IMPERSONATION_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [REFRESH_USER]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(REFRESH_USER_REQUEST);

                userService.refreshUser()
                    .then(
                        user => {
                            commit(REFRESH_USER_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(REFRESH_USER_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [FORGOT_PASSWORD]({ commit }, emailAddress) {
            return new Promise((resolve, reject) => {
                commit(FORGOT_PASSWORD_REQUEST);

                userService.forgotPassword(emailAddress)
                    .then(
                        response => {
                            commit(FORGOT_PASSWORD_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(FORGOT_PASSWORD_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [RESET_PASSWORD]({ commit }, { emailAddress, password, verification }) {
            return new Promise((resolve, reject) => {
                commit(RESET_PASSWORD_REQUEST);

                userService.resetPassword(emailAddress, password, verification)
                    .then(
                        response => {
                            commit(RESET_PASSWORD_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(RESET_PASSWORD_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [CHANGE_MY_PASSWORD]({ commit }, { oldPassword, newPassword }) {
            return new Promise((resolve, reject) => {
                commit(CHANGE_MY_PASSWORD_REQUEST);

                userService.changeMyPassword(oldPassword, newPassword)
                    .then(
                        response => {
                            commit(CHANGE_MY_PASSWORD_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            // debugger;
                            commit(CHANGE_MY_PASSWORD_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [CHANGE_PASSWORD]({ commit }, { userId, newPassword }) {
            return new Promise((resolve, reject) => {
                commit(CHANGE_PASSWORD_REQUEST);

                userService.changePassword(userId, newPassword)
                    .then(
                        response => {
                            commit(CHANGE_PASSWORD_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            // debugger;
                            commit(CHANGE_PASSWORD_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [SEND_EMAIL_VERIFY]({ commit }, emailAddress) {
            return new Promise((resolve, reject) => {
                commit(SEND_EMAIL_VERIFY_REQUEST);

                userService.sendVerificationEmail(emailAddress)
                    .then(
                        response => {
                            commit(SEND_EMAIL_VERIFY_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(SEND_EMAIL_VERIFY_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [VERIFY_EMAIL]({ commit }, { emailAddress, verification }) {
            return new Promise((resolve, reject) => {
                commit(VERIFY_EMAIL_REQUEST);

                userService.verifyEmail(emailAddress, verification)
                    .then(
                        response => {
                            commit(VERIFY_EMAIL_SUCCESS, response);
                            resolve(response);
                        },
                        error => {
                            commit(VERIFY_EMAIL_FAILURE, error);
                            reject(error);
                        }
                    );
            })
        },
        [LOGOUT]({ commit }) {
            return new Promise((resolve, reject) => {
                userService.logout();
                commit(PROCESS_LOGOUT);
                resolve();
            })
        }
    },
    mutations: {
        [LOGIN_REQUEST](state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        [LOGIN_SUCCESS](state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        [LOGIN_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.user = null;
        },
        [PROCESS_LOGOUT](state) {
            state.status = {};
            state.user = null;
        },
        [SWITCH_ACCOUNT_REQUEST](state) {
            state.status = { loading: true };
        },
        [SWITCH_ACCOUNT_SUCCESS](state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        [SWITCH_ACCOUNT_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [JOIN_ACCOUNT_REQUEST](state) {
            state.status = { loading: true };
        },
        [JOIN_ACCOUNT_SUCCESS](state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        [JOIN_ACCOUNT_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [IMPERSONATE_REQUEST](state) {
            state.status = { loading: true };
        },
        [IMPERSONATE_SUCCESS](state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        [IMPERSONATE_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [END_IMPERSONATION_REQUEST](state) {
            state.status = { loading: true };
        },
        [END_IMPERSONATION_SUCCESS](state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        [END_IMPERSONATION_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [REFRESH_USER_REQUEST](state) {
            state.status = { loading: true };
        },
        [REFRESH_USER_SUCCESS](state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        [REFRESH_USER_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [FORGOT_PASSWORD_REQUEST](state) {
            state.status = { loading: true };
        },
        [FORGOT_PASSWORD_SUCCESS](state, response) {
            state.status = { loading: false };
        },
        [FORGOT_PASSWORD_FAILURE](state, error) {
            state.status = { failed: true, error };
        },
        [RESET_PASSWORD_REQUEST](state) {
            state.status = { loading: true };
        },
        [RESET_PASSWORD_SUCCESS](state, user) {
            state.status = { loading: false };
            state.user = user;
        },
        [RESET_PASSWORD_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [CHANGE_MY_PASSWORD_REQUEST](state) {
            state.status = { loading: true };
        },
        [CHANGE_MY_PASSWORD_SUCCESS](state, user) {
            state.status = { loading: false };
            state.user = user;
        },
        [CHANGE_MY_PASSWORD_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [CHANGE_PASSWORD_REQUEST](state) {
            state.status = { loading: true };
        },
        [CHANGE_PASSWORD_SUCCESS](state, user) {
            state.status = { loading: false };
            // state.user = user;
        },
        [CHANGE_PASSWORD_FAILURE](state, error) {
            state.status = { failed: true, error };
            // state.user = null;
        },
        [SEND_EMAIL_VERIFY_REQUEST](state) {
            state.status = { loading: true };
        },
        [SEND_EMAIL_VERIFY_SUCCESS](state, response) {
            state.status = { loading: false };
        },
        [SEND_EMAIL_VERIFY_FAILURE](state, error) {
            state.status = { failed: true, error };
        },
        [VERIFY_EMAIL_REQUEST](state) {
            state.status = { loading: true };
        },
        [VERIFY_EMAIL_SUCCESS](state, response) {
            state.status = { loading: false };
        },
        [VERIFY_EMAIL_FAILURE](state, error) {
            state.status = { failed: true, error };
        },
        [CREATE_TRIAL_REQUEST](state) {
            state.status = { loading: true };
        },
        [CREATE_TRIAL_SUCCESS](state, response) {
            state.status = { loading: false };
        },
        [CREATE_TRIAL_FAILURE](state, error) {
            state.status = { failed: true, error };
        },
        [REGISTER_REQUEST](state) {
            state.status = { loading: true };
        },
        [REGISTER_SUCCESS](state, response) {
            state.status = { loading: false };
        },
        [REGISTER_FAILURE](state, error) {
            state.status = { failed: true, error };
        },
        [REGISTER_SET_EMAIL_REQUEST](state) {
            state.status = { loading: true };
        },
        [REGISTER_SET_EMAIL_SUCCESS](state, response) {
            state.status = { loading: false };
        },
        [REGISTER_SET_EMAIL_FAILURE](state, error) {
            state.status = { failed: true, error };
        },

    }
}
