// common
export const ADD = 'add';
export const UPDATE = 'update';
export const UPDATE_SECURITY = 'updateSecurity';
export const DELETE = 'deleteEntity';
export const GET_ALL = 'getAll';
export const GET_ACTIVE = 'getActive';
export const GET_SUMMARIES = 'getSummaries';
export const GET_BY_ID = 'getById';
export const GET = 'get';
export const GET_NEW = 'getNew';
export const GET_BY_PORTFOLIO_ID = 'getByPortfolioId';
export const GET_BY_ACCOUNT_ID = 'getByAccountId';

// authentication
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER_SET_EMAIL = 'setEmail';
export const REGISTER = 'register';
export const CREATE_SANDBOX = 'createSandbox';
export const SWITCH_ACCOUNT = 'switchAccount';
export const JOIN_ACCOUNT = 'joinAccount';
export const IMPERSONATE = 'impersonate';
export const END_IMPERSONATION = 'endImpersonation';
export const REFRESH_USER = 'refreshUser';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';
export const CHANGE_MY_PASSWORD = 'changeMyPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const SEND_EMAIL_VERIFY = 'sendEmailVerification';
export const VERIFY_EMAIL = 'verifyEmail';

// namespaced auth
export const AUTHENTICATION_NAMESPACE = 'authentication';
export const AUTHENTICATION_LOGIN = `${AUTHENTICATION_NAMESPACE}/${LOGIN}`;
export const AUTHENTICATION_LOGOUT = `${AUTHENTICATION_NAMESPACE}/${LOGOUT}`;
export const AUTHENTICATION_REGISTER_SET_EMAIL = `${AUTHENTICATION_NAMESPACE}/${REGISTER_SET_EMAIL}`;
export const AUTHENTICATION_REGISTER = `${AUTHENTICATION_NAMESPACE}/${REGISTER}`;
export const AUTHENTICATION_CREATE_SANDBOX = `${AUTHENTICATION_NAMESPACE}/${CREATE_SANDBOX}`;
export const AUTHENTICATION_SWITCH_ACCOUNT = `${AUTHENTICATION_NAMESPACE}/${SWITCH_ACCOUNT}`;
export const AUTHENTICATION_JOIN_ACCOUNT = `${AUTHENTICATION_NAMESPACE}/${JOIN_ACCOUNT}`;
export const AUTHENTICATION_IMPERSONATE = `${AUTHENTICATION_NAMESPACE}/${IMPERSONATE}`;
export const AUTHENTICATION_END_IMPERSONATION = `${AUTHENTICATION_NAMESPACE}/${END_IMPERSONATION}`;
export const AUTHENTICATION_REFRESH_USER = `${AUTHENTICATION_NAMESPACE}/${REFRESH_USER}`;
export const AUTHENTICATION_FORGOT_PASSWORD = `${AUTHENTICATION_NAMESPACE}/${FORGOT_PASSWORD}`;
export const AUTHENTICATION_RESET_PASSWORD = `${AUTHENTICATION_NAMESPACE}/${RESET_PASSWORD}`;
export const AUTHENTICATION_CHANGE_MY_PASSWORD = `${AUTHENTICATION_NAMESPACE}/${CHANGE_MY_PASSWORD}`;
export const AUTHENTICATION_CHANGE_PASSWORD = `${AUTHENTICATION_NAMESPACE}/${CHANGE_PASSWORD}`;
export const AUTHENTICATION_SEND_EMAIL_VERIFY = `${AUTHENTICATION_NAMESPACE}/${SEND_EMAIL_VERIFY}`;
export const AUTHENTICATION_VERIFY_EMAIL = `${AUTHENTICATION_NAMESPACE}/${VERIFY_EMAIL}`;

// namespaced account
export const GET_SUBSCRIPTION = 'getSubscription';
export const GET_RECENT = 'getRecent';
export const GET_REFERENCE_VALUES = 'getReferenceValues';
export const GET_FRAMEWORKS = 'getFrameworks';
export const GET_FLAT_FRAMEWORKS = 'getFlatFrameworks';
export const GET_MY_WORKSPACES = 'getMyWorkspaces';
export const INVITE_USER = 'inviteUser';
export const RESEND_INVITE = 'resendInvite';
export const CANCEL_INVITE = 'cancelInvite';
export const REMOVE_USER = 'removeUser';
export const UPDATE_DOMAINS = 'updateDomains';
export const UPDATE_TRIAL = 'updateTrial';
export const UPDATE_DEMO = 'updateDemo';
export const UPDATE_CERTIFIED_PROVIDER = 'updateCertifiedProvider';
export const UPDATE_BILLING_DAY = 'updateBillingDay';

export const SETUP_SUBSCRIPTION_PLAN = 'setupSubscriptionPlan';
export const SETUP_SUBSCRIPTION_ONBOARDING_INVOICE = 'setupSubscriptionOnboardingInvoice';
export const SETUP_SUBSCRIPTION_DISCOUNT = 'setupSubscriptionDiscount';
export const SETUP_SUBSCRIPTION_BILLING_DETAILS = 'setupSubscriptionBillingDetails';
export const SETUP_SUBSCRIPTION_PAYMENT = 'setupSubscriptionPayment';
export const SETUP_SUBSCRIPTION_COMPLETE = 'setupSubscriptionComplete';

export const SETUP_ACCOUNT = 'setupAccount';
export const SETUP_PORTFOLIO = 'setupPortfolio';
export const SETUP_APPLICATIONS = 'setupApplications';
export const SETUP_AREAS = 'setupAreas';
export const SETUP_CONTACTS = 'setupContacts';
export const SETUP_SKIP = 'setupSkip';

export const SWITCH_SUBSCRIPTION_PLAN = 'switchSubscriptionPlan';
export const SET_PAYMENT_METHOD = 'setPaymentMethod';

export const ACCOUNT_NAMESPACE = 'account';
export const ACCOUNT_GET_REFERENCE_VALUES = `${ACCOUNT_NAMESPACE}/${GET_REFERENCE_VALUES}`;
export const ACCOUNT_GET_FRAMEWORKS = `${ACCOUNT_NAMESPACE}/${GET_FRAMEWORKS}`;
export const ACCOUNT_GET_FLAT_FRAMEWORKS = `${ACCOUNT_NAMESPACE}/${GET_FLAT_FRAMEWORKS}`;
export const ACCOUNT_GET_ALL = `${ACCOUNT_NAMESPACE}/${GET_ALL}`;
export const ACCOUNT_GET_MY_WORKSPACES = `${ACCOUNT_NAMESPACE}/${GET_MY_WORKSPACES}`;
export const ACCOUNT_INVITE_USER = `${ACCOUNT_NAMESPACE}/${INVITE_USER}`;
export const ACCOUNT_RESEND_INVITE = `${ACCOUNT_NAMESPACE}/${RESEND_INVITE}`;
export const ACCOUNT_CANCEL_INVITE = `${ACCOUNT_NAMESPACE}/${CANCEL_INVITE}`;
export const ACCOUNT_REMOVE_USER = `${ACCOUNT_NAMESPACE}/${REMOVE_USER}`;
export const ACCOUNT_GET = `${ACCOUNT_NAMESPACE}/${GET}`;
export const ACCOUNT_GET_SUBSCRIPTION = `${ACCOUNT_NAMESPACE}/${GET_SUBSCRIPTION}`;
export const ACCOUNT_GET_RECENT = `${ACCOUNT_NAMESPACE}/${GET_RECENT}`;
export const ACCOUNT_UPDATE = `${ACCOUNT_NAMESPACE}/${UPDATE}`;
export const ACCOUNT_UPDATE_DOMAINS = `${ACCOUNT_NAMESPACE}/${UPDATE_DOMAINS}`;
export const ACCOUNT_UPDATE_TRIAL = `${ACCOUNT_NAMESPACE}/${UPDATE_TRIAL}`;
export const ACCOUNT_UPDATE_DEMO = `${ACCOUNT_NAMESPACE}/${UPDATE_DEMO}`;
export const ACCOUNT_UPDATE_CERTIFIED_PROVIDER = `${ACCOUNT_NAMESPACE}/${UPDATE_CERTIFIED_PROVIDER}`;
export const ACCOUNT_UPDATE_BILLING_DAY = `${ACCOUNT_NAMESPACE}/${UPDATE_BILLING_DAY}`;

export const ACCOUNT_SETUP_SUBSCRIPTION_PLAN = `${ACCOUNT_NAMESPACE}/${SETUP_SUBSCRIPTION_PLAN}`;
export const ACCOUNT_SETUP_SUBSCRIPTION_ONBOARDING_INVOICE = `${ACCOUNT_NAMESPACE}/${SETUP_SUBSCRIPTION_ONBOARDING_INVOICE}`;
export const ACCOUNT_SETUP_SUBSCRIPTION_DISCOUNT = `${ACCOUNT_NAMESPACE}/${SETUP_SUBSCRIPTION_DISCOUNT}`;
export const ACCOUNT_SETUP_SUBSCRIPTION_BILLING_DETAILS  = `${ACCOUNT_NAMESPACE}/${SETUP_SUBSCRIPTION_BILLING_DETAILS}`;
export const ACCOUNT_SETUP_SUBSCRIPTION_PAYMENT  = `${ACCOUNT_NAMESPACE}/${SETUP_SUBSCRIPTION_PAYMENT}`;
export const ACCOUNT_SETUP_SUBSCRIPTION_COMPLETE  = `${ACCOUNT_NAMESPACE}/${SETUP_SUBSCRIPTION_COMPLETE}`;

export const ACCOUNT_SETUP_ACCOUNT = `${ACCOUNT_NAMESPACE}/${SETUP_ACCOUNT}`;
export const ACCOUNT_SETUP_PORTFOLIO = `${ACCOUNT_NAMESPACE}/${SETUP_PORTFOLIO}`;
export const ACCOUNT_SETUP_APPLICATIONS = `${ACCOUNT_NAMESPACE}/${SETUP_APPLICATIONS}`;
export const ACCOUNT_SETUP_AREAS = `${ACCOUNT_NAMESPACE}/${SETUP_AREAS}`;
export const ACCOUNT_SETUP_CONTACTS = `${ACCOUNT_NAMESPACE}/${SETUP_CONTACTS}`;
export const ACCOUNT_SETUP_SKIP = `${ACCOUNT_NAMESPACE}/${SETUP_SKIP}`;

export const ACCOUNT_SWITCH_SUBSCRIPTION_PLAN = `${ACCOUNT_NAMESPACE}/${SWITCH_SUBSCRIPTION_PLAN}`;
export const ACCOUNT_SET_PAYMENT_METHOD = `${ACCOUNT_NAMESPACE}/${SET_PAYMENT_METHOD}`;


// products
export const GET_RATE_BY_ID = 'gatRateById';
export const ADD_RATE = 'addRate';
export const UPDATE_RATE = 'updateRate';
export const DELETE_RATE = 'deleteRate';

// namespaced products
export const PRODUCTS_NAMESPACE = 'products';
export const PRODUCTS_GET_ALL = `${PRODUCTS_NAMESPACE}/${GET_ALL}`;
export const PRODUCTS_GET_ACTIVE = `${PRODUCTS_NAMESPACE}/${GET_ACTIVE}`;
export const PRODUCTS_GET_BY_ID = `${PRODUCTS_NAMESPACE}/${GET_BY_ID}`;
export const PRODUCTS_ADD = `${PRODUCTS_NAMESPACE}/${ADD}`;
export const PRODUCTS_UPDATE = `${PRODUCTS_NAMESPACE}/${UPDATE}`;
export const PRODUCTS_DELETE = `${PRODUCTS_NAMESPACE}/${DELETE}`;

export const PRODUCTS_RATES_GET_BY_ID = `${PRODUCTS_NAMESPACE}/${GET_RATE_BY_ID}`;
export const PRODUCTS_ADD_RATE = `${PRODUCTS_NAMESPACE}/${ADD_RATE}`;
export const PRODUCTS_UPDATE_RATE = `${PRODUCTS_NAMESPACE}/${UPDATE_RATE}`;
export const PRODUCTS_DELETE_RATE = `${PRODUCTS_NAMESPACE}/${DELETE_RATE}`;


// namespaced coupons
export const COUPONS_NAMESPACE = 'coupons';
export const COUPONS_GET_ALL = `${COUPONS_NAMESPACE}/${GET_ALL}`;
export const COUPONS_GET_BY_ID = `${COUPONS_NAMESPACE}/${GET_BY_ID}`;
export const COUPONS_ADD = `${COUPONS_NAMESPACE}/${ADD}`;
export const COUPONS_UPDATE = `${COUPONS_NAMESPACE}/${UPDATE}`;
export const COUPONS_DELETE = `${COUPONS_NAMESPACE}/${DELETE}`;

// transactions
export const GET_PENDING_SETUP = 'getPendingSetup';
export const GET_PAYMENT_SETUP = 'getPaymentSetup';

export const TRANSACTIONS_NAMESPACE = 'transactions';
export const TRANSACTIONS_NAMESPACE_GET_ALL = `${TRANSACTIONS_NAMESPACE}/${GET_ALL}`;
export const TRANSACTIONS_GET_BY_ID = `${TRANSACTIONS_NAMESPACE}/${GET_BY_ID}`;
export const TRANSACTIONS_GET_PENDING_SETUP = `${TRANSACTIONS_NAMESPACE}/${GET_PENDING_SETUP}`;
export const TRANSACTIONS_GET_PAYMENT_SETUP = `${TRANSACTIONS_NAMESPACE}/${GET_PAYMENT_SETUP}`;

// overview
export const GET_COMMON_VALUES = 'getCommonValues';

// namespaced overview
export const OVERVIEW_NAMESPACE = 'overview';
export const OVERVIEW_GET = `${OVERVIEW_NAMESPACE}/${GET}`;
export const OVERVIEW_GET_COMMON_VALUES = `${OVERVIEW_NAMESPACE}/${GET_COMMON_VALUES}`;

// applications
export const CALCULATE_TECH_SCORES = 'calculateTechScores';

// namespaced applications
export const APPLICATIONS_NAMESPACE = 'applications';
export const APPLICATIONS_GET_ALL = `${APPLICATIONS_NAMESPACE}/${GET_ALL}`;
export const APPLICATIONS_GET_BY_PORTFOLIO_ID = `${APPLICATIONS_NAMESPACE}/${GET_BY_PORTFOLIO_ID}`;
export const APPLICATIONS_GET_BY_ID = `${APPLICATIONS_NAMESPACE}/${GET_BY_ID}`;
export const APPLICATIONS_ADD = `${APPLICATIONS_NAMESPACE}/${ADD}`;
export const APPLICATIONS_UPDATE = `${APPLICATIONS_NAMESPACE}/${UPDATE}`;
export const APPLICATIONS_DELETE = `${APPLICATIONS_NAMESPACE}/${DELETE}`;
export const APPLICATIONS_GET_NEW = `${APPLICATIONS_NAMESPACE}/${GET_NEW}`;
export const APPLICATIONS_CALCULATE_TECH_SCORES = `${APPLICATIONS_NAMESPACE}/${CALCULATE_TECH_SCORES}`;

// namespaced it services
export const IT_SERVICES_NAMESPACE = 'itservices';
export const IT_SERVICES_GET_ALL = `${IT_SERVICES_NAMESPACE}/${GET_ALL}`;
export const IT_SERVICES_GET_BY_ID = `${IT_SERVICES_NAMESPACE}/${GET_BY_ID}`;
export const IT_SERVICES_ADD = `${IT_SERVICES_NAMESPACE}/${ADD}`;
export const IT_SERVICES_UPDATE = `${IT_SERVICES_NAMESPACE}/${UPDATE}`;
export const IT_SERVICES_DELETE = `${IT_SERVICES_NAMESPACE}/${DELETE}`;
export const IT_SERVICES_GET_NEW = `${IT_SERVICES_NAMESPACE}/${GET_NEW}`;

//contacts
export const UPDATE_EMAIL_ADDRESS = 'updateEmailAddress';
export const UPDATE_CONTACT_PHONE = 'updateContactPhone';

// namespaced contacts
export const CONTACTS_NAMESPACE = 'contacts';
export const CONTACTS_GET_ALL = `${CONTACTS_NAMESPACE}/${GET_ALL}`;
export const CONTACTS_GET_BY_ID = `${CONTACTS_NAMESPACE}/${GET_BY_ID}`;
export const CONTACTS_ADD = `${CONTACTS_NAMESPACE}/${ADD}`;
export const CONTACTS_UPDATE = `${CONTACTS_NAMESPACE}/${UPDATE}`;
export const CONTACTS_UPDATE_EMAIL_ADDRESS = `${CONTACTS_NAMESPACE}/${UPDATE_EMAIL_ADDRESS}`;
export const CONTACTS_UPDATE_CONTACT_PHONE = `${CONTACTS_NAMESPACE}/${UPDATE_CONTACT_PHONE}`;
export const CONTACTS_DELETE = `${CONTACTS_NAMESPACE}/${DELETE}`;
 

//projects
export const UPDATE_PERCENT_COMPLETE = 'updatePercentComplete';
export const UPDATE_PRIORITY = 'updatePriority';
// namespaced projects
export const PROJECTS_NAMESPACE = 'projects';
export const PROJECTS_GET_ALL = `${PROJECTS_NAMESPACE}/${GET_ALL}`;
export const PROJECTS_GET_BY_ID = `${PROJECTS_NAMESPACE}/${GET_BY_ID}`;
export const PROJECTS_ADD = `${PROJECTS_NAMESPACE}/${ADD}`;
export const PROJECTS_UPDATE = `${PROJECTS_NAMESPACE}/${UPDATE}`;
export const PROJECTS_UPDATE_PERCENT_COMPLETE = `${PROJECTS_NAMESPACE}/${UPDATE_PERCENT_COMPLETE}`;
export const PROJECTS_UPDATE_PRIORITY = `${PROJECTS_NAMESPACE}/${UPDATE_PRIORITY}`;
export const PROJECTS_DELETE = `${PROJECTS_NAMESPACE}/${DELETE}`;

// namespaced programs
export const PROGRAMS_NAMESPACE = 'programs';
export const PROGRAMS_GET_ALL = `${PROGRAMS_NAMESPACE}/${GET_ALL}`;
export const PROGRAMS_GET_BY_ID = `${PROGRAMS_NAMESPACE}/${GET_BY_ID}`;
export const PROGRAMS_ADD = `${PROGRAMS_NAMESPACE}/${ADD}`;
export const PROGRAMS_UPDATE = `${PROGRAMS_NAMESPACE}/${UPDATE}`;
export const PROGRAMS_DELETE = `${PROGRAMS_NAMESPACE}/${DELETE}`;


// namespaced tags
export const TAGS_NAMESPACE = 'tags';
export const TAGS_GET_BY_ACCOUNT_ID = `${TAGS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const TAGS_GET_BY_ID = `${TAGS_NAMESPACE}/${GET_BY_ID}`;
export const TAGS_ADD = `${TAGS_NAMESPACE}/${ADD}`;
export const TAGS_UPDATE = `${TAGS_NAMESPACE}/${UPDATE}`;
export const TAGS_DELETE = `${TAGS_NAMESPACE}/${DELETE}`;

// namespaced areas
export const AREAS_NAMESPACE = 'areas';
export const AREAS_GET_BY_ACCOUNT_ID = `${AREAS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const AREAS_GET_BY_ID = `${AREAS_NAMESPACE}/${GET_BY_ID}`;
export const AREAS_ADD = `${AREAS_NAMESPACE}/${ADD}`;
export const AREAS_UPDATE = `${AREAS_NAMESPACE}/${UPDATE}`;
export const AREAS_DELETE = `${AREAS_NAMESPACE}/${DELETE}`;

// namespaced suppliers
export const SUPPLIERS_NAMESPACE = 'suppliers';
export const SUPPLIERS_GET_BY_ACCOUNT_ID = `${SUPPLIERS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const SUPPLIERS_GET_BY_ID = `${SUPPLIERS_NAMESPACE}/${GET_BY_ID}`;
export const SUPPLIERS_ADD = `${SUPPLIERS_NAMESPACE}/${ADD}`;
export const SUPPLIERS_UPDATE = `${SUPPLIERS_NAMESPACE}/${UPDATE}`;
export const SUPPLIERS_DELETE = `${SUPPLIERS_NAMESPACE}/${DELETE}`;

// namespaced links
export const LINKS_NAMESPACE = 'links';
export const LINKS_GET_BY_ACCOUNT_ID = `${LINKS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const LINKS_GET_BY_ID = `${LINKS_NAMESPACE}/${GET_BY_ID}`;
export const LINKS_ADD = `${LINKS_NAMESPACE}/${ADD}`;
export const LINKS_UPDATE = `${LINKS_NAMESPACE}/${UPDATE}`;
export const LINKS_DELETE = `${LINKS_NAMESPACE}/${DELETE}`;


// portfolios
export const GET_FILTERS = 'getApplicationFiltersByPortfolioId';
export const GET_ALL_APPLICATIONS = 'getAllApplicationsByPortfolioId';
export const GET_ALL_PROJECTS = 'getAllProjectsByPortfolioId';
export const GET_ALL_IDEAS = 'getAllIdeasByPortfolioId';
export const ADD_APPLICATION = 'addApplication';
export const REMOVE_APPLICATION = 'removeApplication';
export const UPDATE_APPLICATION = 'updateApplication';
export const CALCULATE_BUSINESS_SCORES = 'calculateBusinessScores';
export const APPLY_BUSINESS_FACTORS = 'applyBusinessFactors';

// namespaced portfolios
export const PORTFOLIOS_NAMESPACE = 'portfolios';
export const PORTFOLIOS_GET_FILTERS = `${PORTFOLIOS_NAMESPACE}/${GET_FILTERS}`;
export const PORTFOLIOS_GET_ALL_APPLICATIONS = `${PORTFOLIOS_NAMESPACE}/${GET_ALL_APPLICATIONS}`;
export const PORTFOLIOS_GET_ALL_PROJECTS = `${PORTFOLIOS_NAMESPACE}/${GET_ALL_PROJECTS}`;
export const PORTFOLIOS_GET_ALL_IDEAS = `${PORTFOLIOS_NAMESPACE}/${GET_ALL_IDEAS}`;
export const PORTFOLIOS_GET_ALL = `${PORTFOLIOS_NAMESPACE}/${GET_ALL}`;
export const PORTFOLIOS_GET_SUMMARIES = `${PORTFOLIOS_NAMESPACE}/${GET_SUMMARIES}`;
export const PORTFOLIOS_GET_BY_ID = `${PORTFOLIOS_NAMESPACE}/${GET_BY_ID}`;
export const PORTFOLIOS_ADD = `${PORTFOLIOS_NAMESPACE}/${ADD}`;
export const PORTFOLIOS_UPDATE = `${PORTFOLIOS_NAMESPACE}/${UPDATE}`;
export const PORTFOLIOS_UPDATE_SECURITY = `${PORTFOLIOS_NAMESPACE}/${UPDATE_SECURITY}`;
export const PORTFOLIOS_DELETE = `${PORTFOLIOS_NAMESPACE}/${DELETE}`;
export const PORTFOLIOS_ADD_APPLICATION = `${PORTFOLIOS_NAMESPACE}/${ADD_APPLICATION}`;
export const PORTFOLIOS_REMOVE_APPLICATION = `${PORTFOLIOS_NAMESPACE}/${REMOVE_APPLICATION}`;
export const PORTFOLIOS_UPDATE_APPLICATION = `${PORTFOLIOS_NAMESPACE}/${UPDATE_APPLICATION}`;
export const PORTFOLIOS_CALCULATE_BUSINESS_SCORES = `${PORTFOLIOS_NAMESPACE}/${CALCULATE_BUSINESS_SCORES}`;
export const PORTFOLIOS_APPLY_BUSINESS_FACTORS = `${PORTFOLIOS_NAMESPACE}/${APPLY_BUSINESS_FACTORS}`;

// users
export const CHECK_EXISTS = 'checkEmailExists';
export const UPDATE_ROLES = 'updateRoles';
export const GET_PREFERENCES = 'getPreferences';
export const SET_PREFERENCE = 'setPreference';
export const GET_BY_INVITE_TOKEN = 'getByInviteToken';

// namespaced users
export const USERS_NAMESPACE = 'users';
export const USERS_GET_ALL = `${USERS_NAMESPACE}/${GET_ALL}`;
export const USERS_GET_BY_ID = `${USERS_NAMESPACE}/${GET_BY_ID}`;
export const USERS_GET_BY_INVITE_TOKEN = `${USERS_NAMESPACE}/${GET_BY_INVITE_TOKEN}`;
export const USERS_GET_PREFERENCES = `${USERS_NAMESPACE}/${GET_PREFERENCES}`;
export const USERS_SET_PREFERENCE = `${USERS_NAMESPACE}/${SET_PREFERENCE}`;
export const USERS_UPDATE = `${USERS_NAMESPACE}/${UPDATE}`;
export const USERS_UPDATE_ROLES = `${USERS_NAMESPACE}/${UPDATE_ROLES}`;
export const USERS_CHECK_EXISTS = `${USERS_NAMESPACE}/${CHECK_EXISTS}`;

// namespaced tech questions
export const TECH_QUESTIONS_NAMESPACE = 'techquestions';
export const TECH_QUESTIONS_GET_BY_ACCOUNT_ID = `${TECH_QUESTIONS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const TECH_QUESTIONS_UPDATE = `${TECH_QUESTIONS_NAMESPACE}/${UPDATE}`;

// namespaced business questions
export const BUSINESS_QUESTIONS_NAMESPACE = 'businessquestions';
export const BUSINESS_QUESTIONS_GET_ALL = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_ALL}`;
export const BUSINESS_QUESTIONS_GET_BY_PORTFOLIO_ID = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_BY_PORTFOLIO_ID}`;
export const BUSINESS_QUESTIONS_UPDATE = `${BUSINESS_QUESTIONS_NAMESPACE}/${UPDATE}`;

// namespaced ref app state
export const REF_APPLICATION_STATES_NAMESPACE = 'refapplicationstates';
export const REF_APPLICATION_STATES_GET_BY_ACCOUNT_ID = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_APPLICATION_STATES_GET_BY_ID = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_BY_ID}`;
export const REF_APPLICATION_STATES_ADD = `${REF_APPLICATION_STATES_NAMESPACE}/${ADD}`;
export const REF_APPLICATION_STATES_UPDATE = `${REF_APPLICATION_STATES_NAMESPACE}/${UPDATE}`;
export const REF_APPLICATION_STATES_DELETE = `${REF_APPLICATION_STATES_NAMESPACE}/${DELETE}`;

// namespaced ref app type
export const REF_APPLICATION_TYPES_NAMESPACE = 'refapplicationtypes';
export const REF_APPLICATION_TYPES_GET_BY_ACCOUNT_ID = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_APPLICATION_TYPES_GET_BY_ID = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_BY_ID}`;
export const REF_APPLICATION_TYPES_ADD = `${REF_APPLICATION_TYPES_NAMESPACE}/${ADD}`;
export const REF_APPLICATION_TYPES_UPDATE = `${REF_APPLICATION_TYPES_NAMESPACE}/${UPDATE}`;
export const REF_APPLICATION_TYPES_DELETE = `${REF_APPLICATION_TYPES_NAMESPACE}/${DELETE}`;

// namespaced ref tech type
export const REF_TECHNOLOGY_TYPES_NAMESPACE = 'reftechnologytypes';
export const REF_TECHNOLOGY_TYPES_GET_BY_ACCOUNT_ID = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_TECHNOLOGY_TYPES_GET_BY_ID = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_BY_ID}`;
export const REF_TECHNOLOGY_TYPES_ADD = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${ADD}`;
export const REF_TECHNOLOGY_TYPES_UPDATE = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${UPDATE}`;
export const REF_TECHNOLOGY_TYPES_DELETE = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${DELETE}`;

// namespaced ref integration type
export const REF_INTEGRATION_TYPES_NAMESPACE = 'refintegrationtypes';
export const REF_INTEGRATION_TYPES_GET_BY_ACCOUNT_ID = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_INTEGRATION_TYPES_GET_BY_ID = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_BY_ID}`;
export const REF_INTEGRATION_TYPES_ADD = `${REF_INTEGRATION_TYPES_NAMESPACE}/${ADD}`;
export const REF_INTEGRATION_TYPES_UPDATE = `${REF_INTEGRATION_TYPES_NAMESPACE}/${UPDATE}`;
export const REF_INTEGRATION_TYPES_DELETE = `${REF_INTEGRATION_TYPES_NAMESPACE}/${DELETE}`;

// namespaced ref classification type
export const REF_CLASSIFICATIONS_NAMESPACE = 'refclassifications';
export const REF_CLASSIFICATIONS_GET_BY_ACCOUNT_ID = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_CLASSIFICATIONS_GET_BY_ID = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_BY_ID}`;
export const REF_CLASSIFICATIONS_ADD = `${REF_CLASSIFICATIONS_NAMESPACE}/${ADD}`;
export const REF_CLASSIFICATIONS_UPDATE = `${REF_CLASSIFICATIONS_NAMESPACE}/${UPDATE}`;
export const REF_CLASSIFICATIONS_DELETE = `${REF_CLASSIFICATIONS_NAMESPACE}/${DELETE}`;

// namespaced ref computing type
export const REF_COMPUTING_TYPES_NAMESPACE = 'refcomputingtypes';
export const REF_COMPUTING_TYPES_GET_BY_ACCOUNT_ID = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_COMPUTING_TYPES_GET_BY_ID = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_BY_ID}`;
export const REF_COMPUTING_TYPES_ADD = `${REF_COMPUTING_TYPES_NAMESPACE}/${ADD}`;
export const REF_COMPUTING_TYPES_UPDATE = `${REF_COMPUTING_TYPES_NAMESPACE}/${UPDATE}`;
export const REF_COMPUTING_TYPES_DELETE = `${REF_COMPUTING_TYPES_NAMESPACE}/${DELETE}`;

// namespaced ref platform
export const REF_PLATFORMS_NAMESPACE = 'refplatforms';
export const REF_PLATFORMS_GET_BY_ACCOUNT_ID = `${REF_PLATFORMS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_PLATFORMS_GET_BY_ID = `${REF_PLATFORMS_NAMESPACE}/${GET_BY_ID}`;
export const REF_PLATFORMS_ADD = `${REF_PLATFORMS_NAMESPACE}/${ADD}`;
export const REF_PLATFORMS_UPDATE = `${REF_PLATFORMS_NAMESPACE}/${UPDATE}`;
export const REF_PLATFORMS_DELETE = `${REF_PLATFORMS_NAMESPACE}/${DELETE}`;

// namespaced capability sets
export const CAPABILITY_SETS_NAMESPACE = 'capabilitysets';
export const CAPABILITY_SETS_GET_BY_ACCOUNT_ID = `${CAPABILITY_SETS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const CAPABILITY_SETS_GET_BY_ID = `${CAPABILITY_SETS_NAMESPACE}/${GET_BY_ID}`;
export const CAPABILITY_SETS_ADD = `${CAPABILITY_SETS_NAMESPACE}/${ADD}`;
export const CAPABILITY_SETS_UPDATE = `${CAPABILITY_SETS_NAMESPACE}/${UPDATE}`;
export const CAPABILITY_SETS_DELETE = `${CAPABILITY_SETS_NAMESPACE}/${DELETE}`;

// analytics
export const GET_NAMESPACES = 'getNamespace';
export const GET_NAMESPACE_BY_ID = 'getNamespaceById';

// namespaced analytics
export const ANALYTICS_NAMESPACE = 'analytics';
export const ANALYTICS_GET_ALL = `${ANALYTICS_NAMESPACE}/${GET_ALL}`;
export const ANALYTICS_GET_NAMESPACES = `${ANALYTICS_NAMESPACE}/${GET_NAMESPACES}`;
export const ANALYTICS_GET_NAMESPACE_BY_ID = `${ANALYTICS_NAMESPACE}/${GET_NAMESPACE_BY_ID}`;

// namespaced ref project type
export const REF_PROJECT_TYPES_NAMESPACE = 'refprojecttypes';
export const REF_PROJECT_TYPES_GET_BY_ACCOUNT_ID = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_PROJECT_TYPES_GET_BY_ID = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_BY_ID}`;
export const REF_PROJECT_TYPES_ADD = `${REF_PROJECT_TYPES_NAMESPACE}/${ADD}`;
export const REF_PROJECT_TYPES_UPDATE = `${REF_PROJECT_TYPES_NAMESPACE}/${UPDATE}`;
export const REF_PROJECT_TYPES_DELETE = `${REF_PROJECT_TYPES_NAMESPACE}/${DELETE}`;

// namespaced ref project size
export const REF_PROJECT_SIZES_NAMESPACE = 'refprojectsizes';
export const REF_PROJECT_SIZES_GET_BY_ACCOUNT_ID = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_PROJECT_SIZES_GET_BY_ID = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_BY_ID}`;
export const REF_PROJECT_SIZES_ADD = `${REF_PROJECT_SIZES_NAMESPACE}/${ADD}`;
export const REF_PROJECT_SIZES_UPDATE = `${REF_PROJECT_SIZES_NAMESPACE}/${UPDATE}`;
export const REF_PROJECT_SIZES_DELETE = `${REF_PROJECT_SIZES_NAMESPACE}/${DELETE}`;


// namespaced ref project stage
export const REF_PROJECT_STAGES_NAMESPACE = 'refprojectstages';
export const REF_PROJECT_STAGES_GET_BY_ACCOUNT_ID = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_PROJECT_STAGES_GET_BY_ID = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_BY_ID}`;
export const REF_PROJECT_STAGES_ADD = `${REF_PROJECT_STAGES_NAMESPACE}/${ADD}`;
export const REF_PROJECT_STAGES_UPDATE = `${REF_PROJECT_STAGES_NAMESPACE}/${UPDATE}`;
export const REF_PROJECT_STAGES_DELETE = `${REF_PROJECT_STAGES_NAMESPACE}/${DELETE}`;

// namespaced ref project status
export const REF_PROJECT_STATUSES_NAMESPACE = 'refprojectstatuses';
export const REF_PROJECT_STATUSES_GET_BY_ACCOUNT_ID = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_PROJECT_STATUSES_GET_BY_ID = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_BY_ID}`;
export const REF_PROJECT_STATUSES_ADD = `${REF_PROJECT_STATUSES_NAMESPACE}/${ADD}`;
export const REF_PROJECT_STATUSES_UPDATE = `${REF_PROJECT_STATUSES_NAMESPACE}/${UPDATE}`;
export const REF_PROJECT_STATUSES_DELETE = `${REF_PROJECT_STATUSES_NAMESPACE}/${DELETE}`;

// namespaced ref drivers
export const REF_DRIVERS_NAMESPACE = 'refdrivers';
export const REF_DRIVERS_GET_BY_ACCOUNT_ID = `${REF_DRIVERS_NAMESPACE}/${GET_BY_ACCOUNT_ID}`;
export const REF_DRIVERS_GET_BY_ID = `${REF_DRIVERS_NAMESPACE}/${GET_BY_ID}`;
export const REF_DRIVERS_ADD = `${REF_DRIVERS_NAMESPACE}/${ADD}`;
export const REF_DRIVERS_UPDATE = `${REF_DRIVERS_NAMESPACE}/${UPDATE}`;
export const REF_DRIVERS_DELETE = `${REF_DRIVERS_NAMESPACE}/${DELETE}`;

