<template>
  <drop-down
    icon=""
    tag="li"
    position="right"
    direction="none"
    class="nav-item btn-rotate dropdown"
    v-if="authorizedLinks.length > 0"
  >
    <a
      slot="title"
      slot-scope="{ isOpen }"
      class="nav-link dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      :aria-expanded="isOpen"
    >
      <!-- <i class="fa fa-gear"></i> -->
      <p>
        <span class="d-md-block">Administration&nbsp;</span>
      </p>
    </a>
    <router-link
      v-for="(link, index) in authorizedLinks"
      :key="link.name + index"
      :to="link.path"
      :href="link.path"
      class="dropdown-item"
      >{{ link.name }}</router-link>
  </drop-down>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  components: {

  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("authentication", [
        "hasRight"
    ]),
    authorizedLinks: function() {
        return _.filter(this.links, link => {
            if (!link.right) {
                return true;
            } else {
                return this.hasRight(link.right);
            }
        });
    }
  },
  methods: {

  },
};
</script>
