import Vue from 'vue';
import Vuex from 'vuex';

import { analytics } from './analytics.module';
import { authentication } from './authentication.module';
import { account } from './account.module';
import { users } from './users.module';
import { overview } from './overview.module';
import { portfolios } from './portfolios.module';
import { applications } from './applications.module';
import { itservices } from './itservices.module';
import { contacts } from './contacts.module';
import { projects } from './projects.module';
import { programs } from './programs.module';
import { tags } from './tags.module';
import { areas } from './areas.module';
import { techquestions } from './techquestions.module';
import { businessquestions } from './businessquestions.module';
import { refapplicationstates } from './refapplicationstates.module';
import { refapplicationtypes } from './refapplicationtypes.module';
import { reftechnologytypes } from './reftechnologytypes.module';
import { refintegrationtypes } from './refintegrationtypes.module';
import { refclassifications } from './refclassifications.module';
import { refcomputingtypes } from './refcomputingtypes.module';
import { refplatforms } from './refplatforms.module';
import { capabilitysets } from './capabilitysets.module';
import { refprojecttypes } from './refprojecttypes.module';
import { refprojectsizes } from './refprojectsizes.module';
import { refprojectstages } from './refprojectstages.module';
import { refprojectstatuses } from './refprojectstatuses.module';
import { refdrivers } from './refdrivers.module';
import { products } from './products.module';
import { coupons } from './coupons.module';
import { transactions } from './transactions.module';
import { suppliers } from './suppliers.module';
import { links } from './links.module';
import { ideas } from './ideas.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: true,
    modules: {
        analytics,
        authentication,
        account,
        users,
        overview,
        portfolios,
        applications,
        itservices,
        contacts,
        projects,
        programs,
        tags,
        areas,
        techquestions,
        businessquestions,
        refapplicationstates,
        refapplicationtypes,
        reftechnologytypes,
        refintegrationtypes,
        refclassifications,
        refcomputingtypes,
        refplatforms,
        capabilitysets,
        refprojectsizes,
        refprojecttypes,
        refprojectstages,
        refprojectstatuses,
        refdrivers,
        products,
        coupons,
        transactions,
        suppliers,
        links,
        ideas
    }
});
