import _ from 'lodash';
import { refProjectStageService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankRefProjectStage() {
    return {
        refProjectStageId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        shortName: "",
        name: "",
        notes: "",
        displayOrder: null,
        isInProgressFlag: true,
        visibleOnBoards: true,
        projectCount: 0
    };
}

export const refprojectstages = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentRefProjectStage: getBlankRefProjectStage()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                refProjectStageService.getByAccount(accountId)
                    .then(
                        refProjectStages => {
                            commit(GET_ALL_SUCCESS, refProjectStages);
                            resolve(refProjectStages);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, refProjectStageId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return refProjectStageService.getById(refProjectStageId)
                    .then(
                        refProjectStage => {
                            commit(GET_BY_ID_SUCCESS, refProjectStage);
                            resolve(refProjectStage);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, refProjectStage) {
            return refProjectStageService.add(refProjectStage);
        },
        [UPDATE]({ dispatch, commit }, refProjectStage) {
            return refProjectStageService.update(refProjectStage);
        },
        [DELETE]({ dispatch, commit }, refProjectStage) {
            return refProjectStageService.deleteRefProjectStage(refProjectStage);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, refProjectStages) {
            state.status = { loaded: true };
            state.items = refProjectStages;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, refProjectStage) {
            state.status = { loaded: true };
            state.currentRefProjectStage = refProjectStage;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRefProjectStage = getBlankRefProjectStage();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentRefProjectStage = getBlankRefProjectStage();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRefProjectStage, {
                [field]: value
            });
        },
    }
}
