// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refIdeaStatusService = {
    getByAccount,
    getById,
    add,
    update,
    deleteEntity
};

function getByAccount() {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refIdeaStatuses/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refIdeaStatuses/${id}`).then(handleResponse);
}

function add(refIdeaStatus) {
    return axios.post('/refIdeaStatuses', refIdeaStatus).then(handleResponse);
}

function update(refIdeaStatus) {
    return axios.put(`/refIdeaStatuses/${refIdeaStatus.refIdeaStatusId}`, refIdeaStatus).then(handleResponse);
}

function deleteEntity(refIdeaStatus) {
    return axios.delete(`/refIdeaStatuses/${refIdeaStatus.refIdeaStatusId}`).then(handleResponse);
}
