<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications transition-name="notification-list" transition-mode="out-in">

    </notifications>
    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
  // Loading some plugin css asynchronously
  import 'sweetalert2/dist/sweetalert2.css'
</script>
<style lang="scss">

</style>

<style lang="scss">
  @import "~ag-grid-community/styles/ag-grid.css";
  @import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>

<style lang="css">
.popover{
    max-width: 100%; /* Max Width of the popover (depending on the container!) */
}
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.checkbox-label {
    padding-top: 4px;
}

.table-fluid {
    width: auto;
}

.multiselect, .multiselect__input, .multiselect__single {
    font-size: 14px;
}

</style>

<script>
import { mapState, mapGetters } from "vuex"

import {
    OVERVIEW_GET_COMMON_VALUES,
    ACCOUNT_GET,
    ACCOUNT_GET_REFERENCE_VALUES,
    PORTFOLIOS_GET_ALL,
    APPLICATIONS_GET_ALL,
    PROJECTS_GET_ALL,
    IT_SERVICES_GET_ALL,
    CONTACTS_GET_ALL,
    ACCOUNT_GET_FRAMEWORKS,
    OVERVIEW_GET,
    USERS_GET_PREFERENCES,
    ACCOUNT_GET_RECENT
} from "@/store/actions.type";

import { GUID_EMPTY } from "@/helpers";

export default {
    data() {
        return {
        };
    },
    components: {
    },
    computed: {
        ...mapGetters("authentication", [
            "isAuthenticated"
        ]),
        ...mapState("authentication", {
            currentUser: state => state.user
        }),
        ...mapState("account", {
            currentAccount: state => state.currentAccount
        }),
    },
    async created() {
        if (this.isAuthenticated) {
            await this.$store.dispatch(USERS_GET_PREFERENCES, true);
        }
    },
    
    async mounted() {
        if (this.isAuthenticated) {
            // so we grab the logo for the account right away
            await this.$store.dispatch(ACCOUNT_GET);

            if (process.env.VUE_APP_USE_COMMANDBAR == 1)
                this.setCommandBarUser();
            if (process.env.VUE_APP_USE_INTERCOM == 1)
                this.setIntercomUser();

            if (this.currentAccount.accountId != GUID_EMPTY) {
                // pre-load key entities, after a delay so any of these being
                //  loaded by the current component don't have two immediate requests on page load
                //  that way this request should be bypassed because of the useCache = true
                setTimeout(() => {
                    // check again, in case logging out midstream
                    if (this.isAuthenticated && this.currentAccount.accountId != GUID_EMPTY) {
                        this.$store.dispatch(OVERVIEW_GET_COMMON_VALUES, true);
                        this.$store.dispatch(ACCOUNT_GET_REFERENCE_VALUES, true);
                        this.$store.dispatch(PORTFOLIOS_GET_ALL, true);
                        this.$store.dispatch(APPLICATIONS_GET_ALL, true);
                        this.$store.dispatch(PROJECTS_GET_ALL, true);
                        this.$store.dispatch(IT_SERVICES_GET_ALL, true);
                        this.$store.dispatch(CONTACTS_GET_ALL, true);
                        this.$store.dispatch(ACCOUNT_GET_FRAMEWORKS, true);
                        this.$store.dispatch(OVERVIEW_GET, true);
                        this.$store.dispatch(ACCOUNT_GET_RECENT);
                    }
                },2500);        
            }
        }
    },
    methods: {
    }
};
</script>

