import _ from 'lodash';
import { transactionService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_BY_ID,
    GET_PENDING_SETUP,
    GET_PAYMENT_SETUP
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    GET_PENDING_SETUP_REQUEST,
    GET_PENDING_SETUP_SUCCESS,
    GET_PENDING_SETUP_FAILURE,
    GET_PAYMENT_SETUP_REQUEST,
    GET_PAYMENT_SETUP_SUCCESS,
    GET_PAYMENT_SETUP_FAILURE,
} from "./mutations.type";

function getBlankTransaction() {
    return {
        accountTransactionId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        accountPaymentMethodId: GUID_EMPTY,
        stripePaymentIntentId: null,
        paymentType: null,
        transactionDate: null,
        transactionAmount: null,
        transactionStatus: null,
        description: null,
        notes: null,
        paymentDate: null,
        paymentNotes: null,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        accountPaymentMethod: null,
        accountTransactionItems: []
    }
}

function getBlankSetupDetails() {
    return {
        creditCardTransactions: [],
        invoiceTransactions: [],

        creditCardTax1Code: null,
        creditCardTax1Amount: null,
        creditCardTax2Code: null,
        creditCardTax2Amount: null,
        creditCardNetAmount: null,

        invoiceTax1Code: null,
        invoiceTax1Amount: null,
        invoiceTax2Code: null,
        invoiceTax2Amount: null,
        invoiceNetAmount: null,

        paymentIntentId: null,
        paymentClientSecret: null,
        setupIntentId: null,
        setupClientSecret: null,
        invoicePlatform: false,
        annualPricing: false
    }
}

function getBlankPaymentChangeDetails() {
    return {
        setupIntentId: null,
        setupClientSecret: null,
    }
}

export const transactions = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        transactions: [],
        currentTransaction: getBlankTransaction(),
        currentSetupDetails: getBlankSetupDetails(),
        currentPaymentChangeDetails: getBlankPaymentChangeDetails()
    },
    getters: {
       
    },
    actions: {
        [GET_ALL]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                return transactionService.getAll()
                    .then(
                        transactions => {
                            commit(GET_ALL_SUCCESS, transactions);
                            resolve(transactions);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, transactionId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return transactionService.getById(transactionId)
                    .then(
                        transaction => {
                            commit(GET_BY_ID_SUCCESS, transaction);
                            resolve(transaction);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_PENDING_SETUP]({ commit }, applicationId) {
            return new Promise((resolve, reject) => {
                commit(GET_PENDING_SETUP_REQUEST);

                return transactionService.getPendingSetup()
                    .then(
                        details => {
                            commit(GET_PENDING_SETUP_SUCCESS, details);
                            resolve(details);
                        },
                        error => {
                            commit(GET_PENDING_SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_PAYMENT_SETUP]({ commit }, applicationId) {
            return new Promise((resolve, reject) => {
                commit(GET_PAYMENT_SETUP_REQUEST);

                return transactionService.getPaymentSetup()
                    .then(
                        details => {
                            commit(GET_PAYMENT_SETUP_SUCCESS, details);
                            resolve(details);
                        },
                        error => {
                            commit(GET_PAYMENT_SETUP_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, transactions) {
            state.status = { loaded: true };
            state.transactions = transactions;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.transactions = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, transaction) {
            state.status = { loaded: true };
            state.currentTransaction = transaction;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentTransaction = getBlankTransaction();
        },
        [GET_PENDING_SETUP_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_PENDING_SETUP_SUCCESS](state, details) {
            state.status = { loaded: true };
            state.currentSetupDetails = details;
        },
        [GET_PENDING_SETUP_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentSetupDetails = getBlankSetupDetails();
        },
        [GET_PAYMENT_SETUP_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_PAYMENT_SETUP_SUCCESS](state, details) {
            state.status = { loaded: true };
            state.currentPaymentChangeDetails = details;
        },
        [GET_PAYMENT_SETUP_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentPaymentChangeDetails = getBlankPaymentChangeDetails();
        },    
    }
}
