import { 
    VIEW_BOARD, VIEW_GRID, SET_FILTER,
    ADD_FILTER_PRIORITY, REMOVE_FILTER_PRIORITY,
    ADD_FILTER_PORTFOLIO, REMOVE_FILTER_PORTFOLIO,
    ADD_FILTER_OWNER, REMOVE_FILTER_OWNER,
    ADD_FILTER_INITIATED_BY, REMOVE_FILTER_INITIATED_BY

} from "./mutations.type";

import { IDEA_FILTER_ACTIVE } from '@/helpers';

export const ideas = {
    namespaced: true,
    state: {
        viewBoard: true,
        ideaFilter: IDEA_FILTER_ACTIVE,
        selectedPriorities: [],
        selectedPortfolios: [],
        selectedOwners: [],
        selectedInitiatedBy: []
    },
    getters: {
    },
    actions: {
    },
    mutations: {
        [VIEW_BOARD](state) {
            state.viewBoard = true;
        },
        [VIEW_GRID](state) {
            state.viewBoard = false;
        },
        [SET_FILTER](state, value) {
            state.ideaFilter = value;
        },
        [ADD_FILTER_PRIORITY](state, value) {
            state.selectedPriorities.push(value);
        },
        [REMOVE_FILTER_PRIORITY](state, value) {
            state.selectedPriorities.splice(state.selectedPriorities.findIndex(item => item.refPriorityId === value.refPriorityId), 1);
        },
        [ADD_FILTER_PORTFOLIO](state, value) {
            state.selectedPortfolios.push(value);
        },
        [REMOVE_FILTER_PORTFOLIO](state, value) {
            state.selectedPortfolios.splice(state.selectedPortfolios.findIndex(item => item.portfolioId === value.portfolioId), 1);
        },
        [ADD_FILTER_OWNER](state, value) {
            state.selectedOwners.push(value);
        },
        [REMOVE_FILTER_OWNER](state, value) {
            state.selectedOwners.splice(state.selectedOwners.findIndex(item => item.contactId === value.contactId), 1);
        },
        [ADD_FILTER_INITIATED_BY](state, value) {
            state.selectedInitiatedBy.push(value);
        },
        [REMOVE_FILTER_INITIATED_BY](state, value) {
            state.selectedInitiatedBy.splice(state.selectedInitiatedBy.findIndex(item => item.contactId === value.contactId), 1);
        },
    }
}
