// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const couponService = {
    getAll,
    getCouponsForProduct,
    getById,
    add,
    update,
    deleteCoupon
};


function getAll() {
    return axios.get(`/coupons`).then(handleResponse);
}

function getCouponsForProduct(productId, unit) {
    return axios.get(`/coupons/product/${productId}/${unit}`).then(handleResponse);
}


function getById(id) {
    return axios.get(`/coupons/${id}`).then(handleResponse);
}

function add(coupon) {
    return axios.post('/coupons', coupon).then(handleResponse);
}

function update(coupon) {
    return axios.put(`/coupons/${coupon.couponId}`, coupon).then(handleResponse);
}

function deleteCoupon(coupon) {
    return axios.delete(`/coupons/${coupon.couponId}`).then(handleResponse);
}
