// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const areaService = {
    getByAccount,
    getById,
    add,
    update,
    deleteArea
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/areas/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/areas/${id}`).then(handleResponse);
}

function add(area) {
    return axios.post('/areas', area).then(handleResponse);
}

function update(area) {
    return axios.put(`/areas/${area.areaId}`, area).then(handleResponse);
}

function deleteArea(area) {
    return axios.delete(`/areas/${area.areaId}`).then(handleResponse);
}
