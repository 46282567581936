<template>
  <div class="wrapper">

    <!-- <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks"> -->
      <!-- <user-menu></user-menu> -->
    <!-- </side-bar> -->

    <div class="main-panel-print">
      <!-- <top-navbar></top-navbar> -->

      <dashboard-content>

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  // import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  // import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'

  export default {
    components: {
      // TopNavbar,
      ContentFooter,
      DashboardContent,
      // UserMenu,
    },
    methods: {
    }
  }

</script>
