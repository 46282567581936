// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const refTechnologyTypeService = {
    getByAccount,
    getById,
    add,
    update,
    deleteRefTechnologyType
};

function getByAccount(accountId) {
    // return axios.get(`/areas/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/refTechnologyTypes/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/refTechnologyTypes/${id}`).then(handleResponse);
}

function add(refTechnologyType) {
    return axios.post('/refTechnologyTypes', refTechnologyType).then(handleResponse);
}

function update(refTechnologyType) {
    return axios.put(`/refTechnologyTypes/${refTechnologyType.refTechnologyTypeId}`, refTechnologyType).then(handleResponse);
}

function deleteRefTechnologyType(refTechnologyType) {
    return axios.delete(`/refTechnologyTypes/${refTechnologyType.refTechnologyTypeId}`).then(handleResponse);
}
