import _ from 'lodash';
import { refTechnologyTypeService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_BY_ACCOUNT_ID,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankRefTechnologyType() {
    return {
        refTechnologyTypeId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        shortName: "",
        name: "",
        notes: "",
        displayOrder: null,
        applicationCount: 0
    };
}

export const reftechnologytypes = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentRefTechnologyType: getBlankRefTechnologyType()
    },
    getters: {

    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                refTechnologyTypeService.getByAccount(accountId)
                    .then(
                        refTechnologyTypes => {
                            commit(GET_ALL_SUCCESS, refTechnologyTypes);
                            resolve(refTechnologyTypes);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, refTechnologyTypeId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return refTechnologyTypeService.getById(refTechnologyTypeId)
                    .then(
                        refTechnologyType => {
                            commit(GET_BY_ID_SUCCESS, refTechnologyType);
                            resolve(refTechnologyType);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, refTechnologyType) {
            return refTechnologyTypeService.add(refTechnologyType);
        },
        [UPDATE]({ dispatch, commit }, refTechnologyType) {
            return refTechnologyTypeService.update(refTechnologyType);
        },
        [DELETE]({ dispatch, commit }, refTechnologyType) {
            return refTechnologyTypeService.deleteRefTechnologyType(refTechnologyType);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, refTechnologyTypes) {
            state.status = { loaded: true };
            state.items = refTechnologyTypes;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, refTechnologyType) {
            state.status = { loaded: true };
            state.currentRefTechnologyType = refTechnologyType;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentRefTechnologyType = getBlankRefTechnologyType();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentRefTechnologyType = getBlankRefTechnologyType();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentRefTechnologyType, {
                [field]: value
            });
        },
    }
}
