<template>
    <div class="row mt-2">
        <div class="col-xl-4 col-lg-6">
            <div class="input-group">
                <input type="text" class="form-control" id="filter-text-box" placeholder="Filter..." v-model="filterText">
                <button class="btn btn-xs btn-outline-secondary" type="button" id="clear-filter-text-box-button" @click.prevent="clearFilter()"><i class="fa fa-times"></i></button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        gridApi: Object,
        columnApi: Object
    },
    data () {
        return {
            filterText: ''
        };
    },
    computed: {

    },
    methods: {
        clearFilter() {
            this.filterText = '';
        }
    },
    watch: {
        filterText: function (val) {
            if (this.gridApi) {
                this.gridApi.setQuickFilter(val);
            }
        }
    }
}
</script>
