<template>
  <div 
      class="text-center text-white bg-info"
      v-if="isOverviewPage && isEmailVerified && hasAvailableWorkspaces && promptForWorkspaces">
        {{ bannerText }}
        Go to <router-link :to="{name: 'admin.workspaces'}" class="underline-link text-white">My Workspaces</router-link> to see the available workspaces you can join.
    </div>
</template>
<style lang="scss">

</style>
<script>
  import { mapState } from "vuex";

  export default {
    components: {
    },
    computed: {
        ...mapState("authentication", {
            currentUser: state => state.user
        }),
        isOverviewPage() {
          return this.$route.name == 'overview';
        },
        isEmailVerified() {
          return this.currentUser && this.currentUser.emailAddressVerified;
        },
        hasAvailableWorkspaces() {
          return this.currentUser && this.currentUser.availableWorkspacesCount && this.currentUser.availableWorkspacesCount > 0;
        },
        promptForWorkspaces() {
          return this.currentUser && this.currentUser.promptForAvailableWorkspaces;
        },
        bannerText() {
          if (this.currentUser && this.currentUser.availableWorkspacesCount && this.currentUser.availableWorkspacesCount > 0) {
            return this.currentUser.availableWorkspacesCount > 1 ? `There are ${this.currentUser.availableWorkspacesCount} available workspaces you can join.` : 'There is 1 available workspace you can join.';
          }
          return null;
        }
    }
  }

</script>
