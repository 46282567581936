// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const itServiceService = {
    getAll,
    getById,
    getNew,
    add,
    update,
    deleteITService
};

function getAll() {
    // defaults to current account for now
    // return axios.get(`/itServices/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/itServices/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/itServices/${id}`).then(handleResponse);
}

function getNew() {
    return axios.get(`/itServices/new`).then(handleResponse);
}

function add(itService) {
    return axios.post('/itServices', itService).then(handleResponse);
}

function update(itService) {
    return axios.put(`/itServices/${itService.itServiceId}`, itService).then(handleResponse);
}

function deleteITService(itService) {
    return axios.delete(`/itServices/${itService.itServiceId}`).then(handleResponse);
}

