// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const capabilityService = {
    getAll,
    getById,
    add,
    update,
    deleteEntity
};

function getAll() {
    // return axios.get(`/capabilities/?accountId=${accountId}`).then(handleResponse);
    return axios.get(`/capabilities/`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/capabilities/${id}`).then(handleResponse);
}

function add(capability) {
    return axios.post('/capabilities', capability).then(handleResponse);
}

function update(capability) {
    return axios.put(`/capabilities/${capability.capabilityId}`, capability).then(handleResponse);
}

function deleteEntity(capability) {
    return axios.delete(`/capabilities/${capability.capabilityId}`).then(handleResponse);
}
