import {
    SYSTEM_ADMINISTRATOR,
    RIGHT_FULL_PORTFOLIO_ACCESS
} from './constants';

export const userSecurityHelper = {
    userIsInRole,
    userHasRight,
    userHasPortfolioRight,
    userIsSystemAdministrator
};

function userIsInRole(user, accountId, role) {
    var result = false;
    if (user && user.roles)  {
        var matchingRole = user.roles.find(r => r.roleName == role && r.accountId == accountId);
        result = !!matchingRole;
    }
    return result;
}

function userHasRight(user, accountId, right) {
    var result = false;
    if (user) {
        result = userIsSystemAdministrator(user);
        var currentAccountRole = user.roles.find(r => r.accountId == accountId && r.portfolioId == null);
        if (currentAccountRole) {
            result = result || currentAccountRole.rights.indexOf(right) > -1;
        }
    }
    return result;
}

function userHasPortfolioRight(user, accountId, portfolioId, right) {
    var result = false;
    if (user) {
        result = userIsSystemAdministrator(user);
        result = result || userHasRight(user, accountId, RIGHT_FULL_PORTFOLIO_ACCESS);
        var matchingRight = null;
        if (user.roles) {
            matchingRight = user.roles.find(r => r.portfolioId == portfolioId && r.accountId == accountId && r.rights && r.rights.indexOf(right) > -1);
        }
        result = result || !!matchingRight;
    }
    return result;
}

function userIsSystemAdministrator(user) {
    var result = false;
    if (user) {
        result = userIsInRole(user, null, SYSTEM_ADMINISTRATOR);
    }
    return result;
}