import _ from 'lodash';
import { couponService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_BY_ID,
    ADD,
    UPDATE,
    DELETE
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM
} from "./mutations.type";

function getBlankCoupon() {
    return {
        couponId: GUID_EMPTY,
        productId: null,
        productType: "",
        couponCode: "",
        name: "",
        unit: "",
        couponPercent: null,
        couponAmount: null,
        couponPeriods: null,
        availableCoupons: null,
        usedCoupons: null,
        effectiveDate: null,
        endDate: null,
        accountAdjustmentCount: null
    };
}

export const coupons = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentCoupon: getBlankCoupon()
    },
    getters: {
       
    },
    actions: {
        [GET_ALL]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                return couponService.getAll()
                    .then(
                        items => {
                            commit(GET_ALL_SUCCESS, items);
                            resolve(items);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, couponId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return couponService.getById(couponId)
                    .then(
                        coupon => {
                            commit(GET_BY_ID_SUCCESS, coupon);
                            resolve(coupon);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [ADD]({ dispatch, commit }, coupon) {
            return couponService.add(coupon);
        },
        [UPDATE]({ dispatch, commit }, coupon) {
            return couponService.update(coupon);
        },
        [DELETE]({ dispatch, commit }, coupon) {
            return couponService.deleteCoupon(coupon);
        },

    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, items) {
            state.status = { loaded: true };
            state.items = items;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, coupon) {
            state.status = { loaded: true };
            state.currentCoupon = coupon;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentCoupon = getBlankCoupon();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentCoupon = getBlankCoupon();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentCoupon, {
                [field]: value
            });
        },

    }
}
