import _ from 'lodash';
import { technologyQuestionService } from '@/services';
import {
    GET_BY_ACCOUNT_ID,
    UPDATE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    CLEAR_ITEMS
  } from "./mutations.type";

export const techquestions = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
    },
    getters: {
        
    },
    actions: {
        [GET_BY_ACCOUNT_ID]({ commit }, accountId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                technologyQuestionService.getByAccount(accountId)
                    .then(
                        questions => {
                            commit(GET_ALL_SUCCESS, questions);
                            resolve(questions);
                        },
                        error =>  {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [UPDATE]({ dispatch, commit }, question) {
            return technologyQuestionService.update(question);
        }
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, questions) {
            state.status = { loaded: true };
            state.items = questions;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
    }
}
