<template>
  <navbar navbar-menu-classes="navbar-right" v-model="showMenu">
    <!-- <div class="navbar-wrapper">
      <a class="navbar-brand" href="/login">GetInSync</a>
    </div> -->
    <template slot="navbar-menu">
      <router-link to="/login" class="nav-item" tag="li" v-if="this.$route.name != 'login'">
        <a class="nav-link">
           Login
        </a>
      </router-link>
      <router-link to="/register" class="nav-item" tag="li" v-if="!this.$route.name || !this.$route.name.startsWith('register')">
        <a class="nav-link">
           Register
        </a>
      </router-link>
    </template>
  </navbar>
</template>

<script>
  import {Navbar} from 'src/components/UIComponents'
  import {NavbarBasic} from 'src/components/UIComponents'
  export default {
    name: 'main-navbar',
    components: {
      Navbar,
      NavbarBasic
    },
    data() {
      return {
        showMenu: false
      }
    }
  }
</script>

<style scoped>

</style>
