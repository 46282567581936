import _ from 'lodash';
import { userService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_BY_ID,
    GET_BY_INVITE_TOKEN,
    UPDATE,
    UPDATE_ROLES,
    CHECK_EXISTS,
    GET_PREFERENCES,
    SET_PREFERENCE
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    GET_PREFERENCES_REQUEST,
    GET_PREFERENCES_SUCCESS,
    GET_PREFERENCES_FAILURE,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    SET_CURRENT_ACCOUNT_ROLE,
    UPDATE_CURRENT_ACCOUNT_ROLE,
    SET_PORTFOLIO_ROLE
  } from "./mutations.type";
import {
    SYSTEM_ADMINISTRATOR,
    RIGHT_FULL_PORTFOLIO_ACCESS
} from "@/helpers";

function getBlankUser() {
    return {
        userId: GUID_EMPTY,
        emailAddress: "",
        firstName: "",
        lastName: "",
        name: "",
        position: null,
        company: null,
        contactPhone: null,
        imageFilename: "",
        thumbnailFilename: "",
        emailOptIn: true,
        invited: false,
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        roles: [],
        portfolioRights: [],
        managedPortfoliosDescription: ""
    };
}

function getBlankRole() {
    return {
        userRoleId: GUID_EMPTY,
        userId: null,
        roleId: null,
        partnerId: null,
        accountId: null,
        portfolioId: null,
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        role: {
            roleId: GUID_EMPTY,
            roleName: "",
            roleRights: []
        },
        roleName: "",
        rights: []
    };
}

function getBlankPreference() {
    return {
        userPreferenceId: GUID_EMPTY,
        userId: null,
        accountId: null,
        preferenceName: null,
        preferenceValue: null,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
    };
}


export const users = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [
            {
                userId: null,
                emailAddress: "",
                firstName: "",
                lastName: "",
                name: "",
                position: null,
                company: null,
                contactPhone: null,
                emailOptIn: true,
                invited: false,
                active: true,
                deleted: false,
                createdDate: null,
                createdBy: null,
                modifiedDate: null,
                modifiedBy: null,
                roles: [],
                managedPortfoliosDescription: ""
            }
        ],
        currentUser: getBlankUser(),
        currentAccountRole: getBlankRole(),
        preferences: [],
        preferencesLoaded: false
    },
    getters: {
        userPreferenceValue: (state) => (preferenceName, defaultValue) => {
            let result = defaultValue;
            if (state.preferences) {
                let pref = state.preferences.find(p => p.preferenceName == preferenceName);
                if (pref) {
                    result = pref.preferenceValue;
                }
            }
            return result;
        },
        userPreferencesLoaded: state => state.preferencesLoaded

        // currentUserIsInRole: (state, getters) => (role) => {
        //     var result = false;
        //     if (state.currentUser && state.currentUser.roles)  {
        //         var matchingRole = state.currentUser.roles.find(r => r.roleName == role);
        //         result = !!matchingRole;
        //     }
        //     return result;
        // },
        // currentUserHasRight: (state, getters) => (right) => {
        //     var result = false;
        //     if (state.currentUser) {
        //         result = (getters.currentUserIsSystemAdministrator || (state.currentAccountRole && state.currentAccountRole.rights.indexOf(right) > -1));
        //     }
        //     return result;
        // },
        // currentUserHasPortfolioRight: (state, getters) => (portfolioId, right) => {
        //     var result = false;
        //     if (state.currentUser) {
        //         result = getters.currentUserIsSystemAdministrator;
        //         result = result || getters.currentUserHasRight(RIGHT_FULL_PORTFOLIO_ACCESS);
        //         var matchingRight = null;
        //         if (state.currentUser.portfolioRights) {
        //             matchingRight = state.currentUser.portfolioRights.find(pr => pr.portfolioId == portfolioId && pr.rightName == right );
        //         }
        //         result = result || !!matchingRight;
        //     }
        //     return result;
        // },
        // currentUserIsSystemAdministrator: (state, getters) => {
        //     var result = false;
        //     if (state.currentUser) {
        //         result = getters.currentUserIsInRole(SYSTEM_ADMINISTRATOR);
        //     }
        //     return result;
        // },
    },
    actions: {
        [GET_ALL]({ commit }) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                return userService.getAll()
                    .then(
                        users => {
                            commit(GET_ALL_SUCCESS, users);
                            resolve(users);
                        },
                        error => {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, userId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return userService.getById(userId)
                    .then(
                        user => {
                            commit(GET_BY_ID_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_INVITE_TOKEN]({ commit }, token) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return userService.getByInviteToken(token)
                    .then(
                        user => {
                            commit(GET_BY_ID_SUCCESS, user);
                            resolve(user);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [UPDATE]({ dispatch, commit }, { user, file }) {
            return userService.update(user, file);
        },
        [UPDATE_ROLES]({ dispatch, commit }, user) {
            return userService.updateRoles(user);
        },
        [CHECK_EXISTS]({ dispatch, commit }, email) {
            return userService.checkEmailExists(email);
        },
        [GET_PREFERENCES]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.preferences.length > 0) {
                    resolve(state.preferences);
                } else {
                    commit(GET_PREFERENCES_REQUEST);

                    return userService.getUserPreferences()
                        .then(
                            prefs => {
                                commit(GET_PREFERENCES_SUCCESS, prefs);
                                resolve(prefs);
                            },
                            error => {
                                commit(GET_PREFERENCES_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        async [SET_PREFERENCE]({ dispatch, commit }, { preferenceName, preferenceValue, isGlobalPreference }) {
            await userService.saveUserPreference(preferenceName, preferenceValue, isGlobalPreference);
            return dispatch(GET_PREFERENCES);
        },
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, users) {
            state.status = { loaded: true };
            state.items = users;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, user) {
            state.status = { loaded: true };
            state.currentUser = user;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentUser = getBlankUser();
        },
        [GET_PREFERENCES_REQUEST](state) {
            state.status = { loading: true };
            state.preferencesLoaded = false;
        },
        [GET_PREFERENCES_SUCCESS](state, preferences) {
            state.status = { loaded: true };
            state.preferences = preferences;
            state.preferencesLoaded = true;
        },
        [GET_PREFERENCES_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.preferencesLoaded = false;
            state.preferences = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentUser = getBlankUser();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentUser, {
                [field]: value
            });
        },
        [SET_CURRENT_ACCOUNT_ROLE](state, accountId) {
            state.currentAccountRole = getBlankRole();
            if (state.currentUser.roles) {
                // debugger;
                var role = state.currentUser.roles.find(r => r.accountId == accountId && r.portfolioId == null);
                if (role) {
                    state.currentAccountRole = role;
                }
            }
        },
        [UPDATE_CURRENT_ACCOUNT_ROLE](state, { field, value }) {
            Object.assign(state.currentAccountRole, {
                [field]: value
            });
        },
        [SET_PORTFOLIO_ROLE](state, { accountId, portfolioId, roleName }) {
            _.remove(state.currentUser.roles, { 'portfolioId': portfolioId });
            var newRole = getBlankRole();
            newRole.accountId = accountId;
            newRole.portfolioId = portfolioId;
            newRole.roleName = roleName;
            state.currentUser.roles.push(newRole);
        },
    }
}
